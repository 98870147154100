import {
  ActionType,
  Chart,
  dispose,
  Indicator,
  init,
  KLineData,
  LayoutChildType,
  registerIndicator,
  registerYAxis,
  utils,
} from 'klinecharts';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector, useBreakpoints, useTheme } from '../../../hooks';
import {
  BollingerBandsIndicatorData,
  ChartDateIntervalRangeEnum,
  EMAIndicatorData,
  IndicatorEnum,
  RSIIndicatorData,
  SMAIndicatorData,
} from '../../../interfaces';
import {
  cn,
  getCurrentValueForIndicator,
  getPricePrecisionFromString,
  groupFlatKLineInfo,
  recalculateBOLLIndicatorChartFuncHelper,
  recalculateEMAIndicatorChartFuncHelper,
  recalculateRSIIndicatorChartFuncHelper,
  recalculateSMAIndicatorChartFuncHelper,
} from '../../../utils';
import { SpinnerIcon } from '../../icons/spinner-icon';
import { BOLL, bollingerBandsIndicator } from './indicators/boll';
import { Ema, exponentialMovingAverageIndicator } from './indicators/ema';
import { flatCandleStickIndicator } from './indicators/flat-candle-stick';
import { relativeStrengthIndexIndicator, RSI } from './indicators/rsi';
import { simpleMovingAverageIndicator, SMA } from './indicators/sma';
import { BollingerBandsTooltip } from './tooltips/bollinger-bands-tooltip';
import { EMATooltip } from './tooltips/ema-tooltip';
import { PriceTooltip } from './tooltips/price-tooltip';
import { RSITooltip } from './tooltips/rsi-tooltip';
import { SMATooltip } from './tooltips/sma-tooltip';

import { volumeIndicator } from './indicators/volume';
import { applyChartStyles } from './utils/apply-chart-styles';
import { calculateInitialYAxisRange } from './utils/calculate-initial-y-axis-range';
import { formatDecimalFold } from './utils/format-decimal-fold';

type ChartProps = {
  currentOHLCData: KLineData[];
  prependOHLCData: KLineData[];
  newOHLCData: KLineData[] | undefined;
  indicators: {
    [IndicatorEnum.SMA]: boolean;
    [IndicatorEnum.EMA]: boolean;
    [IndicatorEnum.RSI]: boolean;
    [IndicatorEnum.BOLLINGER_BANDS]: boolean;
  };
  chartWidth: number;
  isDataLoading: boolean;
  selectedRange: ChartDateIntervalRangeEnum;
  allOHLCData: KLineData[];
  setAllOHLCData: React.Dispatch<React.SetStateAction<KLineData[]>>;
};

const initialChartTooltipData = {
  klineData: null,
  indicatorData: {
    [IndicatorEnum.SMA]: null,
    [IndicatorEnum.EMA]: null,
    [IndicatorEnum.RSI]: null,
    [IndicatorEnum.BOLLINGER_BANDS]: null,
  },
};

registerYAxis({
  name: 'customYAxisBasic',
  minSpan: () => Math.pow(10, -18),
  createRange: ({ chart, defaultRange }) => {
    return calculateInitialYAxisRange(chart, defaultRange);
  },
});

export const ChartComponent: FC<ChartProps> = ({
  indicators,
  chartWidth,
  currentOHLCData,
  isDataLoading,
  selectedRange,
  prependOHLCData,
  newOHLCData,
  allOHLCData,
  setAllOHLCData,
}) => {
  const limitOrderPair = useAppSelector((state) => state.stopMarketOrder.stopMarketOrderPair);

  const chartRef = useRef<Chart | null>(null);
  const [chartPrecision, setChartPrecision] = useState<number>(0);
  const [chartXAxisWidth, setChartXAxisWidth] = useState<number>(0);
  const [chartRSIPanelPosition, setChartRSIPanelPosition] = useState<number>(100);
  const { isDarkTheme } = useTheme();
  const { isMobile } = useBreakpoints();

  const [chartTooltipData, setChartTooltipData] = useState<{
    klineData: KLineData | null;
    indicatorData: {
      [IndicatorEnum.SMA]: SMAIndicatorData | null;
      [IndicatorEnum.EMA]: EMAIndicatorData | null;
      [IndicatorEnum.RSI]: RSIIndicatorData | null;
      [IndicatorEnum.BOLLINGER_BANDS]: BollingerBandsIndicatorData | null;
    };
  }>(initialChartTooltipData);

  const toggleIndicator = useCallback(
    (indicator: IndicatorEnum, isActive: boolean) => {
      if (!chartRef.current) return;

      const id = indicator === IndicatorEnum.RSI ? IndicatorEnum.RSI : 'candle_pane';

      const filter =
        indicator === IndicatorEnum.RSI
          ? {
              name: IndicatorEnum.RSI,
            }
          : {
              paneId: 'candle_pane',
              name: indicator,
            };

      if (isActive) {
        chartRef.current.createIndicator(indicator, true, { id });

        const currentValue = getCurrentValueForIndicator({
          chart: chartRef.current,
          indicator,
          filter,
          data: currentOHLCData,
          paneId: id,
        });

        setChartTooltipData((prev) => ({
          ...prev,
          indicatorData: {
            ...prev.indicatorData,
            [filter.name]: currentValue ?? null,
          },
        }));
      } else {
        chartRef.current.removeIndicator(filter);

        setChartTooltipData((prev) => ({
          ...prev,
          indicatorData: {
            ...prev.indicatorData,
            [filter.name]: null,
          },
        }));
      }
    },
    [indicators, currentOHLCData],
  );

  const toggleIndicators = useCallback(() => {
    toggleIndicator(IndicatorEnum.SMA, indicators[IndicatorEnum.SMA]);
    toggleIndicator(IndicatorEnum.EMA, indicators[IndicatorEnum.EMA]);
    toggleIndicator(IndicatorEnum.RSI, indicators[IndicatorEnum.RSI]);
    toggleIndicator(IndicatorEnum.BOLLINGER_BANDS, indicators[IndicatorEnum.BOLLINGER_BANDS]);
  }, [toggleIndicator]);

  useEffect(() => {
    const chart = init('chart', {
      timezone: 'UTC',
      locale: 'en-US',
      layout: [
        {
          // eslint-disable-next-line
          type: 'candle' as LayoutChildType,
          options: {
            axis: {
              name: 'customYAxisBasic',
            },
          },
        },
      ],
    });

    if (chart) {
      chartRef.current = chart;

      let lowestPrice = currentOHLCData[0]?.low;

      currentOHLCData.forEach((d) => {
        lowestPrice = Math.min(lowestPrice, d.low);
      });

      if (lowestPrice) {
        const calculatedPrecision = getPricePrecisionFromString(lowestPrice.toString());
        const adjustedPrecision = calculatedPrecision > 8 ? 8 : calculatedPrecision;

        chart.setPrecision({ price: adjustedPrecision });
        chart.setDecimalFold({
          format: formatDecimalFold,
        });

        setChartPrecision(adjustedPrecision);
      }

      chart.subscribeAction(ActionType.OnCrosshairChange, (d) => {
        const data = d as {
          dataIndex: number;
          paneId: string;
          kLineData: KLineData;
          indicatorData: {
            [IndicatorEnum.RSI]: {
              [IndicatorEnum.RSI]: RSIIndicatorData;
            };
            candle_pane: {
              [IndicatorEnum.BOLLINGER_BANDS]: BollingerBandsIndicatorData | null;
              [IndicatorEnum.EMA]: EMAIndicatorData | null;
              [IndicatorEnum.SMA]: SMAIndicatorData | null;
            };
          };
        };

        setChartTooltipData({
          indicatorData: {
            [IndicatorEnum.SMA]: data.indicatorData.candle_pane[IndicatorEnum.SMA] || null,
            [IndicatorEnum.EMA]: data.indicatorData.candle_pane[IndicatorEnum.EMA] || null,
            [IndicatorEnum.RSI]: data.indicatorData[IndicatorEnum.RSI]?.[IndicatorEnum.RSI] || null,
            [IndicatorEnum.BOLLINGER_BANDS]: data.indicatorData.candle_pane[IndicatorEnum.BOLLINGER_BANDS] || null,
          },
          klineData: data.kLineData,
        });
      });

      chart.subscribeAction(ActionType.OnPaneDrag, () => {
        const RSISizeOptions = chart.getSize('RSI');

        if (RSISizeOptions) {
          setChartRSIPanelPosition(RSISizeOptions.height);
        }
      });

      const { flatCandleStickData, groupedCandleStickData } = groupFlatKLineInfo(currentOHLCData);

      chart.applyNewData(groupedCandleStickData);

      chart.setStyles(applyChartStyles({ isMobile }));

      chart.setMaxOffsetRightDistance(isMobile ? 0 : 20);

      registerIndicator(volumeIndicator(currentOHLCData));
      registerIndicator(flatCandleStickIndicator(flatCandleStickData));
      registerIndicator({
        ...relativeStrengthIndexIndicator,
        calc: (dataList: KLineData[], indicator: Indicator<RSI>) =>
          recalculateRSIIndicatorChartFuncHelper(dataList, prependOHLCData, indicator, relativeStrengthIndexIndicator),
      });
      registerIndicator({
        ...simpleMovingAverageIndicator,
        calc: (dataList: KLineData[], indicator: Indicator<SMA>) =>
          recalculateSMAIndicatorChartFuncHelper(dataList, prependOHLCData, indicator, simpleMovingAverageIndicator),
      });
      registerIndicator({
        ...exponentialMovingAverageIndicator,
        calc: (dataList: KLineData[], indicator: Indicator<Ema>) =>
          recalculateEMAIndicatorChartFuncHelper(
            dataList,
            prependOHLCData,
            indicator,
            exponentialMovingAverageIndicator,
          ),
      });
      registerIndicator({
        ...bollingerBandsIndicator,
        calc: (dataList: KLineData[], indicator: Indicator<BOLL>) =>
          recalculateBOLLIndicatorChartFuncHelper(dataList, prependOHLCData, indicator, bollingerBandsIndicator),
      });

      chart.createIndicator('flatCandleStickIndicator', true, { id: 'candle_pane' });

      if (!isMobile) {
        chart.createIndicator('custom-volume', true, { id: '' });
      }

      setChartTooltipData((prev) => ({
        ...prev,
        klineData: currentOHLCData.at(-1) || null,
      }));
    }

    return () => {
      dispose('chart');
    };
  }, [chartWidth, isDarkTheme, isMobile, currentOHLCData, selectedRange, isDataLoading]);

  const setChartXAxisWidthCb = useCallback(() => {
    const candlePane = chartRef.current?.getDom('candle_pane');

    if (candlePane) {
      const firstChild = candlePane.firstElementChild;
      if (firstChild instanceof HTMLElement) {
        const width = firstChild.offsetWidth;
        setChartXAxisWidth(width);
      }
    }
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      toggleIndicators();
      setChartXAxisWidthCb();
    }
  }, [indicators, setChartXAxisWidthCb, chartRef.current]);

  useEffect(() => {
    if (isDataLoading) {
      setChartTooltipData(initialChartTooltipData);
    }
  }, [isDataLoading]);

  const maxChartPrice = useMemo(
    () => currentOHLCData.reduce((acc, val) => Math.max(acc, val.high), 0),
    [currentOHLCData],
  );

  const tooltipWidth = useMemo(
    () => utils.calcTextWidth(formatDecimalFold(utils.formatPrecision(maxChartPrice, chartPrecision).toString()), 22),
    [maxChartPrice, chartPrecision],
  );

  chartRef.current?.subscribeAction(ActionType.OnVisibleRangeChange, () => {
    setChartXAxisWidthCb();
  });

  useEffect(() => {
    if (newOHLCData?.length && chartRef.current) {
      newOHLCData.forEach((d) => chartRef.current!.updateData(d));

      setAllOHLCData((prev) => [...prev, ...newOHLCData]);
    }
  }, [newOHLCData, setAllOHLCData]);

  return (
    <div id="chart" className={cn('!w-full md:h-[530px] h-[300px] relative')} style={{ width: chartWidth }}>
      {isDataLoading ? (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <SpinnerIcon />
        </div>
      ) : null}

      <div className="absolute top-0 left-0 z-10 w-full" style={{ maxWidth: chartXAxisWidth || chartWidth }}>
        {chartTooltipData?.klineData && (
          <PriceTooltip
            close={chartTooltipData.klineData.close}
            open={chartTooltipData.klineData.open}
            low={chartTooltipData.klineData.low}
            high={chartTooltipData.klineData.high}
            volume={chartTooltipData.klineData.volume || 0}
            timestamp={chartTooltipData.klineData.timestamp}
            precision={chartPrecision}
            tooltipWidth={tooltipWidth}
          />
        )}

        {allOHLCData[allOHLCData.length - 1]?.close && (
          <div className="md:hidden absolute top-0 h-5 leading-5 right-2 text-[10px] backdrop-blur-lg font-medium">
            1 {limitOrderPair?.tokenIn?.symbol} ={' '}
            {formatDecimalFold(utils.formatPrecision(allOHLCData[allOHLCData.length - 1]?.close, chartPrecision))}{' '}
            {limitOrderPair?.tokenOut?.symbol}
          </div>
        )}

        {chartTooltipData?.indicatorData[IndicatorEnum.EMA] && (
          <EMATooltip
            ema1={chartTooltipData?.indicatorData[IndicatorEnum.EMA].ema1}
            ema2={chartTooltipData?.indicatorData[IndicatorEnum.EMA].ema2}
            ema3={chartTooltipData?.indicatorData[IndicatorEnum.EMA].ema3}
            precision={chartPrecision}
          />
        )}
        {chartTooltipData?.indicatorData[IndicatorEnum.BOLLINGER_BANDS] && (
          <BollingerBandsTooltip
            down={chartTooltipData?.indicatorData[IndicatorEnum.BOLLINGER_BANDS].dn}
            up={chartTooltipData?.indicatorData[IndicatorEnum.BOLLINGER_BANDS].up}
            mid={chartTooltipData?.indicatorData[IndicatorEnum.BOLLINGER_BANDS].mid}
            precision={chartPrecision}
          />
        )}
        {chartTooltipData?.indicatorData[IndicatorEnum.SMA] && (
          <SMATooltip sma={chartTooltipData?.indicatorData[IndicatorEnum.SMA].sma} precision={chartPrecision} />
        )}
      </div>

      {chartTooltipData?.indicatorData[IndicatorEnum.RSI] && (
        <RSITooltip
          className="absolute bottom-0"
          style={{
            bottom: chartRSIPanelPosition,
          }}
          rsi1={chartTooltipData?.indicatorData[IndicatorEnum.RSI].rsi1}
          rsi2={chartTooltipData?.indicatorData[IndicatorEnum.RSI].rsi2}
          rsi3={chartTooltipData?.indicatorData[IndicatorEnum.RSI].rsi3}
          precision={chartPrecision}
        />
      )}
    </div>
  );
};
