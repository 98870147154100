import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { formatUnits } from 'ethers';
import { CircleHelpIcon, MoveHorizontalIcon, XCircleIcon } from 'lucide-react';
import { FC } from 'react';
import { useBreakpoints } from '../../../../hooks';
import { StopMarketOrderQuoteEstimationType, Token } from '../../../../interfaces';
import { cn, formatAmount } from '../../../../utils';
import { TokenLabel } from '../../../custom/token-label';
import { TokenLogo } from '../../../custom/token-logo';
import { SpinnerIcon } from '../../../icons/spinner-icon';
import { Button } from '../../../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { Separator } from '../../../ui/separator';
import { TypographyS } from '../../../ui/typography';

type StopMarketOrderTokenEstimationProps = {
  tokenIn: Token;
  tokenOut: Token;
  isTokenInApproved: boolean;
  isCloseOrder: boolean;
  orderQuoteEstimationLoading: boolean;
  orderQuoteEstimation: StopMarketOrderQuoteEstimationType | null;
  orderEstimationError: string | undefined;
  refetchStopMarketOrderQuoteEstimation?: (options?: RefetchOptions) => Promise<
    QueryObserverResult<
      AxiosResponse<{
        openOrderQuoteEstimation: StopMarketOrderQuoteEstimationType;
        closeOrderQuoteEstimation: StopMarketOrderQuoteEstimationType | null;
      }>,
      Error
    >
  >;
};

export const StopMarketOrderTokenEstimation: FC<StopMarketOrderTokenEstimationProps> = ({
  tokenOut,
  tokenIn,
  isTokenInApproved,
  isCloseOrder,
  orderQuoteEstimationLoading,
  orderQuoteEstimation,
  orderEstimationError,
  refetchStopMarketOrderQuoteEstimation,
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <div className="flex md:items-center  md:flex-row flex-col gap-1">
      <div className="flex items-center gap-1">
        <TokenLabel imgClassName="w-3.5 h-3.5" token={tokenOut} textClassName="font-semibold" />{' '}
        <TypographyS className="text-xs font-semibold"> amount to receive</TypographyS>
      </div>
      <div className="md:pl-0 pl-2 ">
        {orderEstimationError ? (
          <div className="flex items-center gap-1">
            <Popover>
              <PopoverTrigger className="w-fit group flex items-center text-[11px] justify-center">
                <TypographyS className="text-xs flex items-center dark:text-white/70 text-black/70 underline ">
                  estimation error
                  <XCircleIcon className="text-red-500 w-3.5 h-3.5 ml-1" />
                </TypographyS>
              </PopoverTrigger>
              <PopoverContent
                align="center"
                side={isMobile ? 'bottom' : 'right'}
                className="bg-white/30 shadow space-y-1 dark:bg-primary-black/30 max-w-[240px] w-fit backdrop-blur-lg p-2"
              >
                <TypographyS className="text-xs dark:text-white/70 text-black/70  ">{orderEstimationError}</TypographyS>
              </PopoverContent>
            </Popover>

            {refetchStopMarketOrderQuoteEstimation ? (
              <Button
                disabled={orderQuoteEstimationLoading}
                onClick={async () => await refetchStopMarketOrderQuoteEstimation()}
                variant="ghost"
                className=" text-[10px] h-fit p-1 dark:text-white/80 dark:hover:text-white text-black/80 hover:text-black"
              >
                Try again?
              </Button>
            ) : null}
          </div>
        ) : orderQuoteEstimationLoading ? (
          <div className="flex items-center gap-1">
            <TypographyS className="text-xs dark:text-white/70 text-black/70 ">estimating</TypographyS>

            <SpinnerIcon className="w-3 h-3" />
          </div>
        ) : orderQuoteEstimation ? (
          <Popover>
            <PopoverTrigger className="w-fit group flex items-center text-[11px] justify-center">
              <TypographyS
                className={cn('text-xs flex items-center  text-black/70 font-semibold dark:text-yellow-400', {
                  'dark:text-purple-400': isCloseOrder,
                })}
              >
                {formatAmount({
                  value: +formatUnits(orderQuoteEstimation.minAmountOut, tokenOut.decimals),
                  withoutFormatForSmallValue: true,
                  precise: true,
                })}
                <MoveHorizontalIcon className="w-3 h-3 mx-1" />
                {formatAmount({
                  value: +formatUnits(orderQuoteEstimation.maxAmountOut, tokenOut.decimals),
                  withoutFormatForSmallValue: true,
                  precise: true,
                })}
              </TypographyS>
              <CircleHelpIcon className="dark:text-white/70 dark:group-hover:text-white text-black/70 group-hover:text-black ml-1 w-3.5 h-3.5" />
            </PopoverTrigger>

            <PopoverContent
              align="center"
              side={isMobile ? 'bottom' : 'right'}
              className="bg-white/30 shadow space-y-1 dark:bg-primary-black/30 max-w-[240px] w-fit backdrop-blur-lg p-2"
            >
              <Label
                title="Amount in:"
                value={formatAmount({
                  value: +formatUnits(orderQuoteEstimation.amountIn, tokenIn.decimals),
                  withoutFormatForSmallValue: true,
                  precise: true,
                })}
                Icon={<TokenLogo token={tokenIn} />}
              />
              <Label
                title="Platform fee:"
                value={formatAmount({
                  value: +formatUnits(orderQuoteEstimation.platformFee, tokenIn.decimals),
                  withoutFormatForSmallValue: true,
                  precise: true,
                })}
                Icon={<TokenLogo token={tokenIn} />}
              />
              <Label
                title="Tx fee:"
                value={formatAmount({
                  value: +formatUnits(orderQuoteEstimation.estimatedTxFee, tokenIn.decimals),
                  withoutFormatForSmallValue: true,
                  precise: true,
                })}
                Icon={<TokenLogo token={tokenIn} />}
              />
              <Label
                title="Floating gas fee:"
                value={formatAmount({
                  value: +formatUnits(orderQuoteEstimation.floatingGasFee, tokenIn.decimals),
                  withoutFormatForSmallValue: true,
                  precise: true,
                })}
                Icon={<TokenLogo token={tokenIn} />}
              />

              <Separator className="!my-2 w-full dark:bg-primary-light-gray" />

              <Label
                title="Min amount out:"
                value={formatAmount({
                  value: +formatUnits(orderQuoteEstimation.minAmountOut, tokenOut.decimals),
                  withoutFormatForSmallValue: true,
                  precise: true,
                })}
                Icon={<TokenLogo token={tokenOut} />}
              />
              <Label
                title="Max amount out:"
                value={formatAmount({
                  value: +formatUnits(orderQuoteEstimation.maxAmountOut, tokenOut.decimals),
                  withoutFormatForSmallValue: true,
                  precise: true,
                })}
                Icon={<TokenLogo token={tokenOut} />}
              />
            </PopoverContent>
          </Popover>
        ) : !isTokenInApproved ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70 ">
              approve {tokenIn.symbol} first to get estimation
            </TypographyS>
          </>
        ) : null}
      </div>
    </div>
  );
};

type LabelProps = {
  title: string;
  value: string | null;
  Icon?: JSX.Element;
};

const Label: FC<LabelProps> = ({ title, value, Icon }) => {
  return (
    <div className="flex gap-2 items-center font-semibold justify-between dark:text-white text-[11px] text-black first-letter:capitalize">
      <TypographyS>{title}</TypographyS>
      <div className="flex items-center gap-1">
        <TypographyS>{value}</TypographyS>
        {Icon ? Icon : null}
      </div>
    </div>
  );
};
