import { useEffect, useMemo } from 'react';
import { OrderListMobile } from '../../components';
import { ChartLayout } from '../../components/order/chart/chart-layout';
import { OrderListCard } from '../../components/order/list/order-list';
import { StopMarketOrderForm } from '../../components/order/stop-market-order/stop-market-order-form';
import { useAppDispatch, useAppSelector, useBreakpoints } from '../../hooks';
import { setStopMarketOrderPair } from '../../store';
import { getAvailableTokens, getNetworks } from '../../utils/blockchain/token-list';

export const StopMarketOrderPage = () => {
  const { isAuthenticated } = useAppSelector((s) => s.auth);
  const dispatch = useAppDispatch();

  const { isMobile } = useBreakpoints();

  const network = getNetworks()[0];
  const networkTokens = useMemo(() => getAvailableTokens({ chainId: network.chainId }), [network.chainId]);
  const tokenIn = networkTokens.filter((t) => t.isStablecoin)[0];
  const tokenOut = networkTokens.filter((t) => !t.isStablecoin)[0];

  useEffect(() => {
    dispatch(
      setStopMarketOrderPair({
        chainId: network.chainId,
        tokenIn: tokenOut,
        tokenOut: tokenIn,
      }),
    );
  }, []);

  return (
    <div className="">
      <div className="container md:grid flex flex-col md:grid-cols-12 gap-2 px-2  pb-0">
        <div className="md:col-span-8 col-span-12 h-full ">
          <ChartLayout />
        </div>
        <div className="col-span-4">
          <StopMarketOrderForm />
        </div>

        {isAuthenticated && isMobile ? (
          <div className="md:hidden">
            <OrderListMobile />
          </div>
        ) : !isMobile ? (
          <div className="col-span-12 md:block hidden">
            <OrderListCard />
          </div>
        ) : null}
      </div>
    </div>
  );
};
