import { useCallback, useState } from 'react';
import { PriceConditionTypeEnum } from '../../../interfaces';

export type ValidatePriceConditionParams = {
  price: string;
  priceCondition: PriceConditionTypeEnum;
  latestTokenPairPrice: string | null;
  orderTokenInUsdPriceOracle: string | null;
  tokenInUsdPrice: number | null;
  orderTokenOutUsdPriceOracle: string | null;
  tokenOutUsdPrice: number | null;
  pricePrecision: number;
};

export const useValidatePriceCondition = () => {
  const [priceDifferenceWarning, setPriceDifferenceWarning] = useState<{
    type: 'high' | 'low';
    delta: number;
  } | null>(null);

  const [priceOverflowError, setPriceOverflowError] = useState<boolean>(false);

  const validatePrice = useCallback(
    ({ price, priceCondition, pricePrecision, latestTokenPairPrice }: ValidatePriceConditionParams) => {
      if (!price || price === '0') {
        setPriceDifferenceWarning(null);

        return;
      }

      if (latestTokenPairPrice && priceCondition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) {
        const marketPrice = +Number(latestTokenPairPrice).toFixed(pricePrecision);
        const userPrice = Number(price);

        // disallow to create orders with higher market price than user price

        if (userPrice < marketPrice) {
          setPriceOverflowError(true);
        } else {
          setPriceOverflowError((prev) => (prev ? false : prev));
        }

        const percentageDifference = Math.round((userPrice / marketPrice) * 100);

        if (percentageDifference > 120) {
          setPriceDifferenceWarning({ type: 'high', delta: percentageDifference });
        } else if (percentageDifference < 80) {
          setPriceDifferenceWarning({ type: 'low', delta: Math.abs(percentageDifference) });
        } else {
          setPriceDifferenceWarning(null);
        }
      } else if (latestTokenPairPrice && priceCondition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) {
        const userPrice = Number(price);
        const marketPrice = +(1 / Number(latestTokenPairPrice)).toFixed(pricePrecision);

        // disallow to create orders with higher market price than user price

        if (userPrice > marketPrice) {
          setPriceOverflowError(true);
        } else {
          setPriceOverflowError((prev) => (prev ? false : prev));
        }

        const percentageDifference = Math.round((userPrice / marketPrice) * 100);

        if (percentageDifference > 120) {
          setPriceDifferenceWarning({ type: 'high', delta: percentageDifference });
        } else if (percentageDifference < 80) {
          setPriceDifferenceWarning({ type: 'low', delta: Math.abs(percentageDifference) });
        } else {
          setPriceDifferenceWarning(null);
        }
      }
    },
    [],
  );

  return {
    priceDifferenceWarning,
    priceOverflowError,
    validatePrice,
    setPriceOverflowError,
    setPriceDifferenceWarning,
    clearErrors: () => {
      setPriceOverflowError(false);
      setPriceDifferenceWarning(null);
    },
  };
};
