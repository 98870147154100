import { MutateOptions, useMutation } from '@tanstack/react-query';
import { AxiosResponse, isAxiosError } from 'axios';
import { useCallback, useEffect, useRef } from 'react';
import { UserRejectedRequestError } from 'viem';
import { SpinnerIcon, TypographyP } from '../../components';
import { LimitOpenOrderType, StopMarketOrderQuoteEstimationType, StopMarketOrderType } from '../../interfaces';
import { toast } from '../ui/use-toast';
import { useReserveStopMarketOrderNonce } from './use-reserve-stop-market-order-nonce';
import { useSignStopMarketOrderOnChain } from './use-sign-stop-market-order-on-chain';

export const useSignStopMarketOrder = () => {
  const { reserveOrderNonce, reservedOrderNonce } = useReserveStopMarketOrderNonce();

  const { signOrderSignature, signedOrderSignature } = useSignStopMarketOrderOnChain();

  const signOrderHandler = useCallback(
    async ({
      userAddress,
      order,
      reservedOrderId,
      orderQuoteEstimation,
      chainId,
      expirationTime,
      orderType,
      reserveOrderId,
    }: {
      userAddress: string;
      order: LimitOpenOrderType['order'];
      chainId: number;
      expirationTime: Date;
      reservedOrderId?: string;
      orderType: StopMarketOrderType;
      orderQuoteEstimation?: StopMarketOrderQuoteEstimationType | null;
      reserveOrderId: (
        variables: void,
        options?: MutateOptions<AxiosResponse<string>, Error, void, unknown> | undefined,
      ) => Promise<AxiosResponse<string>>;
    }) => {
      let orderId: string = reservedOrderId || '';
      let orderNonce: string = reservedOrderNonce || '';

      if (!orderQuoteEstimation) {
        throw new Error(`Cannot estimate order quote`);
      }

      if (!orderId) {
        orderId = (await reserveOrderId()).data;
      }

      if (!orderId) {
        throw new Error(`Cannot reserve order id`);
      }

      if (!orderNonce) {
        orderNonce = (await reserveOrderNonce()).data;
      }

      if (!orderNonce) {
        throw new Error(`Cannot reserve order nonce`);
      }

      await signOrderSignature({
        amountIn: order.amountIn,
        amountOutMin: orderQuoteEstimation.minAmountOut,
        chainId,
        nonce: orderNonce,
        orderId: orderId,
        tokenIn: order.tokenIn.address,
        tokenOut: order.tokenOut.address,
        ttl: expirationTime.getTime(),
        user: userAddress,
        orderType,
      });
    },
    [reservedOrderNonce, signOrderSignature],
  );

  const signingToastRef = useRef<ReturnType<typeof toast> | null>(null);

  const { isPending, error, mutateAsync } = useMutation({
    mutationFn: signOrderHandler,
    onMutate: () => {
      signingToastRef.current = toast({
        title: (
          <div className="flex gap-1 items-center">
            <TypographyP>Signing order </TypographyP>
          </div>
        ) as unknown as string,
        description: (
          <div className=" w-full">
            Waiting until order will be signed
            <div className="absolute right-3 top-1/2 -translate-y-1/2">
              <SpinnerIcon className="w-6 h-6" />
            </div>
          </div>
        ),
        open: true,
        duration: 1000 * 60,
      });
    },
    onSettled: () => {
      signingToastRef.current && signingToastRef.current.dismiss();
    },
    onSuccess() {
      if (signingToastRef.current) {
        signingToastRef.current.update({
          id: signingToastRef.current.id,
          title: `Signing success`,
          description: `Order was signed`,
          duration: 3000,
        });
      }
    },
    onError: (error) => {
      let message = 'Failed to sign the order';

      if (isAxiosError(error)) {
        message = error.message;
      }

      if (error instanceof UserRejectedRequestError) {
        message = error.shortMessage;
      }

      toast({
        title: `Order sign error`,
        description: message,
        duration: 3000,
      });
    },
  });

  useEffect(() => {
    return () => {
      signingToastRef.current && signingToastRef.current.dismiss();
    };
  }, []);

  return {
    signedOrderSignature,
    reservedOrderNonce,
    isOrderSigning: isPending,
    orderSigningError: error,
    signOrder: mutateAsync,
  };
};
