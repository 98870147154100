import { ArrowRightLeftIcon } from 'lucide-react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { useDebounce, ValidatePriceConditionParams } from '../../../../hooks';
import { FormLimitOpenOrderSchema, PriceConditionTypeEnum, Token } from '../../../../interfaces';
import {
  cn,
  formatAmount,
  getOpenOrderHeaderLabel,
  getPricePrecisionFromPriceCondition,
  getTokenFromPriceConditionType,
} from '../../../../utils';
import { Button } from '../../../ui/button';
import { Input } from '../../../ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../ui/tooltip';
import { TypographyH4, TypographyS } from '../../../ui/typography';
import { PriceConditionInput } from '../../order/price-condition-input';
import { PriceConditionSwitcher } from '../../order/price-condition-switcher';
import { TokenSelector } from '../../order/token-selector';
import { StopMarketOrderPricePercentageSelector } from '../components/stop-market-order-price-percentage-selector';

type StopMarketOpenOrderFormProps = {
  networkTokens: Token[];
  tokenAmountInput: string;
  priceConditionPriceInput: string;
  wasPriceConditionPriceInChanged: boolean;
  priceDifferenceWarning: {
    type: 'high' | 'low';
    delta: number;
  } | null;
  insufficientBalanceError: boolean;
  selectedTokenBalance: string | undefined;
  pairLatestPrice: string;
  isPairLatestPriceFetching: boolean;
  tokenInUsdPrice: number | null;
  tokenOutUsdPrice: number | null;
  priceOverflowError: boolean;
  insufficientOpenOrderMinAmountInError: boolean;
  orderPricePercentage: number;
  handleOrderPriceConditionChange: (condition: PriceConditionTypeEnum) => void;
  handleSetMarketplacePrice: () => void;
  setTokenAmountInput: React.Dispatch<React.SetStateAction<string>>;
  handleSelectedTokensChange: ({ tokenIn, tokenOut }: { tokenIn: Token; tokenOut: Token }) => void;
  validateOpenOrderPrice: (params: ValidatePriceConditionParams) => void;
  handleOpenOrderPricePercentageChange: (percentage: number) => void;
  handleOpenOrderInputPriceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const StopMarketOpenOrderForm: FC<StopMarketOpenOrderFormProps> = ({
  tokenAmountInput,
  priceConditionPriceInput,
  wasPriceConditionPriceInChanged,
  priceDifferenceWarning,
  networkTokens,
  tokenOutUsdPrice,
  orderPricePercentage,
  tokenInUsdPrice,
  insufficientBalanceError,
  selectedTokenBalance,
  pairLatestPrice,
  isPairLatestPriceFetching,
  insufficientOpenOrderMinAmountInError,
  priceOverflowError,
  setTokenAmountInput,
  handleSetMarketplacePrice,
  handleSelectedTokensChange,
  validateOpenOrderPrice,
  handleOrderPriceConditionChange,
  handleOpenOrderPricePercentageChange,
  handleOpenOrderInputPriceChange,
}) => {
  const formOpenOrder = useFormContext<z.infer<typeof FormLimitOpenOrderSchema>>();

  const openOrder = formOpenOrder.watch('order');

  const orderAmountIn = openOrder.amountIn;
  const orderTokenIn = openOrder.tokenIn;
  const orderTokenOut = openOrder.tokenOut;
  const orderPriceConditionType = openOrder.priceCondition.conditionType;
  const orderPriceConditionPrice = openOrder.priceCondition.value;

  const openOrderPricePrecision = useMemo(() => {
    return getPricePrecisionFromPriceCondition(orderPriceConditionType, orderTokenIn, orderTokenOut);
  }, [orderPriceConditionType, orderTokenIn.decimals, orderTokenOut.decimals]);

  const availableOrderTokensOut = networkTokens.filter((t) => {
    const isTokenStable = t.isStablecoin;

    if (isTokenStable && orderTokenIn.isStablecoin) {
      return false;
    }

    return true;
  });

  const amountInValidationError = formOpenOrder.formState.errors.order?.amountIn?.message || '';
  const orderPriceConditionValidationError = formOpenOrder.formState.errors.order?.priceCondition?.value?.message || '';

  const handleAmountInChange = useCallback((newAmount: string) => {
    formOpenOrder.setValue('order.amountIn', newAmount, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, []);

  const handleTokenInChange = useCallback(
    (tokenIn: Token) => {
      const isTokenInStablecoin = tokenIn.isStablecoin;
      const isTokenOutStablecoin = orderTokenOut.isStablecoin;

      let tokenOut = orderTokenOut;

      if (tokenIn.address === tokenOut.address || isTokenOutStablecoin) {
        const firstAvailableToken = networkTokens.find((t) => {
          const isNotTheSameToken = t.address !== tokenIn.address;
          const isNotStablecoin = !t.isStablecoin;

          if (isTokenInStablecoin) {
            return isNotStablecoin && isNotTheSameToken;
          }

          return isNotTheSameToken;
        });

        if (firstAvailableToken) {
          tokenOut = firstAvailableToken;
        }
      }

      handleSelectedTokensChange({ tokenIn, tokenOut });
    },
    [orderTokenOut, networkTokens],
  );

  const handleTokenOutChange = useCallback(
    (tokenOut: Token) => {
      const isTokenOutStablecoin = tokenOut.isStablecoin;
      const isTokenInStablecoin = orderTokenIn.isStablecoin;

      let tokenIn = orderTokenIn;

      if (tokenOut.address === tokenIn.address || (isTokenInStablecoin && tokenOut.isStablecoin)) {
        const firstAvailableToken = networkTokens.find((t) => {
          const isNotTheSameToken = t.address !== tokenOut.address;
          const isNotStablecoin = !t.isStablecoin;

          if (isTokenOutStablecoin) {
            return isNotStablecoin && isNotTheSameToken;
          }

          return isNotTheSameToken;
        });

        if (firstAvailableToken) {
          tokenIn = firstAvailableToken;
        }
      }

      handleSelectedTokensChange({ tokenIn, tokenOut });
    },
    [orderTokenIn, networkTokens],
  );

  const handleBalanceClick = useCallback(() => {
    if (selectedTokenBalance) {
      handleAmountInChange(selectedTokenBalance);
      setTokenAmountInput(selectedTokenBalance);
    }
  }, [selectedTokenBalance]);

  const debouncedUpdateAmountIn = useDebounce((value: string) => {
    handleAmountInChange(value.toString());
  }, 500);

  const handleTokenAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputVal = e.target.value;
    inputVal = inputVal.replace(/[^\d.]/g, '');

    const firstDotIndex = inputVal.indexOf('.');
    if (firstDotIndex !== -1) {
      inputVal = inputVal.slice(0, firstDotIndex + 1) + inputVal.slice(firstDotIndex + 1).replace(/\./g, '');
    }

    setTokenAmountInput(inputVal);

    debouncedUpdateAmountIn(inputVal);
  };

  useEffect(() => {
    if (pairLatestPrice && !isPairLatestPriceFetching && !wasPriceConditionPriceInChanged) {
      handleSetMarketplacePrice();
    }
  }, [pairLatestPrice, isPairLatestPriceFetching, wasPriceConditionPriceInChanged]);

  useEffect(() => {
    if (pairLatestPrice && !isPairLatestPriceFetching && orderPriceConditionPrice) {
      validateOpenOrderPrice({
        latestTokenPairPrice: pairLatestPrice,
        orderTokenInUsdPriceOracle: orderTokenIn.usdPriceOracle,
        orderTokenOutUsdPriceOracle: orderTokenOut.usdPriceOracle,
        price: orderPriceConditionPrice,
        priceCondition: orderPriceConditionType,
        tokenInUsdPrice,
        tokenOutUsdPrice,
        pricePrecision: openOrderPricePrecision,
      });
    }
  }, [
    pairLatestPrice,
    tokenInUsdPrice,
    tokenOutUsdPrice,
    isPairLatestPriceFetching,
    orderPriceConditionType,
    orderPriceConditionPrice,
    openOrderPricePrecision,
    validateOpenOrderPrice,
  ]);

  return (
    <div className="dark:bg-primary-light-gray/20 shadow-md dark:shadow-none p-3 rounded-lg">
      <TypographyH4 className="!text-sm mb-2">Open position</TypographyH4>

      <div className="flex items-start gap-1.5 lg:gap-2 flex-col lg:flex-row">
        <div className="flex flex-col w-full">
          <div className="flex justify-between items-center mb-1">
            <label className="text-left text-xs font-medium">You pay</label>
            {selectedTokenBalance !== undefined && (
              <div className="text-[10px] xl:text-xs text-gray-600 cursor-pointer" onClick={handleBalanceClick}>
                Balance:{' '}
                {formatAmount({
                  value: +selectedTokenBalance,
                  withoutFormatForSmallValue: true,
                  precise: true,
                  minimumFractionDigits: 6,
                })}
              </div>
            )}
          </div>
          <div
            className={cn('flex items-center border rounded', {
              ' border-red-400 ':
                amountInValidationError || insufficientBalanceError || insufficientOpenOrderMinAmountInError,
            })}
          >
            <TooltipProvider>
              <Tooltip open={!!amountInValidationError || insufficientOpenOrderMinAmountInError}>
                <TooltipTrigger className="flex flex-col w-full">
                  <Input
                    value={tokenAmountInput}
                    placeholder="0.0"
                    onChange={handleTokenAmountChange}
                    className={cn('p-1 mr-2 border-none  outline-none focus-visible:ring-0  lg:text-sm text-xs', {
                      'text-red-500  placeholder:text-red-500 focus-visible:ring-0':
                        amountInValidationError || insufficientBalanceError || insufficientOpenOrderMinAmountInError,
                    })}
                  />
                </TooltipTrigger>
                <TooltipContent
                  side="bottom"
                  className="bg-white/30 dark:bg-primary-black/30 backdrop-blur-lg p-2 lg:text-sm text-xs"
                >
                  {insufficientOpenOrderMinAmountInError ? (
                    <TypographyS className="text-red-500 text-xs">Amount in is low ({`< 0.2$`})</TypographyS>
                  ) : (
                    amountInValidationError && (
                      <TypographyS className="text-red-500 text-xs">{amountInValidationError}</TypographyS>
                    )
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TokenSelector
              key={`${orderTokenIn.address}-${orderTokenOut.address}`}
              tokenList={networkTokens}
              handleTokenChange={handleTokenInChange}
              selectedToken={orderTokenIn}
              className="px-2 border-none"
            />
          </div>
          <div className="flex gap-2 items-center">
            {tokenInUsdPrice && orderAmountIn && (
              <div className="text-xs text-gray-500  mt-1">~${(+orderAmountIn * tokenInUsdPrice).toFixed(2)}</div>
            )}

            {insufficientBalanceError && (
              <TypographyS className="text-red-500  mt-1 text-xs ml-auto">Insufficient balance</TypographyS>
            )}
          </div>
        </div>

        <Button
          variant="ghost"
          onClick={() => handleSelectedTokensChange({ tokenIn: orderTokenOut, tokenOut: orderTokenIn })}
          className="text-md xl:block hidden text-center mx-0  mt-5 bg-transparent hover:bg-transparent text-black/60 hover:text-black/40 dark:text-white/80 hover:dark:text-white/60"
        >
          <ArrowRightLeftIcon />
        </Button>

        <div className="flex flex-col w-full lg:w-[120px] ">
          <div className="flex items-center mb-1">
            <label className="text-left text-xs font-medium">You get</label>

            <Button
              variant="ghost"
              onClick={() => handleSelectedTokensChange({ tokenIn: orderTokenOut, tokenOut: orderTokenIn })}
              className=" xl:hidden block text-center !p-0 h-fit w-fit ml-auto bg-transparent hover:bg-transparent text-black/60 hover:text-black/40 dark:text-white/80 hover:dark:text-white/60"
            >
              <ArrowRightLeftIcon className=" max-h-3" />
            </Button>
          </div>
          <div className="flex items-center w-full lg:justify-center border rounded">
            <TokenSelector
              key={`${orderTokenIn.address}-${orderTokenOut.address}`}
              tokenList={availableOrderTokensOut}
              handleTokenChange={handleTokenOutChange}
              selectedToken={orderTokenOut}
              className="justify-between px-2 w-full border-none"
              contentClassName="justify-between w-full"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full lg:mt-3 mt-2">
        <div className="text-start text-xs font-medium mb-1 flex items-center">
          {getOpenOrderHeaderLabel(orderTokenIn.symbol, orderTokenOut.symbol, orderPriceConditionType)}
        </div>

        <div className="flex  lg:flex-row flex-col lg:gap-2 gap-1 w-full">
          <div className="flex flex-col w-full items-end">
            <PriceConditionInput
              open={!!orderPriceConditionValidationError || !!priceDifferenceWarning || priceOverflowError}
              inputValue={priceConditionPriceInput}
              onChange={handleOpenOrderInputPriceChange}
              disabled={isPairLatestPriceFetching}
              priceConditionValidationError={orderPriceConditionValidationError}
              priceDifferenceWarning={priceDifferenceWarning}
              priceOverflowError={priceOverflowError ? 'Price is above market' : ''}
              token={getTokenFromPriceConditionType(orderTokenIn, orderTokenOut, orderPriceConditionType)}
              handleSetMarketplacePrice={handleSetMarketplacePrice}
            />
            <div className="lg:block hidden">
              <StopMarketOrderPricePercentageSelector
                orderPricePercentage={orderPricePercentage}
                setOrderPricePercentage={handleOpenOrderPricePercentageChange}
                initialPrice={openOrder.priceCondition.initialValue}
                negative={openOrder.priceCondition.conditionType === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN}
              />
            </div>
          </div>

          <div className="flex items-center lg:items-start justify-end gap-2">
            <div className="lg:hidden block">
              <StopMarketOrderPricePercentageSelector
                orderPricePercentage={orderPricePercentage}
                setOrderPricePercentage={handleOpenOrderPricePercentageChange}
                initialPrice={openOrder.priceCondition.initialValue}
                negative={openOrder.priceCondition.conditionType === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN}
              />
            </div>
            <PriceConditionSwitcher
              handlePriceConditionChange={handleOrderPriceConditionChange}
              tokenIn={orderTokenIn}
              tokenOut={orderTokenOut}
              selectedCondition={orderPriceConditionType}
              disabled={isPairLatestPriceFetching}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
