import { z } from 'zod';

export enum ConditionTypeEnum {
  PRICE = 'price',
  SMA = 'sma',
  EMA = 'ema',
  RSI = 'rsi',
  BOLLINGER_BANDS = 'bollinger-bands',
}

export enum PriceConditionTypeEnum {
  TOKEN_IN_TOKEN_OUT = 'tokenIn/tokenOut',
  TOKEN_OUT_TOKEN_IN = 'tokenOut/tokenIn',
  TOKEN_IN_USD = 'tokenIn/usd',
  TOKEN_OUT_USD = 'tokenOut/usd',
}

export enum CrossoverOptionBollingerBandsEnum {
  PRICE_ABOVE_UPPER = 'priceAboveUpper',
  PRICE_BELOW_LOWER = 'priceBelowLower',
  PRICE_TOUCHER_UPPER = 'priceTouchesUpper',
  PRICE_TOUCHES_LOWER = 'priceTouchesLower',
  BAND_SQUEEZE = 'bandSqueeze',
}

export enum IntervalOptionBollingerBandsEnum {
  FIVE_MIN = '5m',
  THIRTY_MIN = '30m',
  ONE_HOUR = '1h',
  TWO_HOUR = '2h',
  ONE_DAY = '1d',
}

export enum IntervalOptionRSIEnum {
  FIVE_MIN = '5m',
  THIRTY_MIN = '30m',
  ONE_HOUR = '1h',
  TWO_HOUR = '2h',
  ONE_DAY = '1d',
}

export enum IntervalOptionSMAEnum {
  FIVE_MIN = '5m',
  THIRTY_MIN = '30m',
  ONE_HOUR = '1h',
  TWO_HOUR = '2h',
  ONE_DAY = '1d',
}

export enum IntervalOptionEMAEnum {
  FIVE_MIN = '5m',
  THIRTY_MIN = '30m',
  ONE_HOUR = '1h',
  TWO_HOUR = '2h',
  ONE_DAY = '1d',
}

export enum PeriodOptionRSIEnum {
  TEN = 10,
  FOURTEEN = 14,
  TWENTY = 20,
}

export enum PeriodOptionSMAEnum {
  TEN = 10,
  TWENTY = 20,
  FIFTY = 50,
}

export enum PeriodOptionEMAEnum {
  TEN = 10,
  TWENTY = 20,
  FIFTY = 50,
}

export enum ConditionOperatorEnum {
  AND = 'AND',
  OR = 'OR',
}

export const BollingerBandsSchema = z.object({
  type: z.literal(ConditionTypeEnum.BOLLINGER_BANDS),
  interval: z.nativeEnum(IntervalOptionBollingerBandsEnum),
  crossover: z.nativeEnum(CrossoverOptionBollingerBandsEnum),
});

export const RSISchema = z.object({
  type: z.literal(ConditionTypeEnum.RSI),
  threshold: z.number().min(0).max(100),
  period: z.nativeEnum(PeriodOptionRSIEnum),
  interval: z.nativeEnum(IntervalOptionRSIEnum),
});

export const PriceSchema = z.object({
  value: z.string().refine(
    (val) => {
      const num = parseFloat(val);
      return num > 0 && Number.isFinite(num);
    },
    {
      message: 'Price must be greater 0',
    },
  ),
  initialValue: z.string().refine(
    (val) => {
      const num = parseFloat(val);
      return num > 0 && Number.isFinite(num);
    },
    {
      message: 'Price must be greater 0',
    },
  ),
  conditionType: z.nativeEnum(PriceConditionTypeEnum),
});

export const SMASchema = z.object({
  interval: z.nativeEnum(IntervalOptionSMAEnum),
  period: z.number(),
  threshold: z.number().min(0).max(100),
  type: z.literal(ConditionTypeEnum.SMA),
});

export const EMASchema = z.object({
  interval: z.nativeEnum(IntervalOptionEMAEnum),
  period: z.number(),
  threshold: z.number().min(0).max(100),
  type: z.literal(ConditionTypeEnum.EMA),
});

export const TokenSchema = z.object({
  name: z.string(),
  address: z.string(),
  symbol: z.string(),
  decimals: z.number(),
  chainId: z.number(),
  isStablecoin: z.boolean(),
  logoUri: z.string(),
  env: z.string(),
  usdPriceOracle: z.string(),
});

export const LimitOpenOrderSchema = z.object({
  amountIn: z.string().refine(
    (val) => {
      const num = parseFloat(val);
      return num > 0 && Number.isFinite(num);
    },
    {
      message: 'Amount in must be a positive number',
    },
  ),
  tokenIn: TokenSchema,
  tokenOut: TokenSchema,
  priceCondition: PriceSchema,
});

export const LimitCloseOrderSchema = z.object({
  tokenOut: TokenSchema,
  priceCondition: PriceSchema,
});

export const FormLimitOpenOrderSchema = z.object({
  order: LimitOpenOrderSchema,
});
export const FormLimitCloseOrderSchema = z.object({
  order: LimitCloseOrderSchema,
});

export type LimitOpenOrderType = z.infer<typeof FormLimitOpenOrderSchema>;
export type LimitCloseOrderType = z.infer<typeof FormLimitCloseOrderSchema>;
export type OrderFormType = {
  openOrder: LimitOpenOrderType;
  closeOrder: LimitCloseOrderType | null;
};
export type SMAConditionFormType = z.infer<typeof SMASchema>;
export type EMAConditionFormType = z.infer<typeof EMASchema>;
export type RSIConditionFormType = z.infer<typeof RSISchema>;
export type PriceConditionFormType = z.infer<typeof PriceSchema>;
export type BollingerBandsConditionFormType = z.infer<typeof BollingerBandsSchema>;
