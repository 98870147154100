import { useMutation } from '@tanstack/react-query';
import { Address } from 'viem';
import { useSignTypedData } from 'wagmi';

import { STOP_MARKET_ORDER_MANAGER_ADDRESS_ARBITRUM } from '../../constants';
import { StopMarketOrderType } from '../../interfaces';

const openOrderTypes = {
  StopMarketOrder: [
    { name: 'user', type: 'address' },
    { name: 'orderId', type: 'uint256' },
    { name: 'amountIn', type: 'uint256' },
    { name: 'tokenIn', type: 'address' },
    { name: 'tokenOut', type: 'address' },
    { name: 'ttl', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'amountOutMin', type: 'uint256' },
  ],
};

const closeOrderTypes = {
  StopMarketCloseOrder: [
    { name: 'user', type: 'address' },
    { name: 'orderId', type: 'uint256' },
    { name: 'tokenOut', type: 'address' },
    { name: 'ttl', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'amountOutMin', type: 'uint256' },
  ],
};

export const useSignStopMarketOrderOnChain = () => {
  const { signTypedDataAsync } = useSignTypedData();

  const signOrder = async (orderInfo: {
    user: string;
    orderId: string;
    amountIn: string;
    tokenIn: string;
    tokenOut: string;
    ttl: number;
    nonce: string;
    amountOutMin: string;
    chainId: number;
    orderType: StopMarketOrderType;
  }) => {
    const domain = {
      name: 'OrderManagerV1',
      version: '1',
      chainId: orderInfo.chainId,
      verifyingContract: STOP_MARKET_ORDER_MANAGER_ADDRESS_ARBITRUM as Address,
    };

    if (orderInfo.orderType === StopMarketOrderType.OPEN) {
      const orderData = {
        user: orderInfo.user,
        orderId: BigInt(orderInfo.orderId),
        amountIn: BigInt(orderInfo.amountIn),
        tokenIn: orderInfo.tokenIn,
        tokenOut: orderInfo.tokenOut,
        ttl: BigInt(orderInfo.ttl),
        nonce: BigInt(orderInfo.nonce),
        amountOutMin: BigInt(orderInfo.amountOutMin),
      };
      const signature = await signTypedDataAsync({
        domain,
        types: openOrderTypes,
        primaryType: 'StopMarketOrder',
        message: orderData,
      });

      return signature;
    } else {
      const orderData = {
        user: orderInfo.user,
        orderId: BigInt(orderInfo.orderId),
        tokenOut: orderInfo.tokenOut,
        ttl: BigInt(orderInfo.ttl),
        nonce: BigInt(orderInfo.nonce),
        amountOutMin: BigInt(orderInfo.amountOutMin),
      };

      const signature = await signTypedDataAsync({
        domain,
        types: closeOrderTypes,
        primaryType: 'StopMarketCloseOrder',
        message: orderData,
      });

      return signature;
    }
  };

  const { data, isPending, error, mutateAsync } = useMutation({
    mutationFn: signOrder,
  });

  return {
    signedOrderSignature: data,
    signOrderSignatureError: error,
    isStopMarketOrderSigning: isPending,
    signOrderSignature: mutateAsync,
  };
};
