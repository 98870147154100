import { useState } from 'react';
import { Card, CardContent } from '../../ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { OrderTable } from './order-table';

export enum OrderListTabEnum {
  ACTIVE = 'active',
  HISTORY = 'history',
}

export type OrderTableFilterType = {
  chainId: number | undefined;
  tokenInId: string | undefined;
  tokenOutId: string | undefined;
  closeTokenInId: string | undefined;
  closeTokenOutId: string | undefined;
};

const initialFilter = {
  chainId: undefined,
  tokenInId: undefined,
  tokenOutId: undefined,
  closeTokenInId: undefined,
  closeTokenOutId: undefined,
};

export const OrderList = () => {
  const [activeTab, setActiveTab] = useState<OrderListTabEnum>(OrderListTabEnum.ACTIVE);

  const [activeFilters, setActiveFilters] = useState<{
    active: OrderTableFilterType;
    history: OrderTableFilterType;
  }>({
    active: initialFilter,
    history: initialFilter,
  });

  return (
    <Tabs
      value={activeTab}
      onValueChange={(option) => setActiveTab(option as OrderListTabEnum)}
      defaultValue={OrderListTabEnum.ACTIVE}
      className="w-full md:mb-2"
    >
      <div className="flex items-center justify-between space-x-2 ">
        <TabsList className="xs:w-fit w-full flex items-center">
          <TabsTrigger className="xs:w-fit w-full" value={OrderListTabEnum.ACTIVE}>
            Active
          </TabsTrigger>
          <TabsTrigger className="xs:w-fit w-full" value={OrderListTabEnum.HISTORY}>
            History
          </TabsTrigger>
        </TabsList>
      </div>

      <TabsContent value={OrderListTabEnum.ACTIVE}>
        <OrderTable
          type={OrderListTabEnum.ACTIVE}
          activeFilters={activeFilters.active}
          setActiveFilters={setActiveFilters}
        />
      </TabsContent>
      <TabsContent value={OrderListTabEnum.HISTORY}>
        <OrderTable
          type={OrderListTabEnum.HISTORY}
          activeFilters={activeFilters.history}
          setActiveFilters={setActiveFilters}
        />
      </TabsContent>
    </Tabs>
  );
};

export const OrderListCard = () => {
  return (
    <Card className="w-full">
      <CardContent className="lg:py-5 px-2 py-2">
        <OrderList />
      </CardContent>
    </Card>
  );
};
