import { useMutation } from '@tanstack/react-query';
import { APIConfig } from '../../configs';
import { axiosClientWithCredentials } from '../../utils';

export const reserveStopMarketOrderNonce = async () => {
  return await axiosClientWithCredentials.get<string>(`${APIConfig.baseURL}/ds/orders/reserve-nonce`);
};

export const useReserveStopMarketOrderNonce = () => {
  const { data, isPending, error, mutateAsync } = useMutation({
    mutationFn: () => reserveStopMarketOrderNonce(),
    mutationKey: ['reserveOrderNonce'],
  });

  return {
    reservedOrderNonce: data?.data,
    reserveOrderNonceLoading: isPending,
    reserveOrderNonceError: error,
    reserveOrderNonce: mutateAsync,
  };
};
