import { InfiniteData, keepPreviousData, QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { APIConfig } from '../../configs';
import { OrderStatusEnum, SortOptionEnum, UserStopMarketOrdersResponse } from '../../interfaces';
import { axiosClientWithCredentials } from '../../utils';

export type UserOrdersFilter = {
  status?: OrderStatusEnum[];
  onlyActive?: boolean;
  onlyInactive?: boolean;
  chainId?: number;
  tokenIn?: string;
  tokenOut?: string;
  closeTokenIn?: string;
  closeTokenOut?: string;
};

export type GetUserOrdersParams = {
  size: number;
  page: number;
  filter: UserOrdersFilter;
  sort: {
    createdAt?: SortOptionEnum;
  };
};

export const getUserOrders = async (queryParams: string) => {
  return await axiosClientWithCredentials.get<UserStopMarketOrdersResponse>(
    `${APIConfig.baseURL}/ds/orders?${queryParams}`,
  );
};

export const useGetUserStopMarketOrders = (params: GetUserOrdersParams, enabled: boolean) => {
  const url = new URLSearchParams();

  url.set('size', `${params.size}`);
  url.set('page', `${params.page}`);

  if (Object.values(params.filter).length > 0) {
    url.set('filter', JSON.stringify(params.filter));
  }

  if (Object.values(params.sort).length > 0) {
    url.set('sort', JSON.stringify(params.sort));
  }

  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery<
    UserStopMarketOrdersResponse,
    Error,
    InfiniteData<UserStopMarketOrdersResponse, unknown>,
    QueryKey,
    number
  >({
    queryKey: ['userOrders', url.toString()],
    queryFn: async ({ pageParam = 1 }) => {
      url.set('size', `${params.size}`);
      url.set('page', `${pageParam}`);

      const fetchedData = await getUserOrders(url.toString());
      return fetchedData.data;
    },
    staleTime: 1000 * 10, // 10 sec
    initialPageParam: 1,
    getNextPageParam: (_lastGroup) => _lastGroup.page + 1,
    refetchOnWindowFocus: false,
    enabled,

    placeholderData: keepPreviousData,
  });

  const flatData = useMemo(() => data?.pages?.flatMap((page) => page.results) ?? [], [data, url.toString()]);

  return {
    areUserStopMarketOrdersLoading: isFetching || isLoading,
    fetchNextPage: fetchNextPage,
    orders: flatData,
    lastOrders: data?.pages.at(-1)?.results || [],
  };
};
