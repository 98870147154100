import { FC } from 'react';
import { Token } from '../../../interfaces';
import { cn, formatAmount } from '../../../utils';
import { TokenLabel } from '../../custom/token-label';
import { TypographyP, TypographyS } from '../../ui/typography';

type SwapAmountLabelProps = {
  amountIn: string | null;
  amountOut: string | null;
  tokenIn: Token;
  tokenOut: Token;
  isCloseOrder: boolean;
  wrapperClassName?: string;
};

export const SwapAmountLabel: FC<SwapAmountLabelProps> = ({
  wrapperClassName,
  amountIn,
  amountOut,
  tokenIn,
  tokenOut,
  isCloseOrder,
}) => {
  return (
    <div className={cn('text-xs md:text-sm flex', wrapperClassName)}>
      <TypographyP className="mr-1">
        Swap
        {amountIn ? (
          <TypographyS
            className={cn('mx-1 dark:text-yellow-400', {
              'dark:text-purple-400': isCloseOrder,
            })}
          >
            {formatAmount({
              value: +amountIn,
              withoutFormatForSmallValue: true,
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
              precise: true,
            })}
          </TypographyS>
        ) : null}
      </TypographyP>
      {<TokenLabel textClassName="text-xs md:text-sm" token={tokenIn} imgClassName="md:w-3.5 md:h-3.5 w-3 h-3" />}
      <TypographyP className="mx-1 ">for </TypographyP>
      {amountOut ? (
        <>
          <TypographyS
            className={cn('mr-1 dark:text-yellow-400', {
              'dark:text-purple-400': isCloseOrder,
            })}
          >
            {formatAmount({
              value: +amountOut,
              withoutFormatForSmallValue: true,
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
              precise: true,
            })}{' '}
          </TypographyS>
        </>
      ) : null}
      {<TokenLabel textClassName="text-xs md:text-sm" token={tokenOut} imgClassName="md:w-3.5 md:h-3.5  w-3 h-3" />}
    </div>
  );
};
