import { FC } from 'react';
import { Network } from '../../interfaces';
import { cn } from '../../utils';

type NetworkLabelProps = {
  network: Network;
  className?: string;
};

export const NetworkLogo: FC<NetworkLabelProps> = ({ network, className }) => {
  return <img src={network.logoUri} alt={`${network.name} logo`} className={cn('w-5 h-5 mr-2', className)} />;
};
