import { FC } from 'react';
import { LimitOpenOrderType } from '../../../../interfaces';
import { Button } from '../../../ui/button';
import { StopMarketOrderInsufficientError } from '../preview-modal/stop-market-order-insufficient-error';

type StopMarketOrderPreviewModalOpenOrderActionsProps = {
  showInsufficientTokenInBalanceError: boolean;
  amountOfOpenOrderTokenInToBeApprovedForOrders: string | undefined;
  openOrder: LimitOpenOrderType['order'];
  isOpenOrderTokenTotalAmountApproved: boolean;
  areStopMarketOrdersTokensAllowanceLoading: boolean;
  isOpenOrderTokenApproving: boolean;
  isStopMarketOrderQuoteEstimationLoading: boolean;
  isOpenOrderSigning: boolean;
  isOpenOrderTokenInAllowanceLoading: boolean;
  approveOpenOrderTokenHandler: () => Promise<void>;
  signOpenOrderHandler: () => Promise<void>;
};

export const StopMarketOrderPreviewModalOpenOrderActions: FC<StopMarketOrderPreviewModalOpenOrderActionsProps> = ({
  showInsufficientTokenInBalanceError,
  amountOfOpenOrderTokenInToBeApprovedForOrders,
  openOrder,
  isOpenOrderTokenApproving,
  isOpenOrderTokenTotalAmountApproved,
  isOpenOrderSigning,
  areStopMarketOrdersTokensAllowanceLoading,
  isStopMarketOrderQuoteEstimationLoading,
  isOpenOrderTokenInAllowanceLoading,
  approveOpenOrderTokenHandler,
  signOpenOrderHandler,
}) => {
  if (showInsufficientTokenInBalanceError && amountOfOpenOrderTokenInToBeApprovedForOrders) {
    return (
      <StopMarketOrderInsufficientError
        amount={amountOfOpenOrderTokenInToBeApprovedForOrders}
        token={openOrder.tokenIn}
      />
    );
  }

  if (!isOpenOrderTokenTotalAmountApproved) {
    return (
      <Button
        className="bg-blue-500 hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
        onClick={approveOpenOrderTokenHandler}
        disabled={
          areStopMarketOrdersTokensAllowanceLoading || isOpenOrderTokenApproving || isOpenOrderTokenInAllowanceLoading
        }
        loading={
          isOpenOrderTokenApproving || areStopMarketOrdersTokensAllowanceLoading || isOpenOrderTokenInAllowanceLoading
        }
      >
        Allow to use {openOrder.tokenIn.symbol}
      </Button>
    );
  }

  if (isStopMarketOrderQuoteEstimationLoading) {
    return (
      <Button
        className="bg-blue-500 hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
        disabled={isStopMarketOrderQuoteEstimationLoading}
        loading={isStopMarketOrderQuoteEstimationLoading}
      >
        Estimating order quote
      </Button>
    );
  }

  return (
    <Button
      className="bg-blue-500 hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
      disabled={isOpenOrderSigning}
      loading={isOpenOrderSigning}
      onClick={signOpenOrderHandler}
    >
      Sign open order
    </Button>
  );

  return null;
};
