import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { FC } from 'react';
import { LimitCloseOrderType, LimitOpenOrderType, StopMarketOrderQuoteEstimationType } from '../../../../interfaces';
import { cn } from '../../../../utils';
import { TypographyP, TypographyS } from '../../../ui/typography';
import { PriceConditionSummary } from '../../order/price-condition-summary-label';
import { SwapAmountLabel } from '../../order/swap-amount-label';
import { StopMarketCloseOrderTokenApprovalState } from '../close-order/stop-market-close-order-token-approval-state';
import { StopMarketOrderSignState } from '../components/stop-market-order-sign-state';
import { StopMarketOrderTokenEstimation } from '../components/stop-market-order-token-estimation-label';

type StopMarketOrderClosePositionPreviewProps = {
  openOrder: LimitOpenOrderType['order'];
  closeOrder: LimitCloseOrderType['order'];
  approveOpenOrderMessage: string;
  approveCloseOrderTokenError: string;
  isCloseOrderTokenTotalAmountApproved: boolean;
  isCloseOrderTokenApproving: boolean;
  areStopMarketOrdersTokensAllowanceLoading: boolean;
  amountOfCloseOrderTokenInToBeApprovedForOrders: string | undefined;
  stopMarketOrdersTokensAllowanceError: string | undefined;
  isOpenOrderTokenTotalAmountApproved: boolean;
  closeOrderQuoteEstimation: StopMarketOrderQuoteEstimationType | null;
  isStopMarketOrderQuoteEstimationLoading: boolean;
  stopMarketOrderQuoteEstimationError: string | undefined;
  closeOrderTokenInAllowanceError: string | undefined;
  isCloseOrderTokenInAllowanceLoading: boolean;
  isCloseOrderSigned: boolean;
  isCloseOrderSigning: boolean;
  refetchCloseOrderTokenInAllowance: (options?: RefetchOptions) => Promise<QueryObserverResult<string, Error>>;
  approveCloseOrderTokenHandler: () => Promise<void>;
};

export const StopMarketOrderClosePositionPreview: FC<StopMarketOrderClosePositionPreviewProps> = ({
  openOrder,
  closeOrder,
  isCloseOrderSigned,
  isCloseOrderSigning,
  approveOpenOrderMessage,
  approveCloseOrderTokenError,
  isCloseOrderTokenTotalAmountApproved,
  isCloseOrderTokenApproving,
  areStopMarketOrdersTokensAllowanceLoading,
  amountOfCloseOrderTokenInToBeApprovedForOrders,
  stopMarketOrdersTokensAllowanceError,
  isOpenOrderTokenTotalAmountApproved,
  isStopMarketOrderQuoteEstimationLoading,
  closeOrderQuoteEstimation,
  stopMarketOrderQuoteEstimationError,
  closeOrderTokenInAllowanceError,
  isCloseOrderTokenInAllowanceLoading,
  refetchCloseOrderTokenInAllowance,
  approveCloseOrderTokenHandler,
}) => {
  return (
    <div className="">
      <TypographyP className="dark:text-purple-400 font-medium text-xs md:text-sm mb-2">{'Close Position'}</TypographyP>
      <div className="">
        <SwapAmountLabel
          wrapperClassName="md:px-0 px-2"
          amountIn={null}
          amountOut={null}
          tokenIn={openOrder.tokenOut}
          tokenOut={closeOrder.tokenOut}
          isCloseOrder={true}
        />

        <div className="mt-1 text-xs md:text-sm flex md:flex-row flex-col gap-1 md:px-0 px-2">
          <TypographyS className={cn('dark:text-purple-400 italic')}>Condition: </TypographyS>

          <PriceConditionSummary
            tokenIn={openOrder.tokenOut}
            tokenOut={closeOrder.tokenOut}
            value={closeOrder.priceCondition.value}
            conditionType={closeOrder.priceCondition.conditionType}
            closeOrder={true}
          />
        </div>
      </div>

      <div className="p-3 flex mt-4 flex-col space-y-2 border border-dashed dark:border-primary-light-gray border-primary-light-gray/40 rounded-lg w-full  mb-3">
        <StopMarketCloseOrderTokenApprovalState
          approveTokenError={approveCloseOrderTokenError}
          isTokenApproved={isCloseOrderTokenTotalAmountApproved}
          isTokenApproving={isCloseOrderTokenApproving}
          areStopMarketOrdersTokensAllowanceLoading={areStopMarketOrdersTokensAllowanceLoading}
          tokenIn={openOrder.tokenOut}
          approveOpenOrderMessage={approveOpenOrderMessage}
          amountOfTokenToBeApproved={amountOfCloseOrderTokenInToBeApprovedForOrders}
          stopMarketOrdersTokensAllowanceError={stopMarketOrdersTokensAllowanceError}
          closeOrderTokenInAllowanceError={closeOrderTokenInAllowanceError}
          isCloseOrderTokenInAllowanceLoading={isCloseOrderTokenInAllowanceLoading}
          isStopMarketOrderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
          refetchCloseOrderTokenInAllowance={refetchCloseOrderTokenInAllowance}
          onTryApproveAgainClick={approveCloseOrderTokenHandler}
        />

        <StopMarketOrderTokenEstimation
          tokenIn={openOrder.tokenOut}
          tokenOut={closeOrder.tokenOut}
          isTokenInApproved={isOpenOrderTokenTotalAmountApproved}
          isCloseOrder={true}
          orderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
          orderQuoteEstimation={closeOrderQuoteEstimation}
          orderEstimationError={stopMarketOrderQuoteEstimationError}
        />

        <StopMarketOrderSignState
          labelClassName="dark:text-purple-400"
          isOrderSigned={isCloseOrderSigned}
          isOrderSigning={isCloseOrderSigning}
        />
      </div>
    </div>
  );
};
