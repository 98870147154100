import { FilterIcon } from 'lucide-react';
import { FC, useState } from 'react';
import { cn } from '../../../../utils';
import { getNetworks, getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { ChainSelector } from '../../../network/chain-selector';
import { Button } from '../../../ui/button';
import { Dialog, DialogContent, DialogFooter, DialogTitle, DialogTrigger } from '../../../ui/dialog';
import { TypographyH4, TypographyP } from '../../../ui/typography';
import { TokenSelector } from '../../order/token-selector';
import { OrderTableFilterType } from '../order-list';

type MobileOrdersTableFilterProps = {
  onClearFilterClick: () => void;
  onApplyFilterClick: (filters: OrderTableFilterType) => void;
  activeFilter: OrderTableFilterType;
};

const defaultFilter: OrderTableFilterType = {
  chainId: undefined,
  closeTokenInId: undefined,
  closeTokenOutId: undefined,
  tokenInId: undefined,
  tokenOutId: undefined,
};

export const MobileOrdersTableFilter: FC<MobileOrdersTableFilterProps> = ({
  onApplyFilterClick,
  onClearFilterClick,
  activeFilter,
}) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [filter, setFilter] = useState<OrderTableFilterType>(activeFilter);

  const tokenList = getNetworks()
    .filter((n) => (filter.chainId ? n.chainId === filter.chainId : true))
    .flatMap((n) => n.tokens);

  return (
    <Dialog
      open={showFilters}
      onOpenChange={(open) => {
        setShowFilters(open);
        setFilter(defaultFilter);
      }}
    >
      <DialogTrigger asChild>
        <Button variant="ghost" className="p-1 w-fit h-fit ml-1 ">
          <FilterIcon
            className={cn('dark:text-white/70 text-black/60 max-w-3 max-h-3', {
              'dark:text-blue-400 contrast-150 text-blue-400': Object.values(activeFilter).some(Boolean),
            })}
          />
        </Button>
      </DialogTrigger>
      <DialogTitle className="hidden">order info</DialogTitle>
      <DialogContent className="flex h-full w-full xs:w-fit xs:h-fit overflow-auto  justify-start  flex-col gap-4  p-3">
        <div className="flex flex-col items-start">
          <TypographyH4 className="mb-2">Stop market orders filters</TypographyH4>
          <div className="p-2">
            <TypographyP className="text-xs font-semibold mb-1 dark:text-white">Filter by chain</TypographyP>
            <ChainSelector
              className=""
              selectedChainId={filter.chainId || activeFilter.chainId}
              handleChainIdChange={(chainId) =>
                setFilter((prev) => ({
                  ...prev,
                  chainId,
                }))
              }
            />
          </div>

          <div className="grid grid-cols-2 gap-4 mt-2 p-2 w-full">
            <div className="flex flex-col space-y-2 w-full">
              <TypographyP className="font-semibold dark:text-yellow-400 text-[12px]">Filter by open pair</TypographyP>

              <TokenSelector
                key={`${filter.chainId}-open-token-in`}
                className="px-1 mr-0 flex justify-between !w-full"
                tokenList={tokenList}
                label="Token In"
                showNetworkLabel
                contentClassName="w-full flex justify-between"
                selectedToken={filter?.tokenInId ? getTokenByTokenId(filter.tokenInId) : undefined}
                handleTokenChange={(token) =>
                  setFilter((prev) => ({
                    ...prev,
                    tokenInId: `${token.chainId}_${token.address}`,
                  }))
                }
              />

              <TokenSelector
                key={`${filter.chainId}-open-token-out`}
                className="px-1 mr-0 flex justify-between !w-full"
                tokenList={tokenList}
                label="Token Out"
                showNetworkLabel
                selectedToken={filter?.tokenOutId ? getTokenByTokenId(filter.tokenOutId) : undefined}
                handleTokenChange={(token) =>
                  setFilter((prev) => ({
                    ...prev,
                    tokenOutId: `${token.chainId}_${token.address}`,
                  }))
                }
                contentClassName="w-full flex justify-between"
              />
            </div>

            <div className="flex flex-col space-y-2">
              <TypographyP className="font-semibold dark:text-purple-400 text-[12px]">Filter by close pair</TypographyP>

              <TokenSelector
                key={`${filter.chainId}-close-token-in`}
                className="px-1 mr-0 flex justify-between !w-full"
                tokenList={tokenList}
                label="Token In"
                showNetworkLabel
                contentClassName="w-full flex justify-between"
                selectedToken={filter?.closeTokenInId ? getTokenByTokenId(filter.closeTokenInId) : undefined}
                handleTokenChange={(token) =>
                  setFilter((prev) => ({
                    ...prev,
                    closeTokenInId: `${token.chainId}_${token.address}`,
                  }))
                }
              />

              <TokenSelector
                key={`${filter.chainId}-close-token-out`}
                className="px-1 mr-0 flex justify-between !w-full"
                tokenList={tokenList}
                label="Token Out"
                showNetworkLabel
                selectedToken={filter?.closeTokenOutId ? getTokenByTokenId(filter.closeTokenOutId) : undefined}
                handleTokenChange={(token) =>
                  setFilter((prev) => ({
                    ...prev,
                    closeTokenOutId: `${token.chainId}_${token.address}`,
                  }))
                }
                contentClassName="w-full flex justify-between"
              />
            </div>
          </div>
        </div>

        <DialogFooter className="flex gap-2 mt-auto flex-row items-center w-full">
          <Button
            onClick={() => {
              setFilter(defaultFilter);
              onClearFilterClick();
              setShowFilters(false);
            }}
            variant="ghost"
            className="dark:text-white border px-2 py-1.5 h-fit text-xs w-full"
          >
            Clear
          </Button>
          <Button
            disabled={!filter}
            onClick={() => {
              filter && onApplyFilterClick(filter);
              setShowFilters(false);
            }}
            className="dark:bg-primary-light-gray bg-primary-black/10 dark:text-white  w-full px-2 py-1.5 h-fit text-xs"
            variant="ghost"
          >
            Apply
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
