import { FC } from 'react';
import { Network, Token } from '../../interfaces';
import { cn } from '../../utils';

const TOKENS_WITHOUT_BACKGROUND = ['WETH', 'UNI', 'ZRO', 'FET', 'XRP'].map((t) => t.toLowerCase());

type TokenLogoProps = {
  token: Token;
  showNetworkLabel?: boolean;
  network?: Network;
  imgClassName?: string;
} & React.HTMLProps<HTMLDivElement>;

export const TokenLogo: FC<TokenLogoProps> = ({ token, network, imgClassName, showNetworkLabel, ...props }) => {
  return (
    <div className="flex gap-2 items-center " {...props}>
      {network && showNetworkLabel ? (
        <img
          src={network.logoUri}
          alt={network.name}
          className="w-2.5 shrink-0 h-2.5 z-[2] absolute bottom-1 left-3.5"
        />
      ) : null}

      <div className="relative flex shrink-0">
        <img
          src={token.logoUri}
          alt={token.symbol}
          className={cn('w-3.5  h-3.5 z-[1] relative rounded-full', imgClassName)}
        />
        {TOKENS_WITHOUT_BACKGROUND.includes(token.symbol.toLowerCase()) ? (
          <div
            className={cn(
              'bg-white/90 rounded-full  w-4 h-4 absolute z-0 -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2',
              imgClassName,
            )}
          ></div>
        ) : null}
      </div>
    </div>
  );
};
