import { CheckCircle2Icon, CircleHelpIcon } from 'lucide-react';
import { FC } from 'react';
import { useBreakpoints } from '../../../../hooks';
import { cn } from '../../../../utils';
import { SpinnerIcon } from '../../../icons/spinner-icon';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { TypographyP, TypographyS } from '../../../ui/typography';

type StopMarketOrderSignStateProps = {
  isOrderSigning: boolean;
  isOrderSigned: boolean;
  labelClassName?: string;
};

export const StopMarketOrderSignState: FC<StopMarketOrderSignStateProps> = ({
  isOrderSigning,
  isOrderSigned,
  labelClassName,
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <div className="flex md:items-center md:flex-row flex-col gap-1">
      <TypographyS className={cn('dark:text-yellow-400 text-xs font-semibold', labelClassName)}>
        Order status
      </TypographyS>
      <div className="flex gap-1 md:pl-0 pl-2">
        {isOrderSigning ? (
          <div className="flex items-center gap-1">
            <TypographyS className="text-xs dark:text-white/70 text-black/70 ">signing order</TypographyS>

            <SpinnerIcon className="w-3 h-3" />
          </div>
        ) : isOrderSigned ? (
          <>
            <TypographyS className="text-xs dark:text-white/70">signed</TypographyS>
            <CheckCircle2Icon className="text-green-600 w-3.5 h-3.5" />
          </>
        ) : (
          <>
            <TypographyS className="font-normal text-xs  dark:text-white/70">require signing signature</TypographyS>
            <Popover>
              <PopoverTrigger className="w-fit group flex items-center text-[11px] justify-center">
                <CircleHelpIcon className="dark:text-white/70 dark:group-hover:text-white text-black/70 group-hover:text-black w-3.5 h-3.5" />
              </PopoverTrigger>

              <PopoverContent
                align="center"
                side={isMobile ? 'bottom' : 'right'}
                className="bg-white/30 shadow space-y-1 dark:bg-primary-black/30 max-w-[240px] w-fit backdrop-blur-lg p-2"
              >
                <TypographyP className="dark:text-white text-[11px]">
                  Signing <strong>verifies your identity</strong>, <strong>secures execution</strong>, and{' '}
                  <strong>confirms order details for on-chain processing</strong> while keeping your{' '}
                  <strong>private key safe</strong>.
                </TypographyP>
              </PopoverContent>
            </Popover>
          </>
        )}
      </div>
    </div>
  );
};
