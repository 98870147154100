import { ArrowLeftRightIcon } from 'lucide-react';
import { FC } from 'react';
import { PriceConditionTypeEnum, Token } from '../../../interfaces';
import { Button } from '../../ui/button';

const getPriceConditionLabel = (type: PriceConditionTypeEnum, tokenIn: Token, tokenOut: Token) => {
  const tokenInSymbol = tokenIn.symbol;
  const tokenOutSymbol = tokenOut.symbol;

  if (type === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) return `${tokenOutSymbol}`;
  if (type === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) return `${tokenInSymbol}`;
};

type PriceConditionSwitcherProps = {
  handlePriceConditionChange: (condition: PriceConditionTypeEnum) => void;
  selectedCondition: PriceConditionTypeEnum;
  tokenIn: Token;
  tokenOut: Token;
  disabled: boolean;
};

export const PriceConditionSwitcher: FC<PriceConditionSwitcherProps> = ({
  handlePriceConditionChange,
  selectedCondition,
  tokenIn,
  tokenOut,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={() => {
        handlePriceConditionChange(
          selectedCondition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN
            ? PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT
            : PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN,
        );
      }}
      className="flex lg:min-w-[80px] items-center shrink-0 px-1 bg-transparent shadow-none dark:hover:text-white/70 hover:text-black/70 hover:bg-transparent text-black dark:text-white"
    >
      <div className="flex gap-1 max-w-full items-center !text-[10px]">
        {getPriceConditionLabel(selectedCondition, tokenIn, tokenOut)}
      </div>
      <ArrowLeftRightIcon />
    </Button>
  );
};
