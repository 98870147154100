import { Signature } from 'ethers';
import { LimitCloseOrderType, LimitOpenOrderType, StopMarketOrderQuoteEstimationType } from '../../interfaces';

export const mapOpenOrderForCreation = (
  openOrderTokenInAmountInWei: string,
  openOrderQuoteEstimation: StopMarketOrderQuoteEstimationType,
  openOrder: LimitOpenOrderType['order'],
  openOrderPriceConditionValueWei: string,
  openOrderPriceConditionInitialValueWei: string,
  reservedOpenOrderNonce: string,
  signedOpenOrderSignature: string,
) => {
  const {
    v: openOrderSignatureV,
    r: openOrderSignatureR,
    s: openOrderSignatureS,
  } = Signature.from(signedOpenOrderSignature);

  return {
    amountIn: openOrderTokenInAmountInWei,
    minAmountOut: openOrderQuoteEstimation.minAmountOut,
    maxAmountOut: openOrderQuoteEstimation.maxAmountOut,
    priceCondition: {
      ...openOrder.priceCondition,
      value: openOrderPriceConditionValueWei,
      initialValue: openOrderPriceConditionInitialValueWei,
    },
    tokenIn: openOrder.tokenIn.address,
    tokenOut: openOrder.tokenOut.address,
    nonce: reservedOpenOrderNonce,
    signatureR: openOrderSignatureR,
    signatureS: openOrderSignatureS,
    signatureV: openOrderSignatureV,
  };
};

export const mapCloseOrderForCreation = (
  openOrderQuoteEstimation: StopMarketOrderQuoteEstimationType,
  closeOrderQuoteEstimation: StopMarketOrderQuoteEstimationType,
  openOrder: LimitOpenOrderType['order'],
  closeOrder: LimitCloseOrderType['order'],
  closeOrderPriceConditionValueWei: string,
  closeOrderPriceConditionInitialValueWei: string,
  reservedCloseOrderNonce: string,
  signedCloseOrderSignature: string,
) => {
  const {
    v: closeOrderSignatureV,
    r: closeOrderSignatureR,
    s: closeOrderSignatureS,
  } = Signature.from(signedCloseOrderSignature);

  return {
    priceCondition: {
      ...closeOrder.priceCondition,
      value: closeOrderPriceConditionValueWei!,
      initialValue: closeOrderPriceConditionInitialValueWei!,
    },
    tokenIn: openOrder.tokenOut.address,
    tokenOut: closeOrder.tokenOut.address,
    amountIn: openOrderQuoteEstimation.minAmountOut,
    minAmountOut: closeOrderQuoteEstimation!.minAmountOut,
    maxAmountOut: closeOrderQuoteEstimation!.maxAmountOut,
    signatureR: closeOrderSignatureR,
    signatureS: closeOrderSignatureS,
    signatureV: closeOrderSignatureV,
    nonce: reservedCloseOrderNonce!,
  };
};
