import { useQuery } from '@tanstack/react-query';
import { APIConfig } from '../../configs';
import { UserStopMarketOrdersTokensAllowance } from '../../interfaces';
import { axiosClientWithCredentials } from '../../utils';

export type GetUserOrdersTokensAllowanceParams = {
  tokenInId: string;
  tokenOutId: string;
};

export const getUserOrdersTokensAllowance = async (queryParams: string) => {
  return await axiosClientWithCredentials.get<UserStopMarketOrdersTokensAllowance>(
    `${APIConfig.baseURL}/ds/orders/tokens-allowance?${queryParams}`,
  );
};

export const useGetUserStopMarketOrdersTokensAllowance = (
  params: GetUserOrdersTokensAllowanceParams,
  enabled: boolean = true,
) => {
  const url = new URLSearchParams();

  url.set('tokenInId', `${params.tokenInId}`);
  url.set('tokenOutId', `${params.tokenOutId}`);

  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryFn: () => getUserOrdersTokensAllowance(url.toString()),
    queryKey: ['tokensAllowance', params.tokenInId, params.tokenOutId],
    enabled,
    staleTime: 1000 * 30,
    refetchOnMount: 'always',
  });

  return {
    areStopMarketOrdersTokensAllowanceLoading: isFetching || isLoading,
    stopMarketOrdersTokensAllowance: data?.data,
    stopMarketOrdersTokensAllowanceError: error,
    refetchStopMarketOrdersTokensAllowance: refetch,
  };
};
