import { useCallback, useState } from 'react';
import { PriceConditionTypeEnum } from '../../../interfaces';

export type ValidateCloseOrderPriceConditionParams = {
  price: string;
  priceCondition: PriceConditionTypeEnum;
  openOrderPrice: string | null;
  orderTokenInUsdPriceOracle: string | null;
  tokenInUsdPrice: number | null;
  orderTokenOutUsdPriceOracle: string | null;
  tokenOutUsdPrice: number | null;
  orderPricePrecision: number;
};

export const useValidateCloseOrderPriceCondition = () => {
  const [priceDifferenceWarning, setPriceDifferenceWarning] = useState<{
    type: 'high' | 'low';
    delta: number;
  } | null>(null);

  const [priceOverflowError, setPriceOverflowError] = useState<boolean>(false);

  const validatePrice = useCallback(
    ({ price, priceCondition, openOrderPrice, orderPricePrecision }: ValidateCloseOrderPriceConditionParams) => {
      if (!price || price === '0') {
        setPriceDifferenceWarning(null);

        return;
      }

      if (openOrderPrice && priceCondition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) {
        const marketPrice = +Number(openOrderPrice).toFixed(orderPricePrecision);
        const userPrice = Number(price);

        // disallow to create orders with lower market price than user price
        if (userPrice < marketPrice) {
          setPriceOverflowError(true);
        } else {
          setPriceOverflowError((prev) => (prev ? false : prev));
        }

        const percentageDifference = Math.round((userPrice / marketPrice) * 100);

        if (percentageDifference > 120) {
          setPriceDifferenceWarning({ type: 'high', delta: percentageDifference });
        } else if (percentageDifference < 80) {
          setPriceDifferenceWarning({ type: 'low', delta: Math.abs(percentageDifference) });
        } else {
          setPriceDifferenceWarning(null);
        }
      } else if (openOrderPrice && priceCondition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) {
        const userPrice = Number(price);
        const marketPrice = +Number(openOrderPrice).toFixed(orderPricePrecision);
        // disallow to create orders with lower market price than user price

        if (userPrice > marketPrice) {
          setPriceOverflowError(true);
        } else {
          setPriceOverflowError((prev) => (prev ? false : prev));
        }

        const percentageDifference = Math.round((userPrice / marketPrice) * 100);

        if (percentageDifference > 120) {
          setPriceDifferenceWarning({ type: 'high', delta: percentageDifference });
        } else if (percentageDifference < 80) {
          setPriceDifferenceWarning({ type: 'low', delta: Math.abs(percentageDifference) });
        } else {
          setPriceDifferenceWarning(null);
        }
      }
    },
    [],
  );

  return {
    priceDifferenceWarning,
    priceOverflowError,
    validatePrice,
    setPriceOverflowError,
    setPriceDifferenceWarning,
    clearErrors: () => {
      setPriceOverflowError(false);
      setPriceDifferenceWarning(null);
    },
  };
};
