import { PriceConditionTypeEnum, StopMarketOrderCancellationReasonEnum, Token } from '../../interfaces';

export const getPricePrecisionFromPriceCondition = (
  conditionType: PriceConditionTypeEnum,
  tokenIn: Token,
  tokenOut: Token,
) => {
  const initialDecimals =
    conditionType === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN ? tokenIn.decimals : tokenOut.decimals;

  if (initialDecimals > 10) {
    return 10;
  }

  return initialDecimals;
};

export const mapCancellationReasonEnumToText = (
  cancellationReason: StopMarketOrderCancellationReasonEnum | null,
): string => {
  switch (cancellationReason) {
    case StopMarketOrderCancellationReasonEnum.PRECONDITION_FAILED:
      return 'Preconditions for the order execution were not met.';

    case StopMarketOrderCancellationReasonEnum.INSUFFICIENT_OUTPUT_AMOUNT:
      return 'The output amount was too low to proceed with the order execution.';

    case StopMarketOrderCancellationReasonEnum.INSUFFICIENT_TOKEN_IN_ALLOWANCE:
      return 'The allowance for the input token is insufficient to execute the order.';

    case StopMarketOrderCancellationReasonEnum.INSUFFICIENT_TOKEN_IN_AMOUNT:
      return 'The input token balance is insufficient to execute the order.';

    case StopMarketOrderCancellationReasonEnum.FAILED_TO_FETCH_TOKEN_IN_ALLOWANCE:
      return 'Failed to retrieve the allowance for the input token.';

    case StopMarketOrderCancellationReasonEnum.FAILED_TO_FETCH_TOKEN_IN_BALANCE:
      return 'Failed to fetch the balance of the input token.';

    case StopMarketOrderCancellationReasonEnum.FAILED_TO_ESTIMATE_BASE_EXECUTION_FEE:
      return 'Unable to estimate the base execution fee for this order.';

    case StopMarketOrderCancellationReasonEnum.FAILED_TO_ESTIMATE_ORDER_EXECUTION_TX_FEE:
      return 'Failed to estimate the transaction fee required to execute the order.';

    case StopMarketOrderCancellationReasonEnum.FAILED_TO_ESTIMATE_ORDER_SWAP_INFO:
      return 'Unable to retrieve swap information for the order.';

    case StopMarketOrderCancellationReasonEnum.FAILED_TO_ESTIMATE_ORDER_EXECUTION_GAS_USAGE:
      return 'Failed to estimate the gas required to execute the order.';

    case StopMarketOrderCancellationReasonEnum.FAILED_TO_ESTIMATE_ORDER_FEE_SWAP_INFO:
      return 'Failed to estimate the fee swap information for the order.';

    case StopMarketOrderCancellationReasonEnum.FAILED_TO_EXECUTE_ORDER:
      return 'The order execution failed due to an unexpected issue.';

    case StopMarketOrderCancellationReasonEnum.FAILED_TO_GET_EXECUTION_RESULT_FROM_RECEIPT:
      return 'Unable to retrieve the execution result from the transaction receipt.';

    case StopMarketOrderCancellationReasonEnum.UNHANDLED_ERROR:
      return 'An unexpected error occurred while processing the order.';

    // Contract Errors
    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_VERIFICATION_FAILED:
      return 'Order verification failed. The contract rejected the transaction.';

    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_NONCE_ALREADY_USED:
      return 'The order nonce has already been used and cannot be reused.';

    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_UNAUTHORIZED_EXECUTOR:
      return 'The executor is not authorized to process this order.';

    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_UNAUTHORIZED_OWNER:
      return 'The owner of this order is not authorized to modify or execute it.';

    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_ORDER_EXPIRED:
      return 'The order has expired and can no longer be executed.';

    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_INVALID_AMOUNT_OUT:
      return 'The expected output amount is invalid or does not meet contract requirements.';

    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_SWAP_FAILED:
      return 'The swap operation failed due to an issue in the smart contract.';

    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_AMOUNT_OUT_TOO_LOW:
      return 'The received amount is lower than the minimum required for execution.';

    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_FEE_TRANSFER_FAILED:
      return 'Failed to transfer the required execution fee.';

    case StopMarketOrderCancellationReasonEnum.CONTRACT_ERROR_OPEN_ORDER_NOT_FOUND:
      return 'The open order associated with this operation was not found in the contract.';

    // User-Initiated Cancellations
    case StopMarketOrderCancellationReasonEnum.OPEN_ORDER_WAS_CANCELED:
      return 'The order was manually canceled before execution.';

    case StopMarketOrderCancellationReasonEnum.USER_CANCELED_ORDER:
      return 'The user canceled the order before it could be executed.';

    default:
      return 'Unknown cancellation reason.';
  }
};
