import { ethers } from 'ethers';
import { HelpCircleIcon } from 'lucide-react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  ConditionalModalInfoType,
  ModalName,
  OrderStatusEnum,
  StopMarketOrderCancellationReasonEnum,
} from '../../../interfaces';
import { closeModal } from '../../../store';
import { cn, mapCancellationReasonEnumToText } from '../../../utils';
import { getNetworkByChainId, getTokenByTokenId } from '../../../utils/blockchain/token-list';
import { CopyToClipboardButton } from '../../custom/copy-to-clipboard-button';
import { NetworkLabel } from '../../custom/network-label';
import { TokenLabel } from '../../custom/token-label';
import { Button } from '../../ui/button';
import { Dialog, DialogContent, DialogTitle } from '../../ui/dialog';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';
import { Separator } from '../../ui/separator';
import { TypographyH4, TypographyP, TypographyS } from '../../ui/typography';
import { InputAmountOrderLabel } from './columns/amount-column';
import { OrderDateLabel } from './columns/components/order-date-label';
import { OrderPriceLabel } from './columns/components/order-price-label';
import { OrderTargetLabelMobile } from './columns/target-price-column';

export const StopMarketOrderInfoModal = () => {
  const dispatch = useAppDispatch();
  const activeModalInfo = useAppSelector((state) => state.modal);
  const { order } = activeModalInfo.modalInfo! as ConditionalModalInfoType<ModalName.STOP_MARKET_ORDER_INFO>;

  const onClose = () => {
    dispatch(closeModal());
  };

  const network = getNetworkByChainId(order.openOrder.chainId)!;
  const tokenIn = getTokenByTokenId(order.openOrder.tokenInId)!;
  const tokenOut = getTokenByTokenId(order.openOrder.tokenOutId)!;

  const openOrderInfo = {
    tokenIn,
    tokenOut,
    amountIn: order.openOrder.amountIn,
    minAmountOut: order.openOrder.minAmountOut,
    priceCondition: order.openPriceCondition,
    amountOut: order.openOrder.amountOut || order.openOrder.minAmountOut,
    txHash: order.openOrder.txHash,
    chainId: order.openOrder.chainId,
    createdAt: order.openOrder.createdAt,
    expiresAt: order.openOrder.expirationTime,
    gasFeeInTokenIn: order.openOrder.gasFeeInTokenIn,
    executorFeeInTokenIn: order.openOrder.executorFeeInTokenIn,
    totalFeesInTokenIn:
      order.openOrder.gasFeeInTokenIn && order.openOrder.executorFeeInTokenIn
        ? (
            ethers.toBigInt(order.openOrder.executorFeeInTokenIn) + ethers.toBigInt(order.openOrder.gasFeeInTokenIn)
          ).toString()
        : null,
    cancellationReason: order.openOrder.cancellationReason,
  };

  const closeOrderInfo = order.closeOrder
    ? {
        tokenIn: tokenOut,
        tokenOut: getTokenByTokenId(order.closeOrder.tokenOutId)!,
        amountIn: order.openOrder.amountOut || order.openOrder.minAmountOut,
        priceCondition: order.closePriceCondition,
        amountOut: order.closeOrder.amountOut,
        minAmountOut: order.closeOrder.minAmountOut,
        txHash: order.closeOrder.txHash,
        chainId: order.openOrder.chainId,
        status: order.closeOrder.status,
        createdAt: order.closeOrder.createdAt,
        expiresAt: order.openOrder.expirationTime,
        gasFeeInTokenIn: order.closeOrder.gasFeeInTokenIn,
        executorFeeInTokenIn: order.closeOrder.executorFeeInTokenIn,
        cancellationReason: order.closeOrder.cancellationReason,
        totalFeesInTokenIn:
          order.closeOrder.gasFeeInTokenIn && order.closeOrder.executorFeeInTokenIn
            ? (
                ethers.toBigInt(order.closeOrder.executorFeeInTokenIn) +
                ethers.toBigInt(order.closeOrder.gasFeeInTokenIn)
              ).toString()
            : null,
      }
    : null;

  const renderOrderStatus = (
    status: OrderStatusEnum,
    cancellationReason: StopMarketOrderCancellationReasonEnum | null,
  ) => {
    if (status === OrderStatusEnum.CANCELED) {
      return (
        <div className="flex items-center gap-2">
          <Popover>
            <PopoverTrigger className="flex gap-1  text-xs items-center">
              <TypographyP className="font-medium ">Order Status</TypographyP>

              <TypographyS className={cn('capitalize text-[11px]  text-red-500', {})}>{status}</TypographyS>

              <HelpCircleIcon className="w-3 h-3 dark:text-white/60 text-black/60" />
            </PopoverTrigger>
            <PopoverContent
              align="center"
              side="right"
              className="bg-white/30 z-50 text-xs flex flex-col gap-1 shadow dark:bg-primary-black/30 max-w-[280px] w-fit  backdrop-blur-lg p-2"
            >
              <TypographyS>{mapCancellationReasonEnumToText(cancellationReason)}</TypographyS>
            </PopoverContent>
          </Popover>
        </div>
      );
    }

    return (
      <div className="flex items-center gap-2">
        <TypographyP className="font-medium text-xs">Order Status</TypographyP>
        <TypographyS
          className={cn('capitalize text-red-500 text-[11px]', {
            'text-green-500': status === OrderStatusEnum.CREATED || status === OrderStatusEnum.EXECUTED,
            'text-yellow-500': status === OrderStatusEnum.QUEUED,
          })}
        >
          {status}
        </TypographyS>
      </div>
    );
  };

  const renderTxHash = (txHash: string | null, isClose: boolean) =>
    txHash ? (
      <div className="flex items-center font-medium text-xs mt-2">
        <TypographyP className="font-medium mr-2">Tx hash</TypographyP>
        <CopyToClipboardButton
          labelClassName={cn('text-xs', {
            'dark:text-amber-400': !isClose,
            'dark:text-purple-400': isClose,
          })}
          label={`${txHash.slice(0, 6)}...${txHash.slice(-6)}`}
          textToCopy={txHash}
          link={openOrderInfo.chainId === 42161 ? `https://arbiscan.io/tx/${txHash}` : ''}
        />
      </div>
    ) : null;

  return (
    <Dialog
      open={activeModalInfo.activeModal === ModalName.STOP_MARKET_ORDER_INFO}
      modal
      onOpenChange={() => dispatch(closeModal())}
    >
      <DialogTitle>order info</DialogTitle>
      <DialogContent className="md:w-auto md:min-w-[300px] md:h-auto w-[100vw] xs:h-fit h-[100vh]">
        <div className="space-y-2 w-full h-full">
          <TypographyH4 className="font-bold mb-3 items-center flex  overflow-auto">
            Order Info{' '}
            <TypographyS className="ml-2 pt-0.5 text-sm font-medium dark:text-white/60">
              #{order.openOrder.id}
            </TypographyS>
          </TypographyH4>

          <NetworkLabel network={network} />

          <div className="flex flex-col space-y-1">
            <TypographyP className="dark:text-yellow-400 font-medium text-sm">Open Position</TypographyP>

            <div className="flex items-center gap-2">
              <TypographyS className="font-medium text-xs">Order</TypographyS>
              <div className="flex gap-1 justify-center">
                <TypographyP className="dark:text-amber-400 font-medium text-[11px] leading-4">Buy</TypographyP>
                {openOrderInfo.tokenOut && (
                  <div className="flex gap-1 items-center justify-start">
                    <TokenLabel token={openOrderInfo.tokenOut} textClassName="text-[10px]" imgClassName="w-3 h-3" />
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center gap-2">
              <TypographyS className="font-medium text-xs">Target price</TypographyS>
              <OrderTargetLabelMobile
                condition={openOrderInfo.priceCondition}
                orderTokenIn={openOrderInfo.tokenIn}
                orderTokenOut={openOrderInfo.tokenOut}
                isClose={false}
                showIcon
              />
            </div>

            <div className="flex items-center gap-2">
              <TypographyS className="font-medium text-xs">Input amount</TypographyS>
              <InputAmountOrderLabel amount={openOrderInfo.amountIn} tokenIn={openOrderInfo.tokenIn} isClose={false} />
            </div>

            <div className="flex items-center gap-2">
              <TypographyS className="font-medium text-xs">Output amount</TypographyS>
              <InputAmountOrderLabel
                amount={openOrderInfo.amountOut || openOrderInfo.minAmountOut}
                tokenIn={openOrderInfo.tokenOut!}
                isClose={false}
              />
            </div>

            {openOrderInfo.totalFeesInTokenIn ? (
              <div className="flex items-center gap-2">
                <TypographyS className="font-medium text-xs">Total Fees</TypographyS>
                <Popover>
                  <PopoverTrigger className="flex gap-1 items-center">
                    <InputAmountOrderLabel
                      amount={openOrderInfo.totalFeesInTokenIn}
                      tokenIn={openOrderInfo.tokenIn}
                      isClose={false}
                    />

                    <HelpCircleIcon className="w-3 h-3 dark:text-white/60 text-black/60" />
                  </PopoverTrigger>
                  <PopoverContent
                    align="center"
                    side="right"
                    className="bg-white/30 z-50 flex flex-col gap-1 shadow dark:bg-primary-black/30 max-w-[280px] w-fit  backdrop-blur-lg p-2"
                  >
                    <div className="flex items-center gap-1 dark:text-white/80 text-[10px]">
                      <TypographyS className="text-[10px] dark:text-white">Gas fee</TypographyS>

                      <OrderPriceLabel
                        amount={ethers.formatUnits(openOrderInfo.gasFeeInTokenIn!, openOrderInfo.tokenIn.decimals)}
                        token={openOrderInfo.tokenIn}
                        isCloseOrder={false}
                        hideTokenSymbol={true}
                      />
                    </div>
                    <div className="flex items-center gap-1 dark:text-white/80 text-[10px]">
                      <TypographyS className="text-[10px] dark:text-white">Executor fee</TypographyS>
                      <OrderPriceLabel
                        amount={ethers.formatUnits(openOrderInfo.executorFeeInTokenIn!, openOrderInfo.tokenIn.decimals)}
                        token={openOrderInfo.tokenIn}
                        isCloseOrder={false}
                        hideTokenSymbol={true}
                      />
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            ) : null}

            {renderOrderStatus(order.openOrder.status, order.openOrder.cancellationReason)}

            <div className="flex items-center gap-2">
              <TypographyP className="font-medium text-xs">Created</TypographyP>
              <OrderDateLabel date={openOrderInfo.createdAt} />
            </div>

            <div className="flex items-center gap-2">
              <TypographyP className="font-medium text-xs">Expires</TypographyP>
              <OrderDateLabel date={openOrderInfo.expiresAt} />
            </div>

            {renderTxHash(openOrderInfo.txHash, false)}
          </div>

          {closeOrderInfo ? (
            <div className="flex flex-col space-y-1">
              <Separator className="my-4" />

              <TypographyP className="dark:text-purple-400 font-medium text-sm">Close Position</TypographyP>

              <div className="flex items-center gap-2">
                <TypographyS className="font-medium text-xs">Order</TypographyS>
                <div className="flex gap-1 justify-center">
                  <TypographyP className="dark:text-purple-400 font-medium text-[11px] leading-4">Sell</TypographyP>
                  {closeOrderInfo.tokenIn && (
                    <div className="flex gap-1 items-center justify-start">
                      <TokenLabel token={closeOrderInfo.tokenIn} textClassName="text-[10px]" imgClassName="w-3 h-3" />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-2">
                <TypographyS className="font-medium text-xs">Target price</TypographyS>
                <OrderTargetLabelMobile
                  condition={closeOrderInfo.priceCondition}
                  orderTokenIn={closeOrderInfo.tokenIn}
                  orderTokenOut={closeOrderInfo.tokenOut}
                  isClose={true}
                />
              </div>

              <div className="flex items-center gap-2">
                <TypographyS className="font-medium text-xs">Input amount</TypographyS>
                <InputAmountOrderLabel
                  amount={closeOrderInfo.amountIn}
                  tokenIn={closeOrderInfo.tokenIn}
                  isClose={true}
                />
              </div>

              <div className="flex items-center gap-2">
                <TypographyS className="font-medium text-xs">Output amount</TypographyS>
                <InputAmountOrderLabel
                  amount={closeOrderInfo.amountOut || closeOrderInfo.minAmountOut}
                  tokenIn={closeOrderInfo.tokenOut!}
                  isClose={true}
                />
              </div>

              {closeOrderInfo.totalFeesInTokenIn ? (
                <div className="flex items-center gap-2">
                  <TypographyS className="font-medium text-xs">Total Fees</TypographyS>
                  <Popover>
                    <PopoverTrigger className="flex gap-1 items-center">
                      <InputAmountOrderLabel
                        amount={closeOrderInfo.totalFeesInTokenIn}
                        tokenIn={closeOrderInfo.tokenIn}
                        isClose={true}
                      />

                      <HelpCircleIcon className="w-3 h-3 dark:text-white/60 text-black/60" />
                    </PopoverTrigger>
                    <PopoverContent
                      align="center"
                      side="right"
                      className="bg-white/30 z-50 flex flex-col gap-1 shadow dark:bg-primary-black/30 max-w-[280px] w-fit  backdrop-blur-lg p-2"
                    >
                      <div className="flex items-center gap-1 dark:text-white/80 text-[10px]">
                        <TypographyS className="text-[10px] dark:text-white">Gas fee</TypographyS>

                        <OrderPriceLabel
                          amount={ethers.formatUnits(closeOrderInfo.gasFeeInTokenIn!, closeOrderInfo.tokenIn.decimals)}
                          token={closeOrderInfo.tokenIn}
                          isCloseOrder={true}
                          hideTokenSymbol={true}
                        />
                      </div>
                      <div className="flex items-center gap-1 dark:text-white/80 text-[10px]">
                        <TypographyS className="text-[10px] dark:text-white">Executor fee</TypographyS>
                        <OrderPriceLabel
                          amount={ethers.formatUnits(
                            closeOrderInfo.executorFeeInTokenIn!,
                            closeOrderInfo.tokenIn.decimals,
                          )}
                          token={closeOrderInfo.tokenIn}
                          isCloseOrder={true}
                          hideTokenSymbol={true}
                        />
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              ) : null}

              {renderOrderStatus(order.closeOrder.status, order.closeOrder.cancellationReason)}

              <div className="flex items-center font-medium text-xs">
                <TypographyP className="font-medium mr-2">Created</TypographyP>
                <OrderDateLabel date={closeOrderInfo.createdAt} />
              </div>

              <div className="flex items-center font-medium text-xs">
                <TypographyP className="font-medium mr-2">Expires</TypographyP>
                <OrderDateLabel date={closeOrderInfo.expiresAt} />
              </div>

              {renderTxHash(closeOrderInfo.txHash, true)}
            </div>
          ) : null}
        </div>
        <div className="mt-auto w-full">
          <Button variant="outline" className=" py-2 px-4 mt-4 w-full rounded-lg text-sm font-medium" onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
