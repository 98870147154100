import { FC } from 'react';
import { OrderStatusEnum, StopMarketOrderInfo } from '../../../../interfaces';
import { cn } from '../../../../utils';
import { TypographyP } from '../../../ui/typography';

type OrderStatusColumnProps = {
  order: StopMarketOrderInfo;
};

export const OrderStatusColumn: FC<OrderStatusColumnProps> = ({ order }) => {
  return (
    <div className="flex space-y-1.5 flex-col min-w-max">
      <TypographyP
        className={cn('text-center capitalize text-[11px]', {
          'text-green-500': order.openOrder.status === OrderStatusEnum.EXECUTED,
          'text-yellow-500': order.openOrder.status === OrderStatusEnum.QUEUED,
          'text-red-500': order.openOrder.status === OrderStatusEnum.CANCELED,
        })}
      >
        {order.openOrder.status}
      </TypographyP>
      {order.closeOrder && (
        <TypographyP
          className={cn('text-center capitalize text-[11px]', {
            'text-green-500': order.closeOrder.status === OrderStatusEnum.EXECUTED,
            'text-yellow-500': order.closeOrder.status === OrderStatusEnum.QUEUED,
            'text-red-500': order.closeOrder.status === OrderStatusEnum.CANCELED,
          })}
        >
          {order.closeOrder.status}
        </TypographyP>
      )}
    </div>
  );
};
