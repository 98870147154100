import { FC } from 'react';
import { StopMarketOrderInfo } from '../../../../interfaces';
import { getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { InputAmountOrderLabel } from './amount-column';

type AmountOutColumnProps = {
  order: StopMarketOrderInfo;
};

export const AmountOutColumn: FC<AmountOutColumnProps> = ({ order }) => {
  const openOrderTokenOut = getTokenByTokenId(order.openOrder.tokenOutId);
  const closeOrderTokenOut = order.closeOrder ? getTokenByTokenId(order.closeOrder.tokenOutId) : null;

  return (
    <div className="flex space-y-1.5 flex-col min-w-max">
      <InputAmountOrderLabel
        amount={order.openOrder.amountOut || order.openOrder.minAmountOut}
        isClose={false}
        tokenIn={openOrderTokenOut!}
      />

      {order.closeOrder && (
        <InputAmountOrderLabel
          amount={order.closeOrder?.amountOut || order.closeOrder?.minAmountOut}
          isClose={true}
          tokenIn={closeOrderTokenOut!}
        />
      )}
    </div>
  );
};
