import { PriceConditionTypeEnum } from './limit-order-interface';

export enum OrderStatusEnum {
  CREATED = 'created',
  CANCELED = 'canceled',
  EXECUTED = 'executed',
  QUEUED = 'queued',
  EXPIRED = 'expired',
}

export enum SortOptionEnum {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface StopMarketOrderInfo {
  closeOrder: CloseOrder;
  openOrder: OpenOrder;
  openPriceCondition: PriceConditionInfo;
  closePriceCondition: PriceConditionInfo;
}

export enum StopMarketOrderCancellationReasonEnum {
  PRECONDITION_FAILED = 'preconditionFailed',
  INSUFFICIENT_OUTPUT_AMOUNT = 'insufficientOutputAmount',
  INSUFFICIENT_TOKEN_IN_ALLOWANCE = 'insufficientTokenInAllowance',
  INSUFFICIENT_TOKEN_IN_AMOUNT = 'insufficientTokenInAmount',
  FAILED_TO_FETCH_TOKEN_IN_ALLOWANCE = 'failedToFetchTokenInAllowance',
  FAILED_TO_FETCH_TOKEN_IN_BALANCE = 'failedToFetchTokenInBalance',
  FAILED_TO_ESTIMATE_BASE_EXECUTION_FEE = 'failedToEstimateBaseExecutionFee',
  FAILED_TO_ESTIMATE_ORDER_EXECUTION_TX_FEE = 'failedToEstimateOrderExecutionTxFee',
  FAILED_TO_ESTIMATE_ORDER_SWAP_INFO = 'failedToEstimateOrderSwapInfo',
  FAILED_TO_ESTIMATE_ORDER_EXECUTION_GAS_USAGE = 'failedToEstimateOrderExecutionGasUsage',
  FAILED_TO_ESTIMATE_ORDER_FEE_SWAP_INFO = 'failedToEstimateOrderFeeSwapInfo',
  FAILED_TO_EXECUTE_ORDER = 'failedToExecuteOrder',
  FAILED_TO_GET_EXECUTION_RESULT_FROM_RECEIPT = 'failedToGetExecutionResultFromReceipt',
  UNHANDLED_ERROR = 'unhandledError',
  CONTRACT_ERROR_VERIFICATION_FAILED = 'contractErrorVerificationFailed',
  CONTRACT_ERROR_NONCE_ALREADY_USED = 'contractErrorNonceAlreadyUsed',
  CONTRACT_ERROR_UNAUTHORIZED_EXECUTOR = 'contractErrorUnauthorizedExecutor',
  CONTRACT_ERROR_UNAUTHORIZED_OWNER = 'contractErrorUnauthorizedOwner',
  CONTRACT_ERROR_ORDER_EXPIRED = 'contractErrorOrderExpired',
  CONTRACT_ERROR_INVALID_AMOUNT_OUT = 'contractErrorInvalidAmountOut',
  CONTRACT_ERROR_SWAP_FAILED = 'contractErrorSwapFailed',
  CONTRACT_ERROR_AMOUNT_OUT_TOO_LOW = 'contractErrorAmountOutTooLow',
  CONTRACT_ERROR_FEE_TRANSFER_FAILED = 'contractErrorFeeTransferFailed',
  CONTRACT_ERROR_OPEN_ORDER_NOT_FOUND = 'contractErrorOpenOrderNotFound',
  OPEN_ORDER_WAS_CANCELED = 'openOrderWasCanceled',
  USER_CANCELED_ORDER = 'userCanceledOrder',
}

export interface OpenOrder {
  id: string;
  nonce: string;
  userAddress: string;
  amountIn: string;
  minAmountOut: string;
  maxAmountOut: string;
  amountOut: string | null;
  tokenInId: string;
  tokenOutId: string;
  status: OrderStatusEnum;
  expirationTime: string;
  chainId: number;
  signatureR: string;
  signatureS: string;
  signatureV: number;
  executorFee: string | null;
  gasFee: string | null;
  executorFeeInTokenIn: string | null;
  gasFeeInTokenIn: string | null;
  cancellationReason: StopMarketOrderCancellationReasonEnum | null;
  txHash: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface CloseOrder {
  id: string;
  nonce: string;
  minAmountOut: string;
  maxAmountOut: string;
  amountOut: string | null;
  tokenOutId: string;
  status: OrderStatusEnum;
  signatureR: string;
  signatureS: string;
  signatureV: number;
  executorFee: string | null;
  gasFee: string | null;
  executorFeeInTokenIn: string | null;
  gasFeeInTokenIn: string | null;
  cancellationReason: StopMarketOrderCancellationReasonEnum | null;
  txHash: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface PriceConditionInfo {
  id: number;
  value: string;
  conditionType: PriceConditionTypeEnum;
  createdAt: string;
  initialValue: string;
}

export type UserStopMarketOrdersResponse = {
  results: StopMarketOrderInfo[];
  page: number;
  size: number;
};

export type UserStopMarketOrdersTokensAllowance = {
  totalAmountTokenIn: string;
  totalCloseAmountTokenIn: string;
};

export enum OrderDurationEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  THREE_MONTHS = 'threeMonths',
}

export enum StopMarketOrderType {
  CLOSE = 'close',
  OPEN = 'open',
}

export enum OrderGasUsageEnum {
  STANDARD = 'standard',
  LOW = 'low',
}

export type StopMarketOrderQuoteEstimationType = {
  amountIn: string;
  platformFee: string;
  floatingGasFee: string;
  estimatedTxFee: string;
  minAmountOut: string;
  maxAmountOut: string;
  route: {
    amountIn: string;
    path: string[];
    pools: string[];
  }[];
};
