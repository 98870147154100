import { Route, Routes } from 'react-router';
import { StopMarketOrderPage } from '../pages/stop-market-order/stop-market-order-page';
import { UserAgreementPage } from '../pages/user-agreement/user-agreement';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<StopMarketOrderPage />} />
      <Route path="/terms" element={<UserAgreementPage />} />
    </Routes>
  );
};
