import { ethers } from 'ethers';
import { CircleHelpIcon } from 'lucide-react';
import { FC } from 'react';
import { Token } from '../../../../interfaces';
import { formatAmount } from '../../../../utils';
import { Button } from '../../../ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../ui/tooltip';
import { TypographyP, TypographyS } from '../../../ui/typography';

type StopMarketOrderInsufficientErrorProps = {
  token: Token;
  amount: string;
};

export const StopMarketOrderInsufficientError: FC<StopMarketOrderInsufficientErrorProps> = ({ token, amount }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild className=" mx-auto flex items-center justify-center">
          <Button className="bg-red-600/70 hover:bg-red-600/80 w-full capitalize text-white py-5 px-4 rounded-lg text-sm font-medium">
            Insufficient balance of {token.symbol}
            <CircleHelpIcon />
          </Button>
        </TooltipTrigger>
        <TooltipContent
          align="center"
          side="top"
          className="bg-white/30 flex items-center gap-1 shadow dark:bg-primary-black/30 max-w-[250px] backdrop-blur-lg p-2"
        >
          <TypographyP className="dark:text-white text-[12px]  text-black first-letter:capitalize">
            You need to have at least{' '}
            <TypographyS className="text-[12px]  font-semibold">
              {formatAmount({
                value: +ethers.formatUnits(amount, token.decimals),
                withoutFormatForSmallValue: true,
              })}{' '}
              {token.symbol}
            </TypographyS>{' '}
            in your wallet to create a new order.
          </TypographyP>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
