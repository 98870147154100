import { Copy, CopyCheck, ExternalLinkIcon } from 'lucide-react';
import { FC, useState } from 'react';
import { cn } from '../../utils';
import { Button } from '../ui/button';
import { TypographyS } from '../ui/typography';

type CopyToClipboardButtonProps = {
  label: string;
  textToCopy: string;
  labelClassName?: string;
  className?: string;
  link?: string;
};

export const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = ({
  label,
  labelClassName,
  link,
  textToCopy,
  className,
}) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);

      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div className={cn('flex gap-1 items-center', className)}>
      <TypographyS className={cn(labelClassName)}>{label}</TypographyS>
      <Button variant="ghost" onClick={copyToClipboard} className="hover:bg-transparent p-0 w-fit h-fit">
        {!copied ? (
          <Copy className=" max-w-3.5 max-h-3.5" />
        ) : (
          <CopyCheck className="text-green-600 max-w-3.5 max-h-3.5" />
        )}
      </Button>
      {link ? (
        <a href={link} rel="noreferrer" target="_blank">
          <ExternalLinkIcon className="hover:text-green-600 max-w-3.5 max-h-3.5" />
        </a>
      ) : null}
    </div>
  );
};
