import { FC } from 'react';
import { Tabs, TabsList, TabsTrigger } from '../../../ui/tabs';

const ORDER_PRICE_PERCENTAGE_OPTIONS = [
  {
    value: 1,
    label: '+1%',
  },
  {
    value: 3,
    label: '+3%',
  },
  {
    value: 5,
    label: '+5%',
  },
];

type StopMarketOrderPricePercentageSelectorProps = {
  initialPrice: string;
  orderPricePercentage: number;
  negative?: boolean;
  setOrderPricePercentage: (pricePercentage: number) => void;
};

export const StopMarketOrderPricePercentageSelector: FC<StopMarketOrderPricePercentageSelectorProps> = ({
  setOrderPricePercentage,
  initialPrice,
  negative,
  orderPricePercentage,
}) => {
  return (
    <div>
      <Tabs
        value={orderPricePercentage.toString()}
        onValueChange={(option) => setOrderPricePercentage(+option)}
        defaultValue={orderPricePercentage.toString()}
        className="w-full"
      >
        <div className="flex items-center justify-between space-x-1 xl:space-x-2">
          <TabsList className="w-fit flex items-center bg-transparent !p-0 h-8">
            {!ORDER_PRICE_PERCENTAGE_OPTIONS.find((o) => o.value === orderPricePercentage) && orderPricePercentage ? (
              <TabsTrigger
                disabled={!initialPrice}
                className="dark:data-[state=active]:bg-primary-light-gray xl:px-2 px-1 lg:px-1.5 data-[state=active]:text-black dark:data-[state=active]:text-white dark:text-white/70 text-black/70 text-xs"
                value={orderPricePercentage.toString()}
              >
                {orderPricePercentage < 0 ? '' : negative ? '-' : '+'}
                {orderPricePercentage.toFixed(2)}%
              </TabsTrigger>
            ) : null}

            {ORDER_PRICE_PERCENTAGE_OPTIONS.map((o) => (
              <TabsTrigger
                disabled={!initialPrice}
                key={o.value}
                className="dark:data-[state=active]:bg-primary-light-gray xl:px-2 px-1 lg:px-1.5 data-[state=active]:text-black dark:data-[state=active]:text-white dark:text-white/70 text-black/70 text-xs"
                value={o.value.toString()}
              >
                {negative ? o.label.replace('+', '-') : o.label}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
      </Tabs>
    </div>
  );
};
