import { FC } from 'react';
import { LimitOpenOrderType } from '../../../../interfaces';
import { Button } from '../../../ui/button';

type StopMarketOrderPreviewModalCloseOrderActionsProps = {
  closeOrder: LimitOpenOrderType['order'];
  isCloseOrderTokenTotalAmountApproved: boolean;
  areStopMarketOrdersTokensAllowanceLoading: boolean;
  isCloseOrderTokenApproving: boolean;
  isStopMarketOrderQuoteEstimationLoading: boolean;
  isCloseOrderSigning: boolean;
  approveCloseOrderTokenHandler: () => Promise<void>;
  signCloseOrderHandler: () => Promise<void>;
};

export const StopMarketOrderPreviewModalCloseOrderActions: FC<StopMarketOrderPreviewModalCloseOrderActionsProps> = ({
  closeOrder,
  isCloseOrderTokenApproving,
  isCloseOrderTokenTotalAmountApproved,
  isCloseOrderSigning,
  areStopMarketOrdersTokensAllowanceLoading,
  isStopMarketOrderQuoteEstimationLoading,
  approveCloseOrderTokenHandler,
  signCloseOrderHandler,
}) => {
  if (!isCloseOrderTokenTotalAmountApproved) {
    return (
      <Button
        className="bg-blue-500 hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
        onClick={approveCloseOrderTokenHandler}
        disabled={areStopMarketOrdersTokensAllowanceLoading || isCloseOrderTokenApproving}
        loading={isCloseOrderTokenApproving || areStopMarketOrdersTokensAllowanceLoading}
      >
        Allow to use {closeOrder.tokenIn.symbol}
      </Button>
    );
  }

  if (isStopMarketOrderQuoteEstimationLoading) {
    return (
      <Button
        className="bg-blue-500 hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
        disabled={isStopMarketOrderQuoteEstimationLoading}
        loading={isStopMarketOrderQuoteEstimationLoading}
      >
        Estimating order quote
      </Button>
    );
  }

  return (
    <Button
      className="bg-blue-500 hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
      disabled={isCloseOrderSigning}
      loading={isCloseOrderSigning}
      onClick={signCloseOrderHandler}
    >
      Sign Close order
    </Button>
  );

  return null;
};
