import { FC } from 'react';
import { cn } from '../../utils';
import { TypographyS } from '../ui/typography';

type USDLabelProps = {
  textClassName?: string;
  imgClassName?: string;
};

export const USDLabel: FC<USDLabelProps> = ({ textClassName, imgClassName }) => {
  return (
    <div className="flex gap-1 items-center">
      <div className="relative">
        <img
          src="https://cdn-icons-png.flaticon.com/256/262/262280.png"
          alt="USD"
          className={cn('w-3.5 h-3.5 z-10 relative', imgClassName)}
        />
      </div>
      <TypographyS className={cn('text-xs', textClassName)}>USD</TypographyS>
    </div>
  );
};
