import { useAppSelector } from '../../hooks';
import { ModalName } from '../../interfaces';
import { MobileTokenPairSelector } from '../custom/mobile-token-pair-selector';
import { StopMarketOrderInfoModal } from '../order/list/stop-market-order-info-modal';

export const ModalRegistry = () => {
  const activeModal = useAppSelector((state) => state.modal.activeModal);

  return activeModal === ModalName.STOP_MARKET_ORDER_INFO ? (
    <StopMarketOrderInfoModal />
  ) : activeModal === ModalName.STOP_MARKET_ORDER_TOKEN_PAIR_SELECTOR_MOBILE ? (
    <MobileTokenPairSelector />
  ) : null;
};
