import React, { FC } from 'react';
import { Header } from './header';

type BaseLayoutProps = {
  children: React.ReactNode;
};

export const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-primary-black">
      <Header />

      <main className="md:pt-[64px] pt-[52px] pb-2">{children}</main>
    </div>
  );
};
