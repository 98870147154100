import { FC } from 'react';
import { Token } from '../../interfaces';
import { cn } from '../../utils';
import { TypographyS } from '../ui/typography';
import { TokenLogo } from './token-logo';

type TokenLabelProps = {
  token: Token;
  textClassName?: string;
  imgClassName?: string;
  showLogo?: boolean;
  wrapperClassName?: string;
};

export const TokenLabel: FC<TokenLabelProps> = ({
  token,
  wrapperClassName,
  showLogo = true,
  textClassName,
  imgClassName,
}) => {
  return (
    <div
      className={cn(
        'flex items-center',
        {
          'gap-1': showLogo,
        },
        wrapperClassName,
      )}
    >
      {showLogo ? <TokenLogo token={token} imgClassName={imgClassName} /> : null}
      <TypographyS className={cn('text-xs', textClassName)}>{token.symbol}</TypographyS>
    </div>
  );
};
