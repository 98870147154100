import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { APIConfig } from '../../configs';
import { PriceConditionFormType } from '../../interfaces';
import { axiosClientWithCredentials } from '../../utils';
import { toast } from '../ui/use-toast';

type OrderInfo = {
  nonce: string;
  minAmountOut: string;
  maxAmountOut: string;
  amountIn: string;
  tokenIn: string;
  tokenOut: string;
  priceCondition: PriceConditionFormType;
  signatureR: string;
  signatureS: string;
  signatureV: number;
};

export type CreateStopMarketOrderParams = {
  chainId: number;
  orderId: string;
  openOrder: OrderInfo;
  closeOrder: OrderInfo | null;
  expirationTime: Date;
};

const createStopMarketOrder = async (params: CreateStopMarketOrderParams) => {
  return await axiosClientWithCredentials.post<{ orderId: number }>(`${APIConfig.baseURL}/ds/orders`, params);
};

export const useCreateStopMarketOrder = () => {
  const client = useQueryClient();

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: createStopMarketOrder,
    onSuccess: () => {
      toast({
        title: 'Order created',
        description: 'Stop market order was created!',
      });

      client.invalidateQueries({
        queryKey: ['userOrders'],
      });
    },
    onError(err) {
      console.error(err);
      let message = `Unexpected error occurred: ${err.message}`;

      if (isAxiosError(err)) {
        message = err.response?.data?.error || message;
      }

      toast({
        title: 'Order creation error',
        description: message,
      });
    },
  });

  return {
    createStopMarketOrderError: error,
    isCreateStopMarketOrderPending: isPending,
    createStopMarketOrder: mutateAsync,
  };
};
