// api.ts
import axios from 'axios';
import { DisconnectMutate } from 'wagmi/query';
import { APIConfig } from '../../configs';
import { AppDispatch } from '../../store';
import { signOutHandler } from '../auth/sign-out-handler';
import { localStorageManager } from '../global/local-storage-manager';

export const axiosClientWithCredentials = axios.create({
  baseURL: APIConfig.baseURL,
  withCredentials: true,
});

export const setupAxiosClientWithCredentialsInterceptors = (
  dispatch: AppDispatch,
  disconnect: DisconnectMutate<unknown>,
  address: string,
) => {
  axiosClientWithCredentials.interceptors.response.use(
    (response) => {
      localStorageManager.set(
        `auth-${address.toLowerCase()}`,
        true,
        5 * 60 * 60 * 1000, // 5 hr expiration
      );

      return response;
    },
    (error) => {
      if (error.status === 401) {
        signOutHandler(dispatch, disconnect);
      }
      return Promise.reject(error);
    },
  );
};
