import { Check, ChevronDown } from 'lucide-react';
import { FC, useState } from 'react';
import { Token } from '../../../interfaces';
import { cn } from '../../../utils';
import { getNetworks } from '../../../utils/blockchain/token-list';
import { TokenLogo } from '../../custom/token-logo';
import { Button } from '../../ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '../../ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';
import { TypographyS } from '../../ui/typography';

type TokenSelectorProps = {
  tokenList: Token[];
  selectedToken?: Token;
  handleTokenChange?: (token: Token) => void;
  disabled?: boolean;
  className?: string;
  label?: string;
  showNetworkLabel?: boolean;
  contentClassName?: string;
};

export const TokenSelector: FC<TokenSelectorProps> = ({
  tokenList,
  className,
  disabled,
  handleTokenChange,
  selectedToken,
  label,
  showNetworkLabel,
  contentClassName,
}) => {
  const [filteredTokenList, setFilteredTokenList] = useState<Token[]>(tokenList);
  const [tokenSearch, setTokenSearch] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(`${selectedToken?.address}-${selectedToken?.symbol}`);

  const networks = getNetworks();
  const network = networks.find((n) => n.chainId === selectedToken?.chainId);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          disabled={disabled}
          aria-expanded={open}
          className={cn(
            ' min-w-[100px]  hover:bg-transparent relative justify-end bg-transparent cursor-pointer text-xs p-0 ',
            className,
          )}
          contentClassName={contentClassName}
        >
          {selectedToken ? (
            <div className="flex gap-1 items-center ">
              <TokenLogo network={network} showNetworkLabel={showNetworkLabel} token={selectedToken} />
              <TypographyS className="text-xs">{selectedToken.symbol}</TypographyS>
            </div>
          ) : (
            label || 'Select token'
          )}
          {<ChevronDown className="opacity-50" />}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-[140px] p-0 ">
        <Command>
          <CommandInput
            value={tokenSearch}
            onValueChange={(val) => {
              setValue(val);
              setTokenSearch(val);
              setFilteredTokenList(
                val
                  ? tokenList.filter(
                      (t) =>
                        t.address.includes(val.toLowerCase()) || t.symbol.toLowerCase().includes(val.toLowerCase()),
                    )
                  : tokenList,
              );
            }}
            placeholder="Search token"
            className="placeholder:text-xs"
          />
          <CommandList>
            <CommandEmpty className="lg:text-sm text-xs text-center p-2">No token list found.</CommandEmpty>
            <CommandGroup onWheel={(e) => e.stopPropagation()}>
              {filteredTokenList.map((token) => {
                const network = networks.find((n) => n.chainId === token.chainId);

                return (
                  <CommandItem
                    key={`${token.address}-${token.symbol}`}
                    value={`${token.address}-${token.symbol}`}
                    onSelect={(currentValue) => {
                      if (value !== currentValue) {
                        handleTokenChange && handleTokenChange(token);
                        setValue(currentValue);
                      }

                      setTokenSearch('');
                      setOpen(false);
                      setFilteredTokenList(tokenList);
                    }}
                  >
                    <TokenLogo network={network} showNetworkLabel={showNetworkLabel} token={token} />
                    <TypographyS className="text-xs">{token.symbol}</TypographyS>

                    <Check
                      className={cn(
                        'ml-auto',
                        value.split('-')[0] === token.address && token.address === selectedToken?.address
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
