import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { APIConfig } from '../../configs';
import { OHLCIntervalEnum, OHLCTOkenPairResponse } from '../../interfaces';
import { formatOHLCToUnits } from '../../utils';

type FetchOHLCArgs = {
  chainId: number;
  tokenInAddress: string;
  tokenOutAddress: string;
  interval: OHLCIntervalEnum;
  enabled?: boolean;
  from?: number;
  to?: number;
  limit: number;
};

const fetchOHLC = async ({
  chainId,
  tokenInAddress,
  tokenOutAddress,
  interval,
  from,
  to,
  limit,
}: FetchOHLCArgs): Promise<OHLCTOkenPairResponse> => {
  const queryParams = new URLSearchParams();

  queryParams.append('interval', interval);

  if (from) {
    queryParams.append('from', from.toString());
  }

  if (to) {
    queryParams.append('to', to.toString());
  }

  if (limit) {
    queryParams.append('limit', limit.toString());
  }

  const queryParamsString = queryParams.toString();

  return axios
    .get<OHLCTOkenPairResponse>(
      `${APIConfig.baseURL}/ds/${chainId}/ohlc/${tokenInAddress}/${tokenOutAddress}?${queryParamsString}`,
    )
    .then((response) => response.data);
};

export const useGetLatestPairPrice = (
  params: Pick<FetchOHLCArgs, 'chainId' | 'enabled' | 'tokenInAddress' | 'tokenOutAddress'>,
) => {
  const { chainId, tokenInAddress, tokenOutAddress, enabled = true } = params;

  const interval = OHLCIntervalEnum.ONE_MIN;

  const { data, isLoading, isFetching, error, isRefetching } = useQuery({
    queryFn: () => fetchOHLC({ ...params, interval, limit: 1 }),
    queryKey: [chainId, tokenInAddress, tokenOutAddress],
    enabled,
    staleTime: 1000 * 60 * 1,
    refetchInterval: 1000 * 60 * 1,
  });

  const pairLatestPrice = data?.ohlcPeriod.length
    ? formatOHLCToUnits(
        chainId,
        tokenOutAddress,
        (data.ohlcPeriod ? [...data.ohlcPeriod].sort((a, b) => Date.parse(a.date) - Date.parse(b.date)) : [])[
          data.ohlcPeriod.length - 1
        ],
      ).close
    : '0';

  return {
    pairLatestPriceError: error,
    isPairLatestPriceFetching: isLoading || isFetching,
    isPairLatestPriceRefetching: isLoading || isRefetching,
    pairLatestPrice,
  };
};
