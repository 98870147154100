import { utils } from 'klinecharts';
import { EyeIcon } from 'lucide-react';
import { FC } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../ui/accordion';
import { TypographyS } from '../../../ui/typography';
import { formatDecimalFold } from '../utils/format-decimal-fold';
import { TooltipLabel } from './tooltip-label';

type PriceTooltipProps = {
  volume: number;
  open: number;
  high: number;
  low: number;
  close: number;
  timestamp: number;
  tooltipWidth: number;
  precision: number;
};

export const PriceTooltip: FC<PriceTooltipProps> = (props) => {
  return (
    <>
      <Accordion type="single" collapsible className="xs:hidden block w-full min-w-full">
        <AccordionItem value="item-1" className="!border-b-0 min-w-full ">
          <AccordionTrigger
            className="p-0 px-1 !rounded-r-md  dark:bg-primary-black bg-white !no-underline min-w-full dark:[&[data-state=open]>span]:text-yellow-400"
            showIcon={false}
          >
            <TypographyS className="text-[10px] text-black/50 dark:text-white/80 flex items-center gap-1">
              Show OHLC
              <EyeIcon className="w-3 h-3" />
            </TypographyS>
          </AccordionTrigger>

          <AccordionContent>
            <Tooltips {...props} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <div className="xs:block hidden">
        <Tooltips {...props} />
      </div>
    </>
  );
};

type TooltipsProps = PriceTooltipProps;
const Tooltips: FC<TooltipsProps> = ({ tooltipWidth, volume, open, high, low, precision, close }) => {
  return (
    <div className="flex w-fit flex-wrap gap-2 backdrop-blur-lg z-10 p-1 rounded-r-md bg-white/60 dark:bg-primary-black/20">
      <TooltipLabel
        style={{
          width: tooltipWidth,
        }}
        value={formatDecimalFold(utils.formatPrecision(open, precision))}
        title="Open:"
      />
      <TooltipLabel
        style={{
          width: tooltipWidth,
        }}
        value={formatDecimalFold(utils.formatPrecision(high, precision))}
        title="High:"
      />
      <TooltipLabel
        style={{
          width: tooltipWidth,
        }}
        value={formatDecimalFold(utils.formatPrecision(low, precision))}
        title="Low:"
      />
      <TooltipLabel
        style={{
          width: tooltipWidth,
        }}
        value={formatDecimalFold(utils.formatPrecision(close, precision))}
        title="Close:"
      />
      <TooltipLabel value={formatDecimalFold(utils.formatPrecision(volume, precision))} title="Vol:" />
    </div>
  );
};
