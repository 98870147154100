/**
 * Formats a number to a formatted string with a given number of decimals, suffix and prefix.
 * @returns string
 */
export const formatAmount = ({
  value,
  minimumFractionDigits = 3,
  maximumFractionDigits = 3,
  short = false,
  prefix = '',
  suffix = '',
  precise = false,
  withoutFormatForSmallValue = false,
}: {
  value: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  short?: boolean;
  prefix?: string;
  suffix?: string;
  precise?: boolean;
  withoutFormatForSmallValue?: boolean;
}) => {
  if ((!value && value !== 0) || isNaN(Number(value))) {
    return null;
  }

  if (withoutFormatForSmallValue && value < 0.01 && value !== 0) {
    return prefix + Number(value).toFixed(8) + suffix;
  }

  const template = Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits:
      minimumFractionDigits > maximumFractionDigits ? minimumFractionDigits : maximumFractionDigits,
    notation: short ? 'compact' : 'standard',
  });

  const formattedByTemplate = template.format(Number(value));

  if (precise) return formattedByTemplate;

  const formattedValue = cutFormattedNumber(formattedByTemplate);

  return prefix + formattedValue + suffix;
};

export const cutFormattedNumber = (formattedAmount: string) => {
  const shortNotationLetters = ['K', 'M', 'B', 'T', 'Q', 'Qu', 'S', 'Se', 'O', 'N', 'D', 'U'];
  const letter = shortNotationLetters.some((letter) => formattedAmount.includes(letter))
    ? formattedAmount[formattedAmount.length - 1]
    : '';

  const amountWithoutCommas = formattedAmount.replace(/,/g, '');

  const truncatedNumber = Math.floor(parseFloat(amountWithoutCommas) * 100) / 100;
  const truncatedAmount = truncatedNumber.toString();

  return truncatedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + letter;
};
