import { PriceConditionTypeEnum } from '../../interfaces';

export const getPriceFromPriceCondition = (
  pairLatestPrice: string,
  condition: PriceConditionTypeEnum,
  tokenInUsdPrice: number | null,
  tokenOutUsdPrice: number | null,
) => {
  let adjustedPrice = 0;

  if (pairLatestPrice && condition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) {
    adjustedPrice = 1 / Number(pairLatestPrice);
  }

  if (pairLatestPrice && condition === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) {
    adjustedPrice = Number(pairLatestPrice);
  }

  if (tokenInUsdPrice && condition === PriceConditionTypeEnum.TOKEN_IN_USD) {
    adjustedPrice = tokenInUsdPrice;
  }

  if (tokenOutUsdPrice && condition === PriceConditionTypeEnum.TOKEN_OUT_USD) {
    adjustedPrice = tokenOutUsdPrice;
  }

  return adjustedPrice;
};
