import { FC } from 'react';
import { useSwitchChain } from 'wagmi';
import { Network } from '../../interfaces/network';
import { cn } from '../../utils';
import { getNetworkByChainId, getNetworks } from '../../utils/blockchain/token-list';
import { ChainSelector } from '../network/chain-selector';
import { Button } from '../ui/button';
import { TypographyH4 } from '../ui/typography';

type NetworkSelectorProps = {
  network: Network;
  setNetwork: (n: Network) => void;
};

export const NetworkSelector: FC<NetworkSelectorProps> = ({ network, setNetwork }) => {
  const { switchChainAsync, isPending } = useSwitchChain();

  const handleNetworkChanged = async (n: Network) => {
    setNetwork(n);
    await switchChainAsync({
      chainId: n.chainId,
    });
  };

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center ">
      <TypographyH4 className="!text-sm ">Network</TypographyH4>

      <div className="lg:flex hidden gap-1 xl:gap-1.5">
        {getNetworks().map((n) => (
          <Button
            variant="outline"
            key={n.chainId}
            disabled={isPending || n.chainId === 56 || n.chainId === 8453}
            onClick={async () => await handleNetworkChanged(n)}
            className={cn(`border p-1 px-1.5 xl:p-2 rounded flex items-center text-xs font-medium `, {
              'bg-blue-500 text-white hover:bg-blue-500 dark:bg-primary-gray dark:hover:bg-primary-gray/80':
                n === network,
            })}
          >
            <img src={n.logoUri} alt={`${n.name} logo`} className="w-5 h-5 mr-1" />
            {n.name}
          </Button>
        ))}
      </div>

      <div className="lg:hidden block w-full lg:w-0 mt-1">
        <ChainSelector
          selectedChainId={network.chainId}
          handleChainIdChange={(id) => handleNetworkChanged(getNetworkByChainId(id)!)}
        />
      </div>
    </div>
  );
};
