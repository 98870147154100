import { FC } from 'react';
import { PriceConditionTypeEnum, Token } from '../../../interfaces';
import { cn, formatAmount } from '../../../utils';
import { TokenLabel } from '../../custom/token-label';
import { USDLabel } from '../../custom/usd-label';
import { TypographyS } from '../../ui/typography';

type PriceConditionSummaryProps = {
  value: string;
  conditionType: PriceConditionTypeEnum;
  tokenIn: Token;
  tokenOut: Token;
  closeOrder: boolean;
};

export const PriceConditionSummary: FC<PriceConditionSummaryProps> = ({
  value,
  conditionType,
  tokenIn,
  tokenOut,
  closeOrder,
}) => {
  const renderToken0 = () => {
    if (conditionType === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT) {
      return <TokenLabel imgClassName="md:w-3.5 md:h-3.5 w-3 h-3" token={tokenOut} />;
    }

    if (conditionType === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN) {
      return <TokenLabel imgClassName="md:w-3.5 md:h-3.5 w-3 h-3" token={tokenIn} />;
    }

    if (
      conditionType === PriceConditionTypeEnum.TOKEN_IN_USD ||
      conditionType === PriceConditionTypeEnum.TOKEN_OUT_USD
    ) {
      return <USDLabel imgClassName="md:w-3.5 md:h-3.5 w-3 h-3" />;
    }
  };

  const renderToken1 = () => {
    if (
      conditionType === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT ||
      conditionType === PriceConditionTypeEnum.TOKEN_IN_USD
    ) {
      return <TokenLabel imgClassName="md:w-3.5 md:h-3.5 w-3 h-3" token={tokenIn} />;
    }

    if (
      conditionType === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN ||
      conditionType === PriceConditionTypeEnum.TOKEN_OUT_USD
    ) {
      return <TokenLabel imgClassName="md:w-3.5 md:h-3.5 w-3 h-3" token={tokenOut} />;
    }
  };

  return (
    <div className="flex gap-1 text-xs md:text-sm text-nowrap">
      <TypographyS>price crosses </TypographyS>
      <TypographyS
        className={cn(' dark:text-yellow-400', {
          'dark:text-purple-400': closeOrder,
        })}
      >
        {formatAmount({
          value: +value,
          withoutFormatForSmallValue: true,
          minimumFractionDigits: 2,
          maximumFractionDigits: 4,
          precise: true,
        })}{' '}
      </TypographyS>
      {renderToken0()}
      <TypographyS>
        per{' '}
        <TypographyS
          className={cn('dark:text-yellow-400', {
            'dark:text-purple-400': closeOrder,
          })}
        >
          1
        </TypographyS>
      </TypographyS>
      {renderToken1()}
    </div>
  );
};
