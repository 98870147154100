import { DeepPartial, Styles, TooltipShowRule } from 'klinecharts';

export const applyChartStyles = ({ isMobile }: { isMobile: boolean }): DeepPartial<Styles> => ({
  candle: {
    tooltip: {
      showRule: TooltipShowRule.None,
    },
  },
  indicator: {
    tooltip: {
      showRule: TooltipShowRule.None,
    },
  },

  grid: {
    vertical: {
      color: '#89868675',
    },
    horizontal: {
      color: '#89868675',
    },
  },
  xAxis: {
    axisLine: {
      color: '#99969682',
    },
  },
  yAxis: {
    show: isMobile ? false : true,
    size: isMobile ? 0 : 'auto',
    axisLine: {
      color: '#99969682',
    },
  },
  separator: {
    color: '#99969682',
  },
});
