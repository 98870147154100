import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAccount } from 'wagmi';
import { z } from 'zod';
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useGetERC20Balance,
  useGetLatestPairPrice,
  useGetUSDTokenPrice,
  useValidateCloseOrderPriceCondition,
  useValidatePriceCondition,
} from '../../../hooks';
import {
  FormLimitCloseOrderSchema,
  FormLimitOpenOrderSchema,
  ModalName,
  Network,
  OrderDurationEnum,
  PriceConditionTypeEnum,
  Token,
} from '../../../interfaces';
import { closeModal, setActiveModalInfo } from '../../../store';
import { setStopMarketOrderPair } from '../../../store/order/stop-market-order-slice';
import {
  calculateExpirationTime,
  getPriceFromPriceCondition,
  getPricePrecisionFromPriceCondition,
} from '../../../utils';
import { getAvailableTokens, getNetworks } from '../../../utils/blockchain/token-list';
import { CustomConnectButton } from '../../custom/connect-button';
import { NetworkSelector } from '../../custom/network-selector';
import { Button } from '../../ui/button';
import { Card, CardContent, CardHeader } from '../../ui/card';
import { TypographyH4 } from '../../ui/typography';
import { CreateOrderActions } from '../order/create-order-actions';
import { StopMarketCloseOrderForm } from './close-order/stop-market-close-order-form';
import { StopMarketOrderDurationSelector } from './components/stop-market-order-duration-selector';
import { StopMarketOpenOrderForm } from './open-order/stop-market-open-order-form';
import { StopMarketOrderPreviewModal } from './preview-modal/stop-market-order-preview-modal';

type StopMarketOrderFormProps = {
  className?: string;
};

export const StopMarketOrderForm: FC<StopMarketOrderFormProps> = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((s) => s.auth);
  const { activeModal } = useAppSelector((s) => s.modal);
  const [orderDuration, setOrderDuration] = useState<OrderDurationEnum>(OrderDurationEnum.WEEK);
  const [openOrderPricePercentage, setOpenOrderPricePercentage] = useState<number>(0);
  const [closeOrderPricePercentage, setCloseOrderPricePercentage] = useState<number>(3);
  const [orderExpirationTime, setOrderExpirationTime] = useState<Date | null>(null);

  const { address, isConnected, isConnecting, isReconnecting } = useAccount();

  const [network, setNetwork] = useState(getNetworks()[0]);
  const [enableCLoseOrderForm, setEnableCLoseOrderForm] = useState<boolean>(false);

  const networkTokens = useMemo(() => getAvailableTokens({ chainId: network.chainId }), [network.chainId]);
  const initialTokenIn = networkTokens.filter((t) => t.isStablecoin)[0];
  const initialTokenOut = networkTokens.filter((t) => !t.isStablecoin)[0];

  const {
    priceDifferenceWarning: openOrderPriceDifferenceWarning,
    priceOverflowError: openOrderPriceOverflowError,
    clearErrors: clearOpenOrderPriceConditionValidationErrors,
    validatePrice: validateOpenOrderPrice,
  } = useValidatePriceCondition();

  const {
    priceDifferenceWarning: closeOrderPriceDifferenceWarning,
    priceOverflowError: closeOrderPriceOverflowError,
    clearErrors: clearCloseOrderPriceConditionValidationErrors,
    validatePrice: validateCloseOrderPrice,
  } = useValidateCloseOrderPriceCondition();

  const formOpenOrder = useForm<z.infer<typeof FormLimitOpenOrderSchema>>({
    resolver: zodResolver(FormLimitOpenOrderSchema),
    defaultValues: {
      order: {
        tokenIn: initialTokenIn,
        tokenOut: initialTokenOut,
        amountIn: '',
        priceCondition: {
          value: '',
          initialValue: '',
          conditionType: PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN,
        },
      },
    },
    mode: 'onChange',
  });

  const openOrder = formOpenOrder.watch('order');

  const openOrderTokenOut = openOrder.tokenOut;
  const openOrderTokenIn = openOrder.tokenIn;
  const openOrderPriceCondition = openOrder.priceCondition.value;

  const { isPairLatestPriceFetching, pairLatestPrice } = useGetLatestPairPrice({
    chainId: network.chainId,
    tokenInAddress: openOrderTokenIn.address,
    tokenOutAddress: openOrderTokenOut.address,
  });

  const selectedTokenBalance = useGetERC20Balance({
    isConnected,
    address,
    tokenAddress: openOrder.tokenIn.address,
    chainId: network.chainId,
  });

  const insufficientBalanceError = useMemo(
    () =>
      Boolean(
        selectedTokenBalance &&
          openOrder.amountIn &&
          parseFloat(selectedTokenBalance.formattedTokenBalance) < parseFloat(openOrder.amountIn),
      ),
    [selectedTokenBalance, openOrder.amountIn],
  );

  const networkTokensForCloseOrder = useMemo(
    () =>
      networkTokens.filter((t) => {
        const isTokenInStableCoin = openOrderTokenOut.isStablecoin;
        const isTheSameAddress = openOrderTokenOut.address === t.address;

        if (isTokenInStableCoin) {
          return !t.isStablecoin && !isTheSameAddress;
        }

        return !isTheSameAddress;
      }),
    [networkTokens, openOrderTokenOut.address],
  );

  const initialCLoseOrderTokenOut = networkTokensForCloseOrder[0];

  const formCloseOrder = useForm<z.infer<typeof FormLimitCloseOrderSchema>>({
    resolver: zodResolver(FormLimitCloseOrderSchema),
    defaultValues: {
      order: {
        tokenOut: initialCLoseOrderTokenOut,
        priceCondition: {
          value: '',
          initialValue: '',
          conditionType: PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
        },
      },
    },
    mode: 'onChange',
  });

  const closeOrder = formCloseOrder.watch('order');
  const closeOrderTokenIn = openOrder.tokenOut;
  const closeOrderTokenOut = closeOrder.tokenOut;

  const [openOrderTokenAmountInput, setOpenOrderTokenAmountInput] = useState<string>('');
  const [wasOpenOrderPriceConditionPriceInChanged, setWasOpenOrderPriceConditionPriceInChanged] =
    useState<boolean>(false);
  const [openOrderPriceConditionPriceInput, setOpenOrderPriceConditionPriceInput] =
    useState<string>(openOrderPriceCondition);
  const [closeOrderPriceConditionPriceInput, setCloseOrderPriceConditionPriceInput] = useState<string>('');

  const isOpenOrderPriceConditionTypeTokenOutTokenIn =
    openOrder.priceCondition.conditionType === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN;

  const handleOpenOrderConditionPriceChange = (price: string, wasPriceConditionPriceInChangedManually?: boolean) => {
    formOpenOrder.setValue('order.priceCondition.value', price, {
      shouldValidate: true,
    });

    if (!wasPriceConditionPriceInChangedManually) {
      formOpenOrder.setValue('order.priceCondition.initialValue', price, {
        shouldValidate: true,
      });
    }

    if (wasPriceConditionPriceInChangedManually)
      setWasOpenOrderPriceConditionPriceInChanged((prev) => (prev ? prev : true));
  };

  const [openOrderTokenInUsdPrice, openOrderTokenOutUsdPrice] = useGetUSDTokenPrice([
    {
      oracleAddress: openOrderTokenIn.usdPriceOracle,
      chainId: network.chainId,
    },
    {
      oracleAddress: openOrderTokenOut.usdPriceOracle,
      chainId: network.chainId,
    },
  ]);

  const handleOpenOrderPriceConditionChange = useCallback(
    (condition: PriceConditionTypeEnum) => {
      formOpenOrder.setValue('order.priceCondition.conditionType', condition, {
        shouldValidate: false,
      });

      const closeOrderConditionType =
        condition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN
          ? PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT
          : PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN;

      setOpenOrderPricePercentage(0);

      dispatch(
        setStopMarketOrderPair({
          chainId: network.chainId,
          tokenIn: condition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN ? openOrder.tokenOut : openOrder.tokenIn,
          tokenOut: condition === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN ? openOrder.tokenIn : openOrder.tokenOut,
        }),
      );

      if (enableCLoseOrderForm) {
        formCloseOrder.setValue('order.priceCondition.conditionType', closeOrderConditionType, {
          shouldValidate: false,
        });
      }

      if (pairLatestPrice) {
        const marketPrice = getPriceFromPriceCondition(
          pairLatestPrice,
          condition,
          openOrderTokenInUsdPrice,
          openOrderTokenOutUsdPrice,
        );

        const pricePrecision = getPricePrecisionFromPriceCondition(condition, openOrderTokenIn, openOrderTokenOut);

        if (marketPrice) {
          handleOpenOrderConditionPriceChange(marketPrice.toFixed(pricePrecision));
          setOpenOrderPriceConditionPriceInput(marketPrice.toFixed(pricePrecision));

          if (enableCLoseOrderForm) {
            const adjustedPrice =
              closeOrderConditionType === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT
                ? marketPrice + (marketPrice * 3) / 100
                : marketPrice - (marketPrice * 3) / 100;

            const pricePrecision = getPricePrecisionFromPriceCondition(
              closeOrderConditionType,
              closeOrderTokenIn,
              closeOrderTokenOut,
            );

            setCloseOrderPricePercentage(3);
            handleCloseOrderConditionPriceChange(
              adjustedPrice.toFixed(pricePrecision),
              marketPrice.toFixed(pricePrecision),
            );
            setCloseOrderPriceConditionPriceInput(adjustedPrice.toFixed(pricePrecision));
          }
        }
      }
    },
    [
      pairLatestPrice,
      enableCLoseOrderForm,
      getPriceFromPriceCondition,
      wasOpenOrderPriceConditionPriceInChanged,
      openOrderTokenInUsdPrice,
      openOrderTokenOutUsdPrice,
      openOrderTokenIn,
      openOrderTokenOut,
      closeOrderTokenIn,
      closeOrderTokenOut,
      getPricePrecisionFromPriceCondition,
    ],
  );

  const insufficientOpenOrderMinAmountInError = useMemo(
    () =>
      openOrderTokenInUsdPrice && openOrderTokenAmountInput
        ? Boolean(openOrderTokenInUsdPrice * parseFloat(openOrderTokenAmountInput) <= 0.2)
        : false,
    [selectedTokenBalance, openOrder.amountIn],
  );

  const handleSetMarketplacePrice = useCallback(
    (condition?: PriceConditionTypeEnum) => {
      const newCondition = condition || openOrder.priceCondition.conditionType;
      const marketPrice = getPriceFromPriceCondition(
        pairLatestPrice,
        newCondition,
        openOrderTokenInUsdPrice,
        openOrderTokenOutUsdPrice,
      );

      if (marketPrice) {
        const openOrderPricePrecision = getPricePrecisionFromPriceCondition(
          newCondition,
          openOrderTokenIn,
          openOrderTokenOut,
        );

        setOpenOrderPriceConditionPriceInput(marketPrice.toFixed(openOrderPricePrecision));
        handleOpenOrderConditionPriceChange(marketPrice.toFixed(openOrderPricePrecision), false);

        // make sure to reset the close order price condition with default one
        if (enableCLoseOrderForm) {
          const adjustedPrice =
            closeOrder.priceCondition.conditionType === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT
              ? marketPrice + (marketPrice * 3) / 100
              : marketPrice - (marketPrice * 3) / 100;

          const pricePrecision = getPricePrecisionFromPriceCondition(
            closeOrder.priceCondition.conditionType,
            closeOrderTokenIn,
            closeOrderTokenOut,
          );

          setCloseOrderPriceConditionPriceInput(adjustedPrice.toFixed(pricePrecision));
          handleCloseOrderConditionPriceChange(
            adjustedPrice.toFixed(pricePrecision),
            marketPrice.toFixed(pricePrecision),
          );
          setCloseOrderPricePercentage(3);
        }
      }

      setOpenOrderPricePercentage(0);
    },
    [
      pairLatestPrice,
      enableCLoseOrderForm,
      openOrder.priceCondition.conditionType,
      openOrderTokenOutUsdPrice,
      openOrderTokenInUsdPrice,
      openOrderTokenIn,
      openOrderTokenOut,
      closeOrderTokenIn,
      closeOrderTokenOut,
      closeOrder.priceCondition.conditionType,
      getPricePrecisionFromPriceCondition,
    ],
  );

  const handleOpenOrderPricePercentageChange = useCallback(
    (percentage: number) => {
      const currentPrice = +openOrder.priceCondition.initialValue;
      const adjustedPrice = isOpenOrderPriceConditionTypeTokenOutTokenIn
        ? currentPrice - (currentPrice * percentage) / 100
        : currentPrice + (currentPrice * percentage) / 100;

      const openOrderPricePrecision = getPricePrecisionFromPriceCondition(
        openOrder.priceCondition.conditionType,
        openOrderTokenIn,
        openOrderTokenOut,
      );

      setOpenOrderPriceConditionPriceInput(adjustedPrice.toFixed(openOrderPricePrecision));
      handleOpenOrderConditionPriceChange(adjustedPrice.toFixed(openOrderPricePrecision), true);
      setOpenOrderPricePercentage(percentage);

      if (enableCLoseOrderForm) {
        const adjustedClosePrice = isOpenOrderPriceConditionTypeTokenOutTokenIn
          ? adjustedPrice + (adjustedPrice * 3) / 100
          : adjustedPrice - (adjustedPrice * 3) / 100;

        const pricePrecision = getPricePrecisionFromPriceCondition(
          closeOrder.priceCondition.conditionType,
          closeOrderTokenIn,
          closeOrderTokenOut,
        );

        setCloseOrderPriceConditionPriceInput(adjustedClosePrice.toFixed(pricePrecision));
        handleCloseOrderConditionPriceChange(
          adjustedClosePrice.toFixed(pricePrecision),
          adjustedPrice.toFixed(pricePrecision),
        );
        setCloseOrderPricePercentage(3);
      }
    },
    [
      openOrder,
      openOrderTokenIn,
      openOrderTokenOut,
      isOpenOrderPriceConditionTypeTokenOutTokenIn,
      enableCLoseOrderForm,
      closeOrder.priceCondition.conditionType,
      closeOrderTokenIn,
      closeOrderTokenOut,
    ],
  );

  const debouncedUpdateOpenOrderPriceInput = useDebounce((value: string) => {
    handleOpenOrderConditionPriceChange(value, true);
  }, 500);

  const handleOpenOrderInputPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputVal = e.target.value;
    inputVal = inputVal.replace(/[^\d.]/g, '');

    const firstDotIndex = inputVal.indexOf('.');
    if (firstDotIndex !== -1) {
      inputVal = inputVal.slice(0, firstDotIndex + 1) + inputVal.slice(firstDotIndex + 1).replace(/\./g, '');
    }

    setOpenOrderPriceConditionPriceInput(inputVal);

    debouncedUpdateOpenOrderPriceInput(inputVal);

    const priceFromPriceCondition = getPriceFromPriceCondition(
      pairLatestPrice,
      openOrder.priceCondition.conditionType,
      null,
      null,
    );

    const currentPrice = Number(inputVal);

    let customPercent = 0;

    if (isOpenOrderPriceConditionTypeTokenOutTokenIn && currentPrice < priceFromPriceCondition) {
      customPercent = (currentPrice * 100) / Number(priceFromPriceCondition) - 100;
    } else if (!isOpenOrderPriceConditionTypeTokenOutTokenIn && currentPrice > priceFromPriceCondition) {
      customPercent = (currentPrice * 100) / Number(priceFromPriceCondition) - 100;
    }

    setOpenOrderPricePercentage(customPercent);

    if (enableCLoseOrderForm) {
      const adjustedClosePrice =
        closeOrder.priceCondition.conditionType === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT
          ? currentPrice + (currentPrice * 3) / 100
          : currentPrice - (currentPrice * 3) / 100;

      const pricePrecision = getPricePrecisionFromPriceCondition(
        closeOrder.priceCondition.conditionType,
        closeOrderTokenIn,
        closeOrderTokenOut,
      );

      setCloseOrderPriceConditionPriceInput(adjustedClosePrice.toFixed(pricePrecision));
      handleCloseOrderConditionPriceChange(
        adjustedClosePrice.toFixed(pricePrecision),
        currentPrice.toFixed(pricePrecision),
      );
      setCloseOrderPricePercentage(3);
    }
  };

  const handleCloseOrderConditionPriceChange = (price: string, initialPrice?: string) => {
    formCloseOrder.setValue('order.priceCondition.value', price, {
      shouldValidate: true,
    });

    if (initialPrice) {
      formCloseOrder.setValue('order.priceCondition.initialValue', initialPrice, {
        shouldValidate: true,
      });
    }
  };

  const handleCloseOrderPricePercentageChange = useCallback(
    (percentage: number) => {
      const currentPrice = +openOrderPriceCondition;

      const adjustedPrice = isOpenOrderPriceConditionTypeTokenOutTokenIn
        ? currentPrice + (currentPrice * percentage) / 100
        : currentPrice - (currentPrice * percentage) / 100;

      const pricePrecision = getPricePrecisionFromPriceCondition(
        closeOrder.priceCondition.conditionType,
        closeOrderTokenIn,
        closeOrderTokenOut,
      );

      setCloseOrderPriceConditionPriceInput(adjustedPrice.toFixed(pricePrecision));
      handleCloseOrderConditionPriceChange(adjustedPrice.toFixed(pricePrecision));
      setCloseOrderPricePercentage(percentage);
    },
    [
      openOrderPriceCondition,
      isOpenOrderPriceConditionTypeTokenOutTokenIn,
      closeOrder.priceCondition.conditionType,
      closeOrderTokenIn,
      closeOrderTokenOut,
    ],
  );

  const debouncedUpdateCloseOrderPriceInput = useDebounce((value: string) => {
    handleCloseOrderConditionPriceChange(value);
  }, 500);

  const handleCloseOrderPriceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputVal = e.target.value;
    inputVal = inputVal.replace(/[^\d.]/g, '');

    const firstDotIndex = inputVal.indexOf('.');
    if (firstDotIndex !== -1) {
      inputVal = inputVal.slice(0, firstDotIndex + 1) + inputVal.slice(firstDotIndex + 1).replace(/\./g, '');
    }

    setCloseOrderPriceConditionPriceInput(inputVal);

    debouncedUpdateCloseOrderPriceInput(inputVal);

    const currentPrice = Number(inputVal);
    const priceCondtion = Number(openOrderPriceCondition);

    let customPercent = 0;

    if (!isOpenOrderPriceConditionTypeTokenOutTokenIn && currentPrice < priceCondtion) {
      customPercent = (currentPrice * 100) / Number(priceCondtion) - 100;
    } else if (isOpenOrderPriceConditionTypeTokenOutTokenIn && currentPrice > priceCondtion) {
      customPercent = (currentPrice * 100) / Number(priceCondtion) - 100;
    }

    setCloseOrderPricePercentage(customPercent);
  };

  const handleNetworkChange = useCallback(
    (n: Network) => {
      setNetwork(n);
      setOpenOrderTokenAmountInput('');
      setWasOpenOrderPriceConditionPriceInChanged(false);
      setOpenOrderPriceConditionPriceInput('');
      clearOpenOrderPriceConditionValidationErrors();
      clearCloseOrderPriceConditionValidationErrors();

      const tokenIn = n.tokens.filter((t) => t.isStablecoin)[0];
      const tokenOut = n.tokens.filter((t) => !t.isStablecoin)[0];

      formOpenOrder.reset();

      formOpenOrder.setValue('order', {
        tokenIn,
        tokenOut,
        amountIn: '',
        priceCondition: {
          value: '',
          initialValue: '',
          conditionType: PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN,
        },
      });

      formCloseOrder.reset();

      setEnableCLoseOrderForm(false);

      dispatch(setStopMarketOrderPair({ tokenIn, tokenOut, chainId: n.chainId }));
    },
    [dispatch, setStopMarketOrderPair],
  );

  const handleSubmit = () => {
    setOrderExpirationTime(calculateExpirationTime(orderDuration));
    dispatch(setActiveModalInfo({ activeModal: ModalName.STOP_MARKET_ORDER_PREVIEW_DESKTOP, modalInfo: null }));
  };

  const onOrderCreated = useCallback(() => {
    formCloseOrder.reset();
    formOpenOrder.reset();
    dispatch(closeModal());
    setOpenOrderTokenAmountInput('');
    setWasOpenOrderPriceConditionPriceInChanged(false);
    setOpenOrderPriceConditionPriceInput('');
    clearOpenOrderPriceConditionValidationErrors();
    clearCloseOrderPriceConditionValidationErrors();

    setEnableCLoseOrderForm(false);
    handleSetMarketplacePrice(PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN);
  }, [formOpenOrder, handleSetMarketplacePrice, formCloseOrder]);

  const handleOpenOrderSelectedTokensChange = useCallback(
    ({ tokenIn, tokenOut }: { tokenIn: Token; tokenOut: Token }) => {
      // since the default price condition is TOKEN_OUT_TOKEN_IN, we reverse the order
      dispatch(setStopMarketOrderPair({ tokenIn: tokenOut, tokenOut: tokenIn, chainId: network.chainId }));

      setWasOpenOrderPriceConditionPriceInChanged(false);
      setOpenOrderTokenAmountInput('');
      setOpenOrderPriceConditionPriceInput('');
      setCloseOrderPriceConditionPriceInput('');
      setCloseOrderPricePercentage(0);
      clearOpenOrderPriceConditionValidationErrors();
      clearCloseOrderPriceConditionValidationErrors();

      formOpenOrder.reset({
        order: {
          amountIn: '',
          tokenIn,
          tokenOut,
          priceCondition: {
            value: '',
            initialValue: '',
            conditionType: PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN,
          },
        },
      });

      formCloseOrder.reset({
        order: {
          tokenOut: tokenIn,
          priceCondition: {
            value: '',
            initialValue: '',
            conditionType: PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT,
          },
        },
      });
    },
    [network.chainId],
  );

  const handleAddCloseOrder = useCallback(() => {
    // when we add close order we use open order price as initial + 3%
    setEnableCLoseOrderForm(true);
    setCloseOrderPricePercentage(3);

    const closeOrderConditionType =
      openOrder.priceCondition.conditionType === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT
        ? PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN
        : PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT;

    const pricePrecision = getPricePrecisionFromPriceCondition(
      closeOrderConditionType,
      openOrder.tokenOut,
      openOrder.tokenIn,
    );

    const currentPrice = +openOrderPriceCondition;

    const adjustedPrice = isOpenOrderPriceConditionTypeTokenOutTokenIn
      ? currentPrice + (currentPrice * 3) / 100
      : currentPrice - (currentPrice * 3) / 100;

    formCloseOrder.setValue(
      'order',
      {
        tokenOut: openOrder.tokenIn,
        priceCondition: {
          value: adjustedPrice.toFixed(pricePrecision),
          initialValue: openOrderPriceCondition,
          conditionType: closeOrderConditionType,
        },
      },
      {
        shouldValidate: true,
      },
    );

    setCloseOrderPriceConditionPriceInput(adjustedPrice.toFixed(pricePrecision));
  }, [openOrder.tokenOut, isOpenOrderPriceConditionTypeTokenOutTokenIn, openOrderPriceCondition, openOrder.tokenIn]);

  const handleRemoveCloseOrder = useCallback(() => {
    setEnableCLoseOrderForm(false);
    setCloseOrderPriceConditionPriceInput('');
    clearCloseOrderPriceConditionValidationErrors();
    setCloseOrderPricePercentage(0);

    formCloseOrder.reset();
  }, [formCloseOrder]);

  const isCreateButtonDisabled =
    !isConnected ||
    insufficientBalanceError ||
    openOrderPriceOverflowError ||
    !formOpenOrder.formState.isValid ||
    (enableCLoseOrderForm ? !formCloseOrder.formState.isValid || closeOrderPriceOverflowError : false);

  return (
    <Card className="w-full  md:max-h-[645px] overflow-auto  relative">
      <CardHeader className="p-3.5 px-2 lg:px-4 xl:px-6">
        <TypographyH4 className="xl:text-xl lg:text-lg text-base lg:text-start text-center">
          Create Stop Market Order
        </TypographyH4>
      </CardHeader>
      <CardContent className="flex flex-col px-2 xl:px-3 pb-4">
        <div className="mb-2.5">
          <NetworkSelector network={network} setNetwork={handleNetworkChange} />
        </div>

        <FormProvider {...formOpenOrder}>
          <StopMarketOpenOrderForm
            insufficientOpenOrderMinAmountInError={insufficientOpenOrderMinAmountInError}
            orderPricePercentage={openOrderPricePercentage}
            networkTokens={networkTokens}
            tokenAmountInput={openOrderTokenAmountInput}
            priceConditionPriceInput={openOrderPriceConditionPriceInput}
            priceDifferenceWarning={openOrderPriceDifferenceWarning}
            priceOverflowError={openOrderPriceOverflowError}
            wasPriceConditionPriceInChanged={wasOpenOrderPriceConditionPriceInChanged}
            insufficientBalanceError={insufficientBalanceError}
            selectedTokenBalance={selectedTokenBalance?.formattedTokenBalance}
            isPairLatestPriceFetching={isPairLatestPriceFetching}
            pairLatestPrice={pairLatestPrice}
            tokenInUsdPrice={openOrderTokenInUsdPrice}
            tokenOutUsdPrice={openOrderTokenOutUsdPrice}
            setTokenAmountInput={setOpenOrderTokenAmountInput}
            handleSelectedTokensChange={handleOpenOrderSelectedTokensChange}
            validateOpenOrderPrice={validateOpenOrderPrice}
            handleSetMarketplacePrice={handleSetMarketplacePrice}
            handleOrderPriceConditionChange={handleOpenOrderPriceConditionChange}
            handleOpenOrderPricePercentageChange={handleOpenOrderPricePercentageChange}
            handleOpenOrderInputPriceChange={handleOpenOrderInputPriceChange}
          />
        </FormProvider>

        {!enableCLoseOrderForm && (
          <div className="mt-2 self-end dark:w-full ">
            <CreateOrderActions addOrder={handleAddCloseOrder} disabled={!pairLatestPrice} />
          </div>
        )}

        {enableCLoseOrderForm ? (
          <div className="mt-2.5">
            <FormProvider {...formCloseOrder}>
              <StopMarketCloseOrderForm
                priceOverflowError={closeOrderPriceOverflowError}
                priceDifferenceWarning={closeOrderPriceDifferenceWarning}
                validateOrderPrice={validateCloseOrderPrice}
                handleRemoveCloseOrder={handleRemoveCloseOrder}
                orderTokenIn={openOrderTokenOut}
                orderTokenOut={openOrderTokenIn}
                chainId={network.chainId}
                orderPricePercentage={closeOrderPricePercentage}
                orderPriceConditionPriceInput={closeOrderPriceConditionPriceInput}
                handleCloseOrderPricePercentageChange={handleCloseOrderPricePercentageChange}
                handleCloseOrderPriceInputChange={handleCloseOrderPriceInputChange}
                initialPrice={openOrderPriceCondition}
              />
            </FormProvider>
          </div>
        ) : null}

        <div className="flex justify-between lg:flex-row flex-col lg:items-center mt-2.5 ">
          <TypographyH4 className="!text-sm  font-medium">Duration</TypographyH4>
          <StopMarketOrderDurationSelector orderDuration={orderDuration} setOrderDuration={setOrderDuration} />
        </div>

        <div className="flex text-sm font-medium  w-full mt-2.5 ">
          {isAuthenticated || isConnected ? (
            <Button
              className="bg-blue-500 text-white py-2 px-5 w-full rounded-lg shadow hover:bg-blue-600 dark:bg-primary-gray dark:hover:bg-primary-gray/80 disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={handleSubmit}
              disabled={isCreateButtonDisabled}
              loading={isConnecting || isReconnecting}
            >
              Place {enableCLoseOrderForm ? 'Orders' : 'Order'}
            </Button>
          ) : (
            <CustomConnectButton buttonClassName="!text-sm w-full dark:border-none !py-5 capitalize dark:hover:bg-primary-light-gray/20 dark:bg-primary-light-gray/20 text-primary-light-gray dark:text-white/80" />
          )}
        </div>
      </CardContent>

      {activeModal === ModalName.STOP_MARKET_ORDER_PREVIEW_DESKTOP && orderExpirationTime ? (
        <StopMarketOrderPreviewModal
          openOrder={openOrder}
          closeOrder={enableCLoseOrderForm ? closeOrder : null}
          network={network}
          onOrderCreated={onOrderCreated}
          onCloseModal={() => dispatch(closeModal())}
          expirationTime={orderExpirationTime}
          tokenInUserBalance={selectedTokenBalance?.rawTokenBalance || '0'}
        />
      ) : null}
    </Card>
  );
};
