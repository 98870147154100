import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { APIConfig } from '../../configs';
import { PriceConditionFormType, StopMarketOrderQuoteEstimationType } from '../../interfaces';
import { axiosClientWithCredentials } from '../../utils';

export type GetStopMarketOrderQuoteEstimationParams = {
  chainId: number;
  tokenInId: string;
  tokenOutId: string;
  openOrderPriceCondition: PriceConditionFormType;
  closeOrderPriceCondition: PriceConditionFormType | null;
  closeTokenOutId: string | null;
  amountIn: string;
};

export const getStopMarketOrderQuoteEstimation = async (queryParams: string) => {
  return await axiosClientWithCredentials.get<{
    openOrderQuoteEstimation: StopMarketOrderQuoteEstimationType;
    closeOrderQuoteEstimation: StopMarketOrderQuoteEstimationType | null;
  }>(`${APIConfig.baseURL}/ds/orders/estimate-quote?${queryParams}`);
};

export const useGetStopMarketOrderQuoteEstimation = (
  params: GetStopMarketOrderQuoteEstimationParams,
  enabled: boolean,
) => {
  const url = new URLSearchParams();

  const openOrderPriceCondition = `${JSON.stringify(params.openOrderPriceCondition)}`;
  const closeOrderPriceCondition = `${JSON.stringify(params.closeOrderPriceCondition)}`;

  url.set('tokenInId', `${params.tokenInId}`);
  url.set('tokenOutId', `${params.tokenOutId}`);
  url.set('amountIn', `${params.amountIn}`);
  url.set('chainId', `${params.chainId}`);
  url.set('openOrderPriceCondition', openOrderPriceCondition);
  url.set('closeOrderPriceCondition', closeOrderPriceCondition);

  if (params.closeTokenOutId) url.set('closeTokenOutId', `${params.closeTokenOutId}`);

  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryFn: () => getStopMarketOrderQuoteEstimation(url.toString()),
    queryKey: [
      'quoteEstimation',
      params.tokenInId,
      params.tokenOutId,
      params.amountIn,
      params.chainId,
      params.closeTokenOutId,
      openOrderPriceCondition,
      closeOrderPriceCondition,
    ],
    enabled,
    staleTime: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  let stopMarketOrderQuoteEstimationError = error?.message;

  if (error && isAxiosError(error)) {
    const errorsArray = error.response?.data?.errors as string[];
    stopMarketOrderQuoteEstimationError = errorsArray ? errorsArray[0] : error.message;
  }

  return {
    isStopMarketOrderQuoteEstimationLoading: isFetching || isLoading,
    stopMarketOrderQuoteEstimation: data?.data,
    stopMarketOrderQuoteEstimationError,
    refetchStopMarketOrderQuoteEstimation: refetch,
  };
};
