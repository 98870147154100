import { DisconnectMutate } from 'wagmi/query';
import { toast } from '../../hooks';
import { AppDispatch, closeModal, setAuthenticated } from '../../store';
import { localStorageManager } from '../global/local-storage-manager';

export const signOutHandler = async (dispatch: AppDispatch, disconnect: DisconnectMutate<unknown>) => {
  if (localStorageManager.getByPrefix(`auth-`)) {
    toast({
      title: 'Sign out',
      description: 'Signed out from the DEX Standard platform',
    });
  }

  localStorageManager.removeByPrefix(`auth-`);
  dispatch(setAuthenticated({ isAuthenticated: false }));
  dispatch(closeModal());

  disconnect();
};
