import { useMutation } from '@tanstack/react-query';
import { APIConfig } from '../../configs';
import { axiosClientWithCredentials } from '../../utils';

const checkAuth = async () => {
  let isAuthenticated: boolean = false;

  try {
    const { data } = await axiosClientWithCredentials.get<{ status: 'ok' }>(`${APIConfig.baseURL}/u/check-auth`);

    isAuthenticated = data.status === 'ok';
  } catch (err) {
    console.error(err);
    isAuthenticated = false;
  }

  return isAuthenticated;
};

export const useCheckAuth = () => {
  const { data, mutateAsync, isPending, error } = useMutation({
    mutationFn: () => checkAuth(),
  });

  return {
    checkAuthError: error,
    isCheckingAuth: isPending,
    checkAuth: mutateAsync,
    isAuthValid: data,
  };
};
