import { useMutation } from '@tanstack/react-query';
import { APIConfig } from '../../configs';
import { axiosClientWithCredentials } from '../../utils';

export const reserveStopMarketOrderId = async () => {
  return await axiosClientWithCredentials.get<string>(`${APIConfig.baseURL}/ds/orders/reserve-id`);
};

export const useReserveStopMarketOrderId = () => {
  const { data, isPending, error, mutateAsync } = useMutation({
    mutationFn: () => reserveStopMarketOrderId(),
    mutationKey: ['reserveOrderId'],
  });

  return {
    reservedOrderId: data?.data,
    reserveOrderIdLoading: isPending,
    reserveOrderIdError: error,
    reserveOrderId: mutateAsync,
  };
};
