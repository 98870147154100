import { useQuery } from '@tanstack/react-query';
import { getERC20Balance } from '../../utils';

export const useGetERC20Balance = ({
  isConnected,
  address,
  tokenAddress,
  chainId,
}: {
  isConnected: boolean;
  address?: string;
  tokenAddress: string;
  chainId: number;
}) => {
  const { data } = useQuery({
    queryKey: ['erc20Balance', chainId, tokenAddress, address],
    queryFn: () => getERC20Balance(chainId, tokenAddress, address || ''),
    enabled: isConnected && !!address && !!tokenAddress && !!chainId,
    refetchOnWindowFocus: true,
    refetchInterval: 1 * 60 * 1000, // 1 minute,
    staleTime: 1 * 60 * 1000, // 1 minute
  });

  return data;
};
