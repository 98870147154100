import { formatUnits } from 'ethers';
import { FC } from 'react';
import { StopMarketOrderInfo, Token } from '../../../../interfaces';
import { cn, formatAmount } from '../../../../utils';
import { getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { TokenLogo } from '../../../custom/token-logo';
import { TypographyP } from '../../../ui/typography';

type AmountColumnProps = {
  order: StopMarketOrderInfo;
};

export const AmountColumn: FC<AmountColumnProps> = ({ order }) => {
  const openOrderTokenIn = getTokenByTokenId(order.openOrder.tokenInId);
  const closeOrderTokenIn = getTokenByTokenId(order.openOrder.tokenOutId);

  return (
    <div className="flex space-y-1.5 flex-col min-w-max">
      <InputAmountOrderLabel amount={order.openOrder.amountIn} tokenIn={openOrderTokenIn!} isClose={false} />

      {order.closeOrder && (
        <InputAmountOrderLabel
          amount={order.openOrder.amountOut || order.openOrder.minAmountOut}
          tokenIn={closeOrderTokenIn!}
          isClose={true}
        />
      )}
    </div>
  );
};

type InputAmountOrderLabelProps = {
  isClose: boolean;
  amount: string;
  tokenIn: Token;
};

export const InputAmountOrderLabel: FC<InputAmountOrderLabelProps> = ({ isClose, amount: amountOut, tokenIn }) => {
  return (
    <div className="flex gap-1 justify-end xl:justify-center">
      <TypographyP
        className={cn('text-[11px] leading-4', {
          'dark:text-purple-400': isClose,
          'dark:text-amber-400': !isClose,
        })}
      >
        {formatAmount({
          value: parseFloat(formatUnits(amountOut, tokenIn.decimals)),
          withoutFormatForSmallValue: true,
        })}
      </TypographyP>
      <TokenLogo token={tokenIn} imgClassName="w-3.5 h-3.5 " />
    </div>
  );
};
