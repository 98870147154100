import { TriangleAlert } from 'lucide-react';
import { FC } from 'react';
import { cn } from '../../../../utils';
import { TypographyP } from '../../../ui/typography';

type StopMarketOrderPreviewModalAlertLabelProps = {
  wrapperClassName?: string;
};

export const StopMarketOrderPreviewModalAlertLabel: FC<StopMarketOrderPreviewModalAlertLabelProps> = ({
  wrapperClassName,
}) => {
  return (
    <div className={cn('flex items-center', wrapperClassName)}>
      <TriangleAlert className="text-yellow-400 mr-2" />

      <TypographyP className="text-xs">
        Do not close this modal until you approve tokens usage and confirm the order placement!
      </TypographyP>
    </div>
  );
};
