import { ethers } from 'ethers';
import { XIcon } from 'lucide-react';
import { FC, useCallback, useMemo } from 'react';
import { useAccount } from 'wagmi';
import { STOP_MARKET_ORDER_MANAGER_ADDRESS_ARBITRUM } from '../../../../constants';
import {
  toast,
  useAppDispatch,
  useCreateStopMarketOrder,
  useGetAllowance,
  useGetStopMarketOrderQuoteEstimation,
  useGetUserStopMarketOrdersTokensAllowance,
  useReserveStopMarketOrderId,
  useSignStopMarketOrder,
  useTokenApproval,
} from '../../../../hooks';
import { LimitCloseOrderType, LimitOpenOrderType, Network, StopMarketOrderType } from '../../../../interfaces';
import { closeModal } from '../../../../store';
import { formatOrderPriceConditionValueToWei } from '../../../../utils';
import { getTokenId } from '../../../../utils/blockchain/token-list';
import { mapCloseOrderForCreation, mapOpenOrderForCreation } from '../../../../utils/order/map-order';
import { NetworkLabel } from '../../../custom/network-label';
import { Button } from '../../../ui/button';
import { Dialog, DialogContent, DialogFooter, DialogTitle } from '../../../ui/dialog';
import { TypographyH4 } from '../../../ui/typography';
import { StopMarketOrderPreviewModalCloseOrderActions } from '../close-order/stop-market-order-preview-modal-close-order-actions';
import { StopMarketOrderPreviewModalOpenOrderActions } from '../open-order/stop-market-order-preview-modal-open-order-actions';
import { StopMarketOrderClosePositionPreview } from './stop-market-order-close-position-preview';
import { StopMarketOrderOpenPositionPreview } from './stop-market-order-open-position-preview';
import { StopMarketOrderPreviewModalAlertLabel } from './stop-market-order-preview-modal-alert-label';

type StopMarketOrderPreviewModalProps = {
  network: Network;
  expirationTime: Date;
  openOrder: LimitOpenOrderType['order'];
  closeOrder: LimitCloseOrderType['order'] | null;
  tokenInUserBalance: string;
  onOrderCreated: () => void;
  onCloseModal: () => void;
};

export const StopMarketOrderPreviewModal: FC<StopMarketOrderPreviewModalProps> = ({
  network,
  openOrder,
  closeOrder,
  expirationTime,
  tokenInUserBalance,
  onCloseModal,
  onOrderCreated,
}) => {
  const dispatch = useAppDispatch();

  const tokenInId = getTokenId(openOrder.tokenIn.chainId, openOrder.tokenIn.address);
  const tokenOutId = getTokenId(openOrder.tokenOut.chainId, openOrder.tokenOut.address);

  const { address } = useAccount();
  const chainId = network.chainId;

  const openOrderTokenInAmountInWei = useMemo(
    () => ethers.parseUnits(openOrder.amountIn, openOrder.tokenIn.decimals).toString(),
    [openOrder.amountIn, openOrder.tokenIn.decimals],
  );

  const { value: openOrderPriceConditionValueWei, initialValue: openOrderPriceConditionInitialValueWei } =
    formatOrderPriceConditionValueToWei(
      openOrder.priceCondition.value,
      openOrder.priceCondition.initialValue,
      openOrder.priceCondition.conditionType,
      openOrder.tokenIn,
      openOrder.tokenOut,
    )!;

  const { value: closeOrderPriceConditionValueWei, initialValue: closeOrderPriceConditionInitialValueWei } = closeOrder
    ? formatOrderPriceConditionValueToWei(
        closeOrder.priceCondition.value,
        closeOrder.priceCondition.initialValue,
        closeOrder.priceCondition.conditionType,
        openOrder.tokenOut,
        closeOrder.tokenOut,
      )!
    : { value: null, initialValue: null };

  const {
    stopMarketOrdersTokensAllowance,
    areStopMarketOrdersTokensAllowanceLoading,
    refetchStopMarketOrdersTokensAllowance,
    stopMarketOrdersTokensAllowanceError,
  } = useGetUserStopMarketOrdersTokensAllowance({
    tokenInId,
    tokenOutId,
  });

  const { reserveOrderId, reservedOrderId } = useReserveStopMarketOrderId();

  const {
    approveToken: approveOpenOrderToken,
    error: approveOpenOrderTokenError,
    isApproving: isOpenOrderTokenApproving,
    isApproved: isOpenOrderTokenApproved,
  } = useTokenApproval();
  const {
    approveToken: approveCloseOrderToken,
    error: approveCloseOrderTokenError,
    isApproving: isCloseOrderTokenApproving,
    isApproved: isCloseOrderTokenApproved,
  } = useTokenApproval();

  const {
    allowance: openOrderTokenInAllowance,
    allowanceError: openOrderTokenInAllowanceError,
    isAllowanceLoading: isOpenOrderTokenInAllowanceLoading,
    refetchTokenAllowance: refetchOpenOrderTokenInAllowance,
  } = useGetAllowance({
    tokenAddress: openOrder.tokenIn.address,
    ownerAddress: address!,
    spenderAddress: STOP_MARKET_ORDER_MANAGER_ADDRESS_ARBITRUM,
  });

  const amountOfOpenOrderTokenInToBeApprovedForOrders = useMemo(
    () =>
      stopMarketOrdersTokensAllowance
        ? (
            ethers.toBigInt(openOrderTokenInAmountInWei) +
            ethers.toBigInt(stopMarketOrdersTokensAllowance.totalAmountTokenIn)
          ).toString()
        : undefined,
    [stopMarketOrdersTokensAllowance, openOrderTokenInAmountInWei],
  );

  const showInsufficientTokenInBalanceError = useMemo(
    () =>
      !stopMarketOrdersTokensAllowance || !amountOfOpenOrderTokenInToBeApprovedForOrders
        ? false
        : ethers.toBigInt(tokenInUserBalance) < ethers.toBigInt(amountOfOpenOrderTokenInToBeApprovedForOrders),
    [tokenInUserBalance, amountOfOpenOrderTokenInToBeApprovedForOrders],
  );

  const isOpenOrderTokenTotalAmountApproved = useMemo(() => {
    if (isOpenOrderTokenApproved) return true;

    if (amountOfOpenOrderTokenInToBeApprovedForOrders && openOrderTokenInAllowance) {
      return (
        ethers.toBigInt(amountOfOpenOrderTokenInToBeApprovedForOrders) <= ethers.toBigInt(openOrderTokenInAllowance)
      );
    }

    return false;
  }, [amountOfOpenOrderTokenInToBeApprovedForOrders, isOpenOrderTokenApproved, openOrderTokenInAllowance]);

  const {
    stopMarketOrderQuoteEstimation,
    isStopMarketOrderQuoteEstimationLoading,
    refetchStopMarketOrderQuoteEstimation,
    stopMarketOrderQuoteEstimationError,
  } = useGetStopMarketOrderQuoteEstimation(
    {
      amountIn: openOrderTokenInAmountInWei,
      chainId,
      tokenInId,
      tokenOutId,
      openOrderPriceCondition: {
        ...openOrder.priceCondition,
        value: openOrderPriceConditionValueWei,
        initialValue: openOrderPriceConditionInitialValueWei,
      },
      closeOrderPriceCondition:
        closeOrder && closeOrderPriceConditionValueWei
          ? {
              ...closeOrder.priceCondition,
              value: closeOrderPriceConditionValueWei,
              initialValue: closeOrderPriceConditionInitialValueWei,
            }
          : null,
      closeTokenOutId: closeOrder ? getTokenId(closeOrder.tokenOut.chainId, closeOrder.tokenOut.address) : null,
    },
    isOpenOrderTokenTotalAmountApproved,
  );

  const {
    allowance: closeOrderTokenInAllowance,
    allowanceError: closeOrderTokenInAllowanceError,
    isAllowanceLoading: isCloseOrderTokenInAllowanceLoading,
    refetchTokenAllowance: refetchCloseOrderTokenInAllowance,
  } = useGetAllowance({
    tokenAddress: openOrder.tokenOut.address,
    ownerAddress: address!,
    spenderAddress: STOP_MARKET_ORDER_MANAGER_ADDRESS_ARBITRUM,
    enabled: !!closeOrder,
  });

  const closeOrderTokenInAmountInWei = stopMarketOrderQuoteEstimation?.openOrderQuoteEstimation?.minAmountOut;

  const amountOfCloseOrderTokenInToBeApprovedForOrders = useMemo(
    () =>
      stopMarketOrdersTokensAllowance && closeOrderTokenInAmountInWei
        ? (
            ethers.toBigInt(closeOrderTokenInAmountInWei) +
            ethers.toBigInt(stopMarketOrdersTokensAllowance.totalCloseAmountTokenIn)
          ).toString()
        : undefined,
    [stopMarketOrdersTokensAllowance, closeOrderTokenInAmountInWei],
  );

  const isCloseOrderTokenTotalAmountApproved = useMemo(() => {
    if (isCloseOrderTokenApproved) return true;

    if (amountOfCloseOrderTokenInToBeApprovedForOrders && closeOrderTokenInAllowance) {
      return (
        ethers.toBigInt(amountOfCloseOrderTokenInToBeApprovedForOrders) <= ethers.toBigInt(closeOrderTokenInAllowance)
      );
    }

    return false;
  }, [amountOfCloseOrderTokenInToBeApprovedForOrders, isCloseOrderTokenApproved, closeOrderTokenInAllowance]);

  const { createStopMarketOrder, isCreateStopMarketOrderPending } = useCreateStopMarketOrder();

  const approveOpenOrderTokenHandler = useCallback(async () => {
    if (!amountOfOpenOrderTokenInToBeApprovedForOrders) return;

    await approveOpenOrderToken({
      amountWei: amountOfOpenOrderTokenInToBeApprovedForOrders,
      spenderAddress: STOP_MARKET_ORDER_MANAGER_ADDRESS_ARBITRUM,
      token: openOrder.tokenIn,
    });
  }, [openOrder.tokenIn, amountOfOpenOrderTokenInToBeApprovedForOrders]);

  const approveCloseOrderTokenHandler = useCallback(async () => {
    if (!amountOfCloseOrderTokenInToBeApprovedForOrders) return;

    await approveCloseOrderToken({
      amountWei: amountOfCloseOrderTokenInToBeApprovedForOrders,
      spenderAddress: STOP_MARKET_ORDER_MANAGER_ADDRESS_ARBITRUM,
      token: openOrder.tokenOut,
    });
  }, [amountOfCloseOrderTokenInToBeApprovedForOrders, openOrder.tokenOut.address]);

  const {
    isOrderSigning: isOpenOrderSigning,
    reservedOrderNonce: reservedOpenOrderNonce,
    signOrder: signOpenOrder,
    signedOrderSignature: signedOpenOrderSignature,
  } = useSignStopMarketOrder();

  const signOpenOrderHandler = useCallback(
    async () =>
      signOpenOrder({
        chainId,
        expirationTime,
        order: { ...openOrder, amountIn: openOrderTokenInAmountInWei },
        reserveOrderId,
        reservedOrderId,
        userAddress: address!,
        orderQuoteEstimation: stopMarketOrderQuoteEstimation?.openOrderQuoteEstimation,
        orderType: StopMarketOrderType.OPEN,
      }),
    [
      stopMarketOrderQuoteEstimation,
      openOrderTokenInAmountInWei,
      address,
      chainId,
      expirationTime,
      openOrder,
      reserveOrderId,
      reservedOrderId,
    ],
  );

  const {
    isOrderSigning: isCloseOrderSigning,
    reservedOrderNonce: reservedCloseOrderNonce,
    signOrder: signCloseOrder,
    signedOrderSignature: signedCloseOrderSignature,
  } = useSignStopMarketOrder();

  const signCloseOrderHandler = useCallback(async () => {
    if (closeOrder && stopMarketOrderQuoteEstimation) {
      signCloseOrder({
        chainId,
        expirationTime,
        order: {
          ...closeOrder,
          tokenIn: openOrder.tokenOut,
          amountIn: stopMarketOrderQuoteEstimation.openOrderQuoteEstimation.minAmountOut,
        },
        reserveOrderId,
        reservedOrderId,
        userAddress: address!,
        orderQuoteEstimation: stopMarketOrderQuoteEstimation.closeOrderQuoteEstimation,
        orderType: StopMarketOrderType.CLOSE,
      });

      return;
    }
  }, [stopMarketOrderQuoteEstimation, reserveOrderId, address, chainId, expirationTime, openOrder, reservedOrderId]);

  const createStopMarketOrderHandler = async () => {
    try {
      if (!openOrderPriceConditionValueWei || (closeOrder && !closeOrderPriceConditionValueWei)) {
        throw new Error('Cannot parse order price condition value');
      }

      if (!reservedOrderId || !stopMarketOrderQuoteEstimation || !reservedOpenOrderNonce || !signedOpenOrderSignature) {
        throw new Error('Order creation precondition failed');
      }

      if (
        closeOrder &&
        (!reservedCloseOrderNonce ||
          !stopMarketOrderQuoteEstimation.closeOrderQuoteEstimation ||
          !closeOrderPriceConditionValueWei)
      ) {
        throw new Error('Order creation precondition failed');
      }

      const mappedOpenOrder = mapOpenOrderForCreation(
        openOrderTokenInAmountInWei,
        stopMarketOrderQuoteEstimation.openOrderQuoteEstimation,
        openOrder,
        openOrderPriceConditionValueWei,
        openOrderPriceConditionInitialValueWei,
        reservedOpenOrderNonce,
        signedOpenOrderSignature,
      );

      const mappedCloseOrder = closeOrder
        ? mapCloseOrderForCreation(
            stopMarketOrderQuoteEstimation.openOrderQuoteEstimation,
            stopMarketOrderQuoteEstimation.closeOrderQuoteEstimation!,
            openOrder,
            closeOrder,
            closeOrderPriceConditionValueWei!,
            closeOrderPriceConditionInitialValueWei!,
            reservedCloseOrderNonce!,
            signedCloseOrderSignature!,
          )
        : null;

      const res = await createStopMarketOrder({
        chainId,
        orderId: reservedOrderId,
        expirationTime,
        closeOrder: mappedCloseOrder,
        openOrder: mappedOpenOrder,
      });

      if (res.data.orderId) {
        dispatch(closeModal());
        onOrderCreated();
      }
      // eslint-disable-next-line
    } catch (error: any) {
      toast({
        title: `Order creation failed`,
        description: error.message || `Order creation precondition failed`,
      });
      console.log(error);
    }
  };

  const onClose = () => {
    dispatch(closeModal());
    onCloseModal();
  };

  const isOpenOrderPreparedToSubmit =
    stopMarketOrderQuoteEstimation?.openOrderQuoteEstimation &&
    isOpenOrderTokenTotalAmountApproved &&
    !!signedOpenOrderSignature &&
    !showInsufficientTokenInBalanceError;

  const isCloseOrderPreparedToSubmit =
    stopMarketOrderQuoteEstimation?.closeOrderQuoteEstimation &&
    isCloseOrderTokenTotalAmountApproved &&
    !!signedCloseOrderSignature;

  return (
    <Dialog open={true}>
      <DialogContent showClose={false} className="overflow-auto max-h-[100vh]">
        <Button
          variant="link"
          className="dark:hover:text-white/80 hover:text-black/70 absolute top-2 right-2 dark:text-white "
          onClick={onClose}
        >
          <XIcon />
        </Button>

        <DialogTitle></DialogTitle>
        <TypographyH4 className="font-bold mb-3">Order Review</TypographyH4>

        <NetworkLabel network={network} className="mb-3" />

        <StopMarketOrderOpenPositionPreview
          amountOfOpenOrderTokenInToBeApprovedForOrders={amountOfOpenOrderTokenInToBeApprovedForOrders}
          approveOpenOrderTokenError={approveOpenOrderTokenError}
          areStopMarketOrdersTokensAllowanceLoading={areStopMarketOrdersTokensAllowanceLoading}
          isOpenOrderTokenApproving={isOpenOrderTokenApproving}
          isStopMarketOrderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
          isTokenInApproved={isOpenOrderTokenTotalAmountApproved}
          openOrder={openOrder}
          openOrderQuoteEstimation={stopMarketOrderQuoteEstimation?.openOrderQuoteEstimation || null}
          stopMarketOrdersTokensAllowanceError={stopMarketOrdersTokensAllowanceError?.message}
          stopMarketOrderQuoteEstimationError={stopMarketOrderQuoteEstimationError}
          isOpenOrderTokenInAllowanceLoading={isOpenOrderTokenInAllowanceLoading}
          openOrderTokenInAllowanceError={openOrderTokenInAllowanceError?.message}
          isOpenOrderSigned={!!signedOpenOrderSignature}
          isOpenOrderSigning={isOpenOrderSigning}
          approveOpenOrderTokenHandler={approveOpenOrderTokenHandler}
          refetchStopMarketOrdersTokensAllowance={refetchStopMarketOrdersTokensAllowance}
          refetchStopMarketOrderQuoteEstimation={refetchStopMarketOrderQuoteEstimation}
          refetchOpenOrderTokenInAllowance={refetchOpenOrderTokenInAllowance}
        />

        {closeOrder ? (
          <div className="mt-2">
            <StopMarketOrderClosePositionPreview
              openOrder={openOrder}
              closeOrder={closeOrder}
              approveCloseOrderTokenError={approveCloseOrderTokenError}
              isCloseOrderTokenTotalAmountApproved={isCloseOrderTokenTotalAmountApproved}
              isCloseOrderTokenApproving={isCloseOrderTokenApproving}
              areStopMarketOrdersTokensAllowanceLoading={areStopMarketOrdersTokensAllowanceLoading}
              amountOfCloseOrderTokenInToBeApprovedForOrders={amountOfCloseOrderTokenInToBeApprovedForOrders}
              stopMarketOrdersTokensAllowanceError={stopMarketOrdersTokensAllowanceError?.message}
              isOpenOrderTokenTotalAmountApproved={isOpenOrderTokenTotalAmountApproved}
              isStopMarketOrderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
              closeOrderQuoteEstimation={stopMarketOrderQuoteEstimation?.closeOrderQuoteEstimation || null}
              approveOpenOrderMessage={
                isOpenOrderTokenTotalAmountApproved ? '' : `approve ${openOrder.tokenIn.symbol} first to get estimation`
              }
              stopMarketOrderQuoteEstimationError={stopMarketOrderQuoteEstimationError}
              closeOrderTokenInAllowanceError={closeOrderTokenInAllowanceError?.message}
              isCloseOrderTokenInAllowanceLoading={isCloseOrderTokenInAllowanceLoading}
              isCloseOrderSigned={!!signedCloseOrderSignature}
              isCloseOrderSigning={isCloseOrderSigning}
              refetchCloseOrderTokenInAllowance={refetchCloseOrderTokenInAllowance}
              approveCloseOrderTokenHandler={approveCloseOrderTokenHandler}
            />
          </div>
        ) : null}

        <StopMarketOrderPreviewModalAlertLabel wrapperClassName="mt-4" />

        <DialogFooter className="flex !justify-between mt-4 !flex-col">
          {!isOpenOrderPreparedToSubmit ? (
            <StopMarketOrderPreviewModalOpenOrderActions
              amountOfOpenOrderTokenInToBeApprovedForOrders={amountOfOpenOrderTokenInToBeApprovedForOrders}
              showInsufficientTokenInBalanceError={showInsufficientTokenInBalanceError}
              openOrder={openOrder}
              isOpenOrderTokenTotalAmountApproved={isOpenOrderTokenTotalAmountApproved}
              areStopMarketOrdersTokensAllowanceLoading={areStopMarketOrdersTokensAllowanceLoading}
              isOpenOrderTokenApproving={isOpenOrderTokenApproving}
              isStopMarketOrderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
              approveOpenOrderTokenHandler={approveOpenOrderTokenHandler}
              signOpenOrderHandler={signOpenOrderHandler}
              isOpenOrderSigning={isOpenOrderSigning}
              isOpenOrderTokenInAllowanceLoading={isOpenOrderTokenInAllowanceLoading}
            />
          ) : !isCloseOrderPreparedToSubmit && closeOrder ? (
            <StopMarketOrderPreviewModalCloseOrderActions
              isCloseOrderTokenTotalAmountApproved={isCloseOrderTokenTotalAmountApproved}
              closeOrder={{
                ...closeOrder,
                tokenIn: openOrder.tokenOut,
                amountIn: stopMarketOrderQuoteEstimation.openOrderQuoteEstimation.minAmountOut,
              }}
              areStopMarketOrdersTokensAllowanceLoading={areStopMarketOrdersTokensAllowanceLoading}
              isCloseOrderTokenApproving={isCloseOrderTokenApproving}
              isStopMarketOrderQuoteEstimationLoading={isStopMarketOrderQuoteEstimationLoading}
              approveCloseOrderTokenHandler={approveCloseOrderTokenHandler}
              signCloseOrderHandler={signCloseOrderHandler}
              isCloseOrderSigning={isCloseOrderSigning}
            />
          ) : (
            <Button
              className="bg-blue-500 hover:bg-blue-600  dark:bg-primary-gray dark:hover:bg-primary-gray/80 w-full text-white py-5 px-4 relative rounded-lg text-sm font-medium"
              onClick={createStopMarketOrderHandler}
              disabled={
                isCreateStopMarketOrderPending ||
                !isOpenOrderPreparedToSubmit ||
                (!!closeOrder && !isCloseOrderPreparedToSubmit)
              }
              loading={isCreateStopMarketOrderPending}
            >
              Confirm and place the {closeOrder ? 'orders' : 'order'}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
