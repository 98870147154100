export const UserAgreementPage = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg md:rounded-lg">
      <h3 className="text-xl font-bold text-center mb-4 dark:text-black/80">User Agreement & Privacy Policy</h3>
      <p className="text-center text-gray-600">Effective Date: 02.02.2025</p>

      <h4 className="text-lg font-semibold mt-6 mb-2 dark:text-black/80">1. Introduction</h4>
      <p className="text-gray-700">
        Welcome to DEXStandard. By using our platform, you acknowledge and agree to the terms outlined in this User
        Agreement and Privacy Policy. Our service functions as an interface for interacting with smart contracts and
        does not offer financial advice or custodial services.
      </p>

      <h4 className="text-lg font-semibold mt-6 mb-2 dark:text-black/80">2. Data Collection & Privacy</h4>
      <p className="text-gray-700 mb-2">
        We respect your privacy and keep data collection to a minimum. The only information we collect is:
      </p>
      <ul className="list-disc list-inside text-gray-700">
        <li>Your wallet address</li>
        <li>Necessary metadata required for smart contract interactions</li>
      </ul>
      <p className="text-gray-700 mt-2">
        We do not store personal data beyond what is essential for blockchain interactions. We do not share or sell any
        user data.
      </p>

      <h4 className="text-lg font-semibold mt-6 mb-2 dark:text-black/80">3. Beta Software Disclaimer</h4>
      <p className="text-gray-700 mb-2">
        Our platform is currently in beta. While we strive for reliability, you acknowledge that:
      </p>
      <ul className="list-disc list-inside text-gray-700">
        <li>The software is provided &#34;as is&#34; without warranties of any kind.</li>
        <li>There may be bugs, errors, or vulnerabilities.</li>
        <li>You use the platform at your own risk.</li>
      </ul>

      <h4 className="text-lg font-semibold mt-6 mb-2 dark:text-black/80">4. User Responsibility</h4>
      <p className="text-gray-700 mb-2">By using DEXStandard, you acknowledge that:</p>
      <ul className="list-disc list-inside text-gray-700">
        <li>You take full responsibility for all transactions and interactions with smart contracts.</li>
        <li>You understand the risks of decentralized finance (DeFi) and smart contract execution.</li>
        <li>DEXStandard is not responsible for any financial losses, errors, or third-party failures.</li>
      </ul>

      <h4 className="text-lg font-semibold mt-6 mb-2 dark:text-black/80">5. Tax Compliance</h4>
      <p className="text-gray-700">
        You are solely responsible for complying with your local tax laws. DEXStandard does not provide tax reporting,
        calculation, or legal guidance.
      </p>

      <h4 className="text-lg font-semibold mt-6 mb-2 dark:text-black/80">6. Changes to This Agreement</h4>
      <p className="text-gray-700">
        We may update this agreement periodically. Continued use of the platform constitutes acceptance of any revised
        terms.
      </p>

      <p className="mt-6 font-bold text-gray-800 text-center">
        By using our platform, you agree to these terms and conditions.
      </p>
    </div>
  );
};
