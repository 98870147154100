import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ChevronDown, ChevronUp, TentIcon } from 'lucide-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector, useBreakpoints, useGetUserStopMarketOrders } from '../../../hooks';
import { SortOptionEnum, StopMarketOrderInfo } from '../../../interfaces';
import { cn } from '../../../utils';
import { SpinnerIcon } from '../../icons/spinner-icon';
import { TypographyP } from '../../ui/typography';
import { AmountColumn } from './columns/amount-column';
import { AmountOutColumn } from './columns/amount-out-column';
import { ChainColumn } from './columns/chain-column';
import { CreatedAtColumn } from './columns/created-at-column';
import { MobileOrderInfoColumn } from './columns/mobile-order-info-column';
import { OrderActionColumn } from './columns/order-action-column';
import { OrderColumn } from './columns/order-column';
import { OrderStatusColumn } from './columns/order-status-column';
import { TargetPriceColumn } from './columns/target-price-column';
import { ChainFilter } from './filters/chain-filter';
import { MobileOrdersTableFilter } from './filters/mobile-filter';
import { TokenPairFilter } from './filters/token-pair-filter';
import { OrderListTabEnum, OrderTableFilterType } from './order-list';

type OrderTableProps = {
  type: OrderListTabEnum;
  activeFilters: OrderTableFilterType;
  setActiveFilters: React.Dispatch<
    React.SetStateAction<{
      active: OrderTableFilterType;
      history: OrderTableFilterType;
    }>
  >;
};

const PAGE_SIZE = 10;

export const OrderTable: FC<OrderTableProps> = ({ type, activeFilters, setActiveFilters }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const { isMobile } = useBreakpoints();

  const [sorting, setSorting] = useState<{
    createdAt: SortOptionEnum;
  }>({
    createdAt: SortOptionEnum.DESC,
  });

  const userOrdersFilter = useMemo(() => {
    const chainId = activeFilters.chainId;

    return {
      onlyActive: type === OrderListTabEnum.ACTIVE,
      onlyInactive: type === OrderListTabEnum.HISTORY,
      chainId,
      tokenInId: activeFilters.tokenInId,
      tokenOutId: activeFilters.tokenOutId,
      closeTokenInId: activeFilters.closeTokenInId,
      closeTokenOutId: activeFilters.closeTokenOutId,
    };
  }, [
    type,
    activeFilters.closeTokenInId,
    activeFilters.closeTokenOutId,
    activeFilters.tokenInId,
    activeFilters.tokenOutId,
    activeFilters.chainId,
  ]);

  const { fetchNextPage, orders, lastOrders, areUserStopMarketOrdersLoading } = useGetUserStopMarketOrders(
    {
      page: 1,
      size: PAGE_SIZE,
      filter: userOrdersFilter,
      sort: {
        createdAt: sorting.createdAt,
      },
    },
    isAuthenticated,
  );

  const columns: ColumnDef<StopMarketOrderInfo, StopMarketOrderInfo>[] = useMemo(
    () =>
      isMobile
        ? [
            {
              accessorKey: 'orderInfo',
              enableSorting: false,
              header: 'Order info',
              id: 'orderInfo',
              cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => (
                <MobileOrderInfoColumn orderInfo={row.original} activeOrder={type === 'active'} />
              ),
            },
          ]
        : [
            {
              accessorKey: 'chainId',
              enableSorting: false,
              header: 'Chain',
              id: 'chainId',
              cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => (
                <ChainColumn chainId={row.original.openOrder.chainId} orderId={row.original.openOrder.id} />
              ),
            },
            {
              accessorKey: 'order',
              enableSorting: false,
              header: 'Order',
              id: 'order',
              cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => <OrderColumn order={row.original} />,
            },

            {
              accessorKey: 'targetPrice',
              enableSorting: false,
              header: 'Target price',
              cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => <TargetPriceColumn order={row.original} />,
            },
            {
              accessorKey: 'inputAmount',
              enableSorting: false,
              header: 'Input amount',
              cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => <AmountColumn order={row.original} />,
            },
            {
              accessorKey: 'outputAmount',
              enableSorting: false,
              header: 'Output amount',
              cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => <AmountOutColumn order={row.original} />,
            },
            {
              accessorKey: 'status',
              enableSorting: false,
              header: 'Status',
              cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => <OrderStatusColumn order={row.original} />,
            },
            {
              accessorKey: 'createdAt',
              header: 'Date',
              enableSorting: true,
              sortDescFirst: true,
              cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => (
                <CreatedAtColumn createdAt={row.original.openOrder.createdAt} />
              ),
            },
            {
              enableSorting: false,
              header: 'Actions',
              id: 'actions',
              cell: ({ row }: { row: { original: StopMarketOrderInfo } }) => (
                <OrderActionColumn activeOrder={type === 'active'} order={row.original} />
              ),
            },
          ],
    [type, isMobile, orders],
  );

  const table = useReactTable({
    data: orders,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    manualSorting: true,
  });

  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (
          scrollHeight - scrollTop - clientHeight < 250 &&
          !areUserStopMarketOrdersLoading &&
          lastOrders.length > 0 &&
          lastOrders.length % PAGE_SIZE === 0
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, lastOrders, orders, areUserStopMarketOrdersLoading],
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const { rows } = table.getRowModel();

  const showNoOrdersFound = (rows.length === 0 && !areUserStopMarketOrdersLoading) || !isAuthenticated;

  return (
    <div
      onScroll={(e) => fetchMoreOnBottomReached(e.currentTarget)}
      ref={tableContainerRef}
      className={cn('h-[300px]  overflow-auto relative border-t ', {
        'h-[150px] overflow-hidden': showNoOrdersFound || (areUserStopMarketOrdersLoading && rows.length === 0),
      })}
    >
      {areUserStopMarketOrdersLoading && isAuthenticated ? (
        <div className="w-full h-full absolute top-6 flex items-center justify-center  backdrop-blur-sm z-10">
          <SpinnerIcon />
        </div>
      ) : null}

      {showNoOrdersFound ? (
        <div className="flex absolute top-6 h-full items-center w-full justify-center space-x-2">
          <TentIcon className="w-4 h-4 dark:text-white/50 text-black/50" />

          <TypographyP className="text-sm dark:text-white/50 text-black/50">No orders were found...</TypographyP>
        </div>
      ) : null}
      <table className="w-full relative text-left">
        <thead className="sticky top-0 z-10 dark:bg-primary-black bg-white">
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const canSort = header.column.getCanSort();

                  if (canSort) {
                    return (
                      <th
                        onClick={() =>
                          !areUserStopMarketOrdersLoading &&
                          setSorting({
                            createdAt:
                              sorting.createdAt === SortOptionEnum.DESC ? SortOptionEnum.ASC : SortOptionEnum.DESC,
                          })
                        }
                        key={header.id}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                            : undefined
                        }
                        className="p-0 text-center text-xs"
                      >
                        <div className="border border-t-0 border-l-0 w-full flex items-center justify-center  p-2 py-1 h-9">
                          {flexRender(header.column.columnDef.header, header.getContext())}

                          {sorting.createdAt === SortOptionEnum.DESC ? (
                            <ChevronDown className="w-4 h-4" />
                          ) : (
                            <ChevronUp className="w-4 h-4" />
                          )}
                        </div>
                      </th>
                    );
                  }

                  if (header.id === 'orderInfo') {
                    return (
                      <th key={header.id} className="p-0 text-center text-xs">
                        <div className="border border-t-0 w-full flex items-center justify-center  p-2 py-1 h-9">
                          {flexRender(header.column.columnDef.header, header.getContext())}

                          <div className="absolute right-2">
                            <MobileOrdersTableFilter
                              activeFilter={activeFilters}
                              onClearFilterClick={() => {
                                setActiveFilters((prev) => ({
                                  ...prev,
                                  active: {
                                    chainId: undefined,
                                    closeTokenInId: undefined,
                                    closeTokenOutId: undefined,
                                    tokenInId: undefined,
                                    tokenOutId: undefined,
                                  },
                                }));
                              }}
                              onApplyFilterClick={(filters) => {
                                setActiveFilters((prev) => ({
                                  ...prev,
                                  active: filters,
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </th>
                    );
                  }

                  if (header.id === 'chainId') {
                    return (
                      <th key={header.id} className="p-0 text-center text-xs">
                        <div className="border border-t-0 w-full flex items-center justify-center  p-2 py-1 h-9">
                          {flexRender(header.column.columnDef.header, header.getContext())}

                          <ChainFilter
                            activeFilter={activeFilters.chainId}
                            onClearFilterClick={() => {
                              setActiveFilters((prev) => ({
                                ...prev,
                                active: {
                                  ...prev.active,
                                  chainId: undefined,
                                },
                              }));
                            }}
                            onApplyFilterClick={(chainId) => {
                              setActiveFilters((prev) => ({
                                ...prev,
                                active: {
                                  ...prev.active,
                                  chainId,
                                },
                              }));
                            }}
                          />
                        </div>
                      </th>
                    );
                  }

                  if (header.id === 'order') {
                    return (
                      <th key={header.id} className="p-0 text-center text-xs">
                        <div className="border border-t-0 border-l-0 w-full flex items-center justify-center  p-2 py-1 h-9">
                          {flexRender(header.column.columnDef.header, header.getContext())}

                          <TokenPairFilter
                            activeFilter={{
                              tokenInId: activeFilters.tokenInId,
                              tokenOutId: activeFilters.tokenOutId,
                              chainId: activeFilters.chainId,
                            }}
                            onClearFilterClick={() => {
                              setActiveFilters((prev) => ({
                                ...prev,
                                active: {
                                  ...prev.active,
                                  tokenInId: undefined,
                                  tokenOutId: undefined,
                                  closeTokenInId: undefined,
                                  closeTokenOutId: undefined,
                                },
                              }));
                            }}
                            onApplyFilterClick={(params) => {
                              setActiveFilters((prev) => ({
                                ...prev,
                                active: {
                                  ...prev.active,
                                  ...params,
                                },
                              }));
                            }}
                          />
                        </div>
                      </th>
                    );
                  }

                  return (
                    <th key={header.id} className="p-0 text-center text-xs">
                      <div className="border border-t-0 border-l-0 w-full flex items-center justify-center   py-1 h-9">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody className="relative h-full ">
          {isAuthenticated
            ? rows.map((row, i) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={cn(`md:px-4 md:py-2 px-2 py-1 border text-xs`, {
                        'border-t-0': i === 0,
                      })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            : null}
        </tbody>
      </table>

      {areUserStopMarketOrdersLoading ? (
        <div className="my-2 flex items-center justify-center ">
          <SpinnerIcon className="w-3 h-3" />
        </div>
      ) : null}
    </div>
  );
};
