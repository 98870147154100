import { UnknownAction } from '@reduxjs/toolkit';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Dispatch, useMemo } from 'react';
import { APIConfig } from '../../configs';
import { OHLCIntervalEnum, OhlcPeriod, OHLCTOkenPairResponse } from '../../interfaces';
import { clearPairOHLCInfo, setIsPairOHLCLoading, setPairOHLCInfo } from '../../store';
import { formatOHLCToUnits } from '../../utils';
import { useAppDispatch } from '../store';

export type FetchOHLCArgs = {
  chainId: number;
  tokenInAddress: string;
  tokenOutAddress: string;
  interval: OHLCIntervalEnum;
  enabled?: boolean;
  from?: number;
  to?: number;
  limit?: number;
  refetchInterval?: number;
  setLastOHLCInfo: React.Dispatch<React.SetStateAction<OhlcPeriod | null>>;
};

const fetchOHLC = async (
  { chainId, tokenInAddress, tokenOutAddress, interval, from, to, limit, setLastOHLCInfo }: FetchOHLCArgs,
  dispatch: Dispatch<UnknownAction>,
): Promise<OHLCTOkenPairResponse> => {
  const queryParams = new URLSearchParams();

  queryParams.append('interval', interval);

  if (from) {
    queryParams.append('from', from.toString());
  }

  if (to) {
    queryParams.append('to', to.toString());
  }

  if (limit) {
    queryParams.append('limit', limit.toString());
  }

  const queryParamsString = queryParams.toString();
  dispatch(setIsPairOHLCLoading(true));
  return axios
    .get<OHLCTOkenPairResponse>(
      `${APIConfig.baseURL}/ds/${chainId}/ohlc/${tokenInAddress}/${tokenOutAddress}?${queryParamsString}`,
    )
    .then((response) => {
      const data = response.data;
      const ohlcPeriod = [...data.ohlcPeriod].sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

      dispatch(
        setPairOHLCInfo({
          ...data,
          ohlcPeriod: ohlcPeriod.map((ohlc) => formatOHLCToUnits(chainId!, tokenOutAddress!, ohlc)),
          oldestTime: data.oldestTime,
        }),
      );

      setLastOHLCInfo(ohlcPeriod[ohlcPeriod.length - 1]);

      return data;
    })
    .catch((e) => {
      dispatch(
        clearPairOHLCInfo({
          tokenInAddress: tokenInAddress!,
          tokenOutAddress: tokenOutAddress!,
          chainId: chainId!,
        }),
      );

      return e;
    });
};

export const useGetPairOHLCInfo = (args: FetchOHLCArgs | null) => {
  const dispatch = useAppDispatch();

  const { data, isRefetching, refetch, isLoading, isPending, error } = useQuery({
    queryFn: () => fetchOHLC(args!, dispatch),
    queryKey: ['ohlc', JSON.stringify(args)],
    enabled: args?.enabled,
    refetchInterval: args?.refetchInterval || false,
  });

  const ohlcPeriod = useMemo(
    () =>
      data?.ohlcPeriod && args
        ? [...data.ohlcPeriod]
            .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
            .map((p) => formatOHLCToUnits(args.chainId, args.tokenOutAddress, p))
        : [],

    [data?.ohlcPeriod, args?.chainId, args?.tokenOutAddress],
  );

  const pairOHLCInfo = data?.pair
    ? {
        ...data,
        ohlcPeriod,
        oldestTime: new Date(data.oldestTime),
      }
    : null;

  return {
    pairOHLCInfoError: error,
    isPairOHLCInfoRefetching: isRefetching,
    isPairOHLCInfoLoading: isLoading || isPending,
    pairOHLCInfo,
    refetchPairOHLCInfo: refetch,
  };
};
