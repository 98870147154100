import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OhlcPeriod, Token } from '../../interfaces';
import { getOHLCPairStoreKey } from '../../utils';

type PairOHLC = {
  pair: string;
  chainId: number;
  route: Token[];
  ohlcPeriod: OhlcPeriod[];
  oldestTime: string;
};

export interface PairOHLCSlice {
  pairsInfo: Map<string, PairOHLC>;
  isPairOHLCLoading: boolean;
}

const initialState: PairOHLCSlice = {
  pairsInfo: new Map(),
  isPairOHLCLoading: false,
};

export const pairsOHLCSlice = createSlice({
  name: 'pairs-OHLC',
  initialState,
  reducers: {
    setIsPairOHLCLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isPairOHLCLoading = payload;
    },
    setPairOHLCInfo: (state, { payload }: PayloadAction<PairOHLC>) => {
      state.isPairOHLCLoading = false;

      const key = getOHLCPairStoreKey(payload.route[0].address, payload.route.at(-1)?.address || '', payload.chainId);

      if (!state.pairsInfo.has(key)) {
        state.pairsInfo.set(key, payload);
      } else {
        const currentState = state.pairsInfo.get(key)!;

        const updatedState: PairOHLC = {
          pair: payload.pair,
          chainId: payload.chainId,
          route: payload.route,
          ohlcPeriod: [...payload.ohlcPeriod, ...currentState.ohlcPeriod]
            .reduce<OhlcPeriod[]>((acc, val) => {
              const exists = acc.find((p) => p.date === val.date);

              if (!exists) {
                acc.push(val);
              }

              return acc;
            }, [])
            .sort((a, b) => Date.parse(a.date) - Date.parse(b.date)),
          oldestTime: currentState.oldestTime < payload.oldestTime ? currentState.oldestTime : payload.oldestTime,
        };

        state.pairsInfo.set(key, updatedState);
      }
    },

    clearPairOHLCInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        tokenInAddress: string;
        tokenOutAddress: string;
        chainId: number;
      }>,
    ) => {
      const key = getOHLCPairStoreKey(payload.tokenInAddress, payload.tokenOutAddress, payload.chainId);

      state.pairsInfo.delete(key);
      state.isPairOHLCLoading = false;
    },
  },
});

export const { setPairOHLCInfo, clearPairOHLCInfo, setIsPairOHLCLoading } = pairsOHLCSlice.actions;

export const pairsOHLCSliceReducer = pairsOHLCSlice.reducer;
