import { FC } from 'react';
import { OrderDurationEnum } from '../../../../interfaces';
import { Tabs, TabsList, TabsTrigger } from '../../../ui/tabs';
import { OrderListTabEnum } from '../../list/order-list';

const ORDER_DURATION_OPTIONS = [
  {
    value: OrderDurationEnum.DAY,
    label: '1 day',
  },
  {
    value: OrderDurationEnum.WEEK,
    label: '1 week',
  },
  {
    value: OrderDurationEnum.MONTH,
    label: '1 month',
  },
  {
    value: OrderDurationEnum.THREE_MONTHS,
    label: '3 months',
  },
];

type StopMarketOrderDurationSelectorProps = {
  setOrderDuration: React.Dispatch<React.SetStateAction<OrderDurationEnum>>;
  orderDuration: OrderDurationEnum;
};

export const StopMarketOrderDurationSelector: FC<StopMarketOrderDurationSelectorProps> = ({
  setOrderDuration,
  orderDuration,
}) => {
  return (
    <div>
      <Tabs
        value={orderDuration}
        onValueChange={(option) => setOrderDuration(option as OrderDurationEnum)}
        defaultValue={OrderListTabEnum.ACTIVE}
        className="w-full"
      >
        <div className="flex items-center justify-between space-x-1 xl:space-x-2">
          <TabsList className="w-fit flex items-center bg-transparent !px-0">
            {ORDER_DURATION_OPTIONS.map((o) => (
              <TabsTrigger
                key={o.value}
                className="dark:data-[state=active]:bg-primary-light-gray xl:px-3 px-1.5 lg:px-2 data-[state=active]:text-black dark:data-[state=active]:text-white dark:text-white/70 text-black/70 text-xs"
                value={o.value}
              >
                {o.label}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
      </Tabs>
    </div>
  );
};
