import { RainbowKitProvider, cssStringFromTheme, darkTheme, lightTheme } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isAxiosError } from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router';
import { WagmiProvider } from 'wagmi';
import { App } from './App';
import { Toaster } from './components';
import { wagmiConfig } from './configs';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        if (failureCount === 3) {
          return false;
        }

        if (isAxiosError(error)) {
          if (error.status === 401) {
            return false;
          }
        }

        return true;
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={null}>
          <style
            dangerouslySetInnerHTML={{
              __html: `
            :root {
              ${cssStringFromTheme(lightTheme)}
            }

            .dark {
              ${cssStringFromTheme(darkTheme, {
                extends: lightTheme,
              })}
            }
          `,
            }}
          />
          <BrowserRouter>
            <Provider store={store}>
              <App />
              <Toaster />
            </Provider>
          </BrowserRouter>
        </RainbowKitProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
);

reportWebVitals();
