import { MoonIcon, SettingsIcon, SunIcon } from 'lucide-react';
import { ThemeEnum, useTheme } from '../../hooks';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { Tabs, TabsList, TabsTrigger } from '../ui/tabs';
import { TypographyP } from '../ui/typography';

export const SettingsWidget = () => {
  const { toggleTheme, theme } = useTheme();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <SettingsIcon className="w-4 h-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-fit mt-3 p-2 bg-primary-dark-blue/0 backdrop-blur-md">
        <div className="flex items-center">
          <TypographyP className="text-xs font-medium dark:font-semibold">Theme</TypographyP>
          <Tabs
            onValueChange={(option) => toggleTheme(option as ThemeEnum)}
            defaultValue={theme}
            className="w-fit ml-2"
          >
            <TabsList className="w-full flex items-center p-0 py-0 h-fit">
              <TabsTrigger className="text-xs px-2 py-1" value={ThemeEnum.LIGHT}>
                Light
                <SunIcon className="w-3 h-3 ml-1" />
              </TabsTrigger>
              <TabsTrigger className="text-xs" value={ThemeEnum.DARK}>
                Dark <MoonIcon className="w-3 h-3 ml-1" />
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
