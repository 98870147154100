import { ArrowRight, TrashIcon } from 'lucide-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { useGetUSDTokenPrice, ValidateCloseOrderPriceConditionParams } from '../../../../hooks';
import { FormLimitCloseOrderSchema, PriceConditionTypeEnum, Token } from '../../../../interfaces';
import {
  getCloseOrderHeaderLabel,
  getPricePrecisionFromPriceCondition,
  getTokenFromPriceConditionType,
} from '../../../../utils';
import { TokenLabel } from '../../../custom/token-label';
import { Button } from '../../../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { TypographyH4, TypographyP } from '../../../ui/typography';
import { PriceConditionInput } from '../../order/price-condition-input';
import { StopMarketOrderPricePercentageSelector } from '../components/stop-market-order-price-percentage-selector';

type StopMarketCloseOrderFormProps = {
  chainId: number;
  orderTokenIn: Token;
  orderTokenOut: Token;
  priceOverflowError: boolean;
  priceDifferenceWarning: {
    type: 'high' | 'low';
    delta: number;
  } | null;
  orderPricePercentage: number;
  orderPriceConditionPriceInput: string;
  initialPrice: string;
  validateOrderPrice: (params: ValidateCloseOrderPriceConditionParams) => void;
  handleRemoveCloseOrder: () => void;
  handleCloseOrderPricePercentageChange: (percentage: number) => void;
  handleCloseOrderPriceInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const StopMarketCloseOrderForm: FC<StopMarketCloseOrderFormProps> = ({
  orderTokenIn,
  orderTokenOut,
  chainId,
  priceOverflowError,
  priceDifferenceWarning,
  orderPricePercentage,
  orderPriceConditionPriceInput,
  initialPrice,
  validateOrderPrice,
  handleRemoveCloseOrder,
  handleCloseOrderPricePercentageChange,
  handleCloseOrderPriceInputChange,
}) => {
  const [showCancelPopover, setShowCancelPopover] = useState(false);

  const formCloseOrder = useFormContext<z.infer<typeof FormLimitCloseOrderSchema>>();

  const closeOrder = formCloseOrder.watch('order');

  const orderPriceConditionType = closeOrder.priceCondition.conditionType;
  const orderPriceConditionPrice = closeOrder.priceCondition.value;

  const closeOrderPricePrecision = useMemo(() => {
    return getPricePrecisionFromPriceCondition(orderPriceConditionType, orderTokenIn, orderTokenOut);
  }, [orderPriceConditionType, orderTokenIn.decimals, orderTokenOut.decimals]);

  const [tokenInUsdPrice, tokenOutUsdPrice] = useGetUSDTokenPrice([
    {
      oracleAddress: orderTokenIn.usdPriceOracle,
      chainId,
    },
    {
      oracleAddress: orderTokenOut.usdPriceOracle,
      chainId,
    },
  ]);

  const orderPriceConditionValidationError =
    formCloseOrder.formState.errors.order?.priceCondition?.value?.message || '';

  useEffect(() => {
    if (initialPrice && orderPriceConditionPrice) {
      validateOrderPrice({
        price: orderPriceConditionPrice,
        priceCondition: orderPriceConditionType,
        openOrderPrice: initialPrice,
        orderTokenInUsdPriceOracle: orderTokenIn.usdPriceOracle,
        orderTokenOutUsdPriceOracle: orderTokenOut.usdPriceOracle,
        tokenInUsdPrice,
        tokenOutUsdPrice,
        orderPricePrecision: closeOrderPricePrecision,
      });
    }
  }, [
    initialPrice,
    tokenInUsdPrice,
    closeOrderPricePrecision,
    tokenOutUsdPrice,
    orderPriceConditionType,
    orderPriceConditionPrice,
  ]);

  return (
    <div className="dark:bg-primary-light-gray/20 shadow-md dark:shadow-none p-3 relative rounded-lg">
      <Popover open={showCancelPopover} onOpenChange={(open) => setShowCancelPopover(open)}>
        <PopoverTrigger asChild>
          <Button
            onClick={() => setShowCancelPopover(true)}
            variant="ghost"
            className="p-1 h-fit absolute right-2 top-2"
            aria-label="Remove close Order"
          >
            <TrashIcon />
          </Button>
        </PopoverTrigger>
        <PopoverContent side="left" className="flex items-center flex-col gap-2 p-2.5 max-w-[170px]">
          <TypographyP className="text-center text-sm">Remove close order?</TypographyP>
          <Button
            type="button"
            variant="ghost"
            className="relative bg-red-600 hover:bg-red-500 hover:text-white text-white"
            onClick={() => {
              handleRemoveCloseOrder();
            }}
            aria-label="Cancel Order"
          >
            Remove
          </Button>
        </PopoverContent>
      </Popover>

      <div className="mb-2 flex gap-2 items-center">
        <TypographyH4 className="!text-sm ">Close position</TypographyH4>

        <div className="flex items-center gap-0.5">
          <TokenLabel token={orderTokenIn} showLogo={false} textClassName="text-[10px]" />
          <ArrowRight className="w-2.5 h-2.5" />
          <TokenLabel token={orderTokenOut} showLogo={false} textClassName="text-[10px]" />
        </div>
      </div>

      <div className="flex flex-col w-full lg:mt-3 mt-2">
        <div className="text-start text-xs font-medium mb-1 flex items-center">
          {getCloseOrderHeaderLabel(orderTokenIn.symbol, orderTokenOut.symbol, orderPriceConditionType)}
        </div>

        <div className="flex  lg:flex-row flex-col lg:gap-2 gap-1 w-full">
          <div className="flex flex-col w-full items-end">
            <PriceConditionInput
              open={!!orderPriceConditionValidationError || !!priceDifferenceWarning || priceOverflowError}
              inputValue={orderPriceConditionPriceInput}
              onChange={handleCloseOrderPriceInputChange}
              disabled={false}
              priceConditionValidationError={orderPriceConditionValidationError}
              priceDifferenceWarning={priceDifferenceWarning}
              token={getTokenFromPriceConditionType(orderTokenIn, orderTokenOut, orderPriceConditionType)}
              priceOverflowError={priceOverflowError ? 'Price is below sell' : ''}
            />
            <div className="lg:block hidden">
              <StopMarketOrderPricePercentageSelector
                orderPricePercentage={orderPricePercentage}
                setOrderPricePercentage={handleCloseOrderPricePercentageChange}
                initialPrice={closeOrder.priceCondition.initialValue}
                negative={closeOrder.priceCondition.conditionType === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN}
              />
            </div>
          </div>

          <div className="flex items-center lg:items-start justify-end gap-2">
            <div className="lg:hidden block">
              <StopMarketOrderPricePercentageSelector
                orderPricePercentage={orderPricePercentage}
                setOrderPricePercentage={handleCloseOrderPricePercentageChange}
                initialPrice={closeOrder.priceCondition.initialValue}
                negative={closeOrder.priceCondition.conditionType === PriceConditionTypeEnum.TOKEN_OUT_TOKEN_IN}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
