import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ethers } from 'ethers';
import { CheckCircle2Icon, CircleHelpIcon, XCircleIcon } from 'lucide-react';
import { FC } from 'react';
import { Token, UserStopMarketOrdersTokensAllowance } from '../../../../interfaces';
import { formatAmount } from '../../../../utils';
import { TokenLabel } from '../../../custom/token-label';
import { SpinnerIcon } from '../../../icons/spinner-icon';
import { Button } from '../../../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { TypographyP, TypographyS } from '../../../ui/typography';

type StopMarketOpenOrderTokenApprovalStateProps = {
  tokenIn: Token;
  isTokenApproving: boolean;
  isTokenApproved: boolean;
  approveTokenError: string;
  areStopMarketOrdersTokensAllowanceLoading: boolean;
  amountOfTokenToBeApproved: string | undefined;
  isOpenOrderTokenInAllowanceLoading: boolean;
  openOrderTokenInAllowanceError: string | undefined;
  refetchOpenOrderTokenInAllowance: (options?: RefetchOptions) => Promise<QueryObserverResult<string, Error>>;
  onTryApproveAgainClick: () => Promise<void>;
  refetchStopMarketOrdersTokensAllowance: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<AxiosResponse<UserStopMarketOrdersTokensAllowance>, Error>>;
  stopMarketOrdersTokensAllowanceError: string | undefined;
};

export const StopMarketOpenOrderTokenApprovalState: FC<StopMarketOpenOrderTokenApprovalStateProps> = ({
  tokenIn,
  approveTokenError,
  isTokenApproved,
  isTokenApproving,
  areStopMarketOrdersTokensAllowanceLoading,
  amountOfTokenToBeApproved,
  stopMarketOrdersTokensAllowanceError,
  isOpenOrderTokenInAllowanceLoading,
  openOrderTokenInAllowanceError,
  refetchOpenOrderTokenInAllowance,
  onTryApproveAgainClick,
  refetchStopMarketOrdersTokensAllowance,
}) => {
  return (
    <div className="flex md:items-center gap-1 md:flex-row flex-col">
      <TokenLabel imgClassName="w-3.5 h-3.5" token={tokenIn} textClassName="font-semibold" />
      <div className="flex items-center gap-1 md:pl-0 pl-2">
        {areStopMarketOrdersTokensAllowanceLoading || isOpenOrderTokenInAllowanceLoading ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">checking approved allowance</TypographyS>
            <SpinnerIcon className=" w-3 h-3" />
          </>
        ) : stopMarketOrdersTokensAllowanceError ? (
          <>
            <Popover>
              <PopoverTrigger className="w-fit  group flex items-center text-[11px] justify-center">
                <TypographyS className="text-xs flex items-center dark:text-white/70 text-black/70 underline ">
                  allowance error
                  <XCircleIcon className="text-red-500 w-3.5 h-3.5 ml-1" />
                </TypographyS>
              </PopoverTrigger>
              <PopoverContent
                align="center"
                side="right"
                className="bg-white/30 shadow space-y-1 dark:bg-primary-black/30 max-w-[240px] w-fit backdrop-blur-lg p-2"
              >
                <TypographyS className="text-xs dark:text-white/70 text-black/70  truncate">
                  {stopMarketOrdersTokensAllowanceError}
                </TypographyS>
              </PopoverContent>
            </Popover>
            <Button
              disabled={isTokenApproving}
              onClick={async () => await refetchStopMarketOrdersTokensAllowance()}
              variant="ghost"
              className=" text-[10px] h-fit p-1 dark:text-white/80 dark:hover:text-white text-black/80 hover:text-black"
            >
              Try again?
            </Button>
          </>
        ) : openOrderTokenInAllowanceError ? (
          <>
            <Popover>
              <PopoverTrigger className="w-fit  group flex items-center text-[11px] justify-center">
                <TypographyS className="text-xs flex items-center dark:text-white/70 text-black/70 underline ">
                  allowance error
                  <XCircleIcon className="text-red-500 w-3.5 h-3.5 ml-1" />
                </TypographyS>
              </PopoverTrigger>
              <PopoverContent
                align="center"
                side="right"
                className="bg-white/30 shadow space-y-1 dark:bg-primary-black/30 max-w-[240px] w-fit backdrop-blur-lg p-2"
              >
                <TypographyS className="text-xs dark:text-white/70 text-black/70  truncate">
                  {openOrderTokenInAllowanceError}
                </TypographyS>
              </PopoverContent>
            </Popover>
            <Button
              disabled={isOpenOrderTokenInAllowanceLoading}
              onClick={async () => await refetchOpenOrderTokenInAllowance()}
              variant="ghost"
              className=" text-[10px] h-fit p-1 dark:text-white/80 dark:hover:text-white text-black/80 hover:text-black"
            >
              Try again?
            </Button>
          </>
        ) : isTokenApproving ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">approving</TypographyS>
            <SpinnerIcon className=" w-3 h-3" />
          </>
        ) : !amountOfTokenToBeApproved ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">cannot estimate amount</TypographyS>
          </>
        ) : !isTokenApproved && !approveTokenError ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">
              require approving of{' '}
              {formatAmount({
                value: +ethers.formatUnits(amountOfTokenToBeApproved, tokenIn.decimals),
                withoutFormatForSmallValue: true,
              })}{' '}
              {tokenIn.symbol}
            </TypographyS>
            <Popover>
              <PopoverTrigger className="w-fit  flex items-center text-[11px] justify-center">
                <CircleHelpIcon className="dark:text-white/70 dark:hover:text-white text-black/70 hover:text-black w-3.5 h-3.5" />
              </PopoverTrigger>
              <PopoverContent
                align="center"
                side="right"
                className="bg-white/30 shadow dark:bg-primary-black/30 max-w-[220px] backdrop-blur-lg p-2"
              >
                <TypographyP className="dark:text-white text-[11px] text-black first-letter:capitalize">
                  Sum of amounts from active orders and current order.
                </TypographyP>
              </PopoverContent>
            </Popover>
          </>
        ) : approveTokenError ? (
          <>
            <TypographyS className="text-xs dark:text-white/70 text-black/70">{approveTokenError}</TypographyS>
            <XCircleIcon className="text-red-500  w-3.5 h-3.5" />

            <Button
              disabled={isTokenApproving}
              onClick={async () => await onTryApproveAgainClick()}
              variant="link"
              className="underline text-[10px] h-fit p-0 ml-2 dark:text-white/80 dark:hover:text-white text-black/80 hover:text-black"
            >
              Try again?
            </Button>
          </>
        ) : (
          <>
            <TypographyS className="text-xs dark:text-white/70">approved</TypographyS>
            <CheckCircle2Icon className="text-green-600 w-3.5 h-3.5" />
          </>
        )}
      </div>
    </div>
  );
};
