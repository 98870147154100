import { EyeIcon, XIcon } from 'lucide-react';
import { FC, useCallback, useState } from 'react';
import { useAppDispatch, useCancelStopMarketOrder } from '../../../../hooks';
import { ConditionalModalInfoType, ModalName, StopMarketOrderInfo } from '../../../../interfaces';
import { setActiveModalInfo } from '../../../../store';
import { cn } from '../../../../utils';
import { Button } from '../../../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { TypographyP } from '../../../ui/typography';

type OrderActionColumnProps = {
  activeOrder: boolean;
  order: StopMarketOrderInfo;
};
export const OrderActionColumn: FC<OrderActionColumnProps> = ({ activeOrder, order }) => {
  const dispatch = useAppDispatch();

  const [showCancelPopover, setShowCancelPopover] = useState<boolean>(false);

  const { cancelStopMarketOrder, isCancelStopMarketOrderPending, cancelingOrderId } = useCancelStopMarketOrder();

  const onCancelStopMarketOrderClick = useCallback(async (orderId: string) => {
    const res = await cancelStopMarketOrder(orderId);

    if (res.data.success) {
      setShowCancelPopover(false);
    }
  }, []);

  return (
    <div
      className={cn('flex items-center justify-center w-full', {
        'space-x-2': activeOrder,
      })}
    >
      <Button
        variant="ghost"
        className="p-1 h-fit"
        onClick={() =>
          dispatch(
            setActiveModalInfo({
              activeModal: ModalName.STOP_MARKET_ORDER_INFO,
              modalInfo: { order } as ConditionalModalInfoType<ModalName.STOP_MARKET_ORDER_INFO>,
            }),
          )
        }
        aria-label="View Order"
      >
        <EyeIcon className={` text-blue-500`} />
      </Button>
      {activeOrder && (
        <>
          <Popover open={showCancelPopover} onOpenChange={(open) => setShowCancelPopover(open)}>
            <PopoverTrigger asChild>
              <Button
                onClick={() => setShowCancelPopover(true)}
                variant="ghost"
                className="p-1 h-fit relative"
                aria-label="Cancel Order"
              >
                <XIcon className={`text-blue-500`} />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="flex items-center flex-col gap-4">
              <TypographyP className="text-center text-sm">Do you want to cancel the order?</TypographyP>
              <Button
                type="button"
                variant="ghost"
                className="relative bg-red-600 hover:bg-red-500 text-white"
                onClick={() => {
                  onCancelStopMarketOrderClick(order.openOrder.id);
                }}
                aria-label="Cancel Order"
                loading={cancelingOrderId === order.openOrder.id && isCancelStopMarketOrderPending}
                disabled={cancelingOrderId === order.openOrder.id && isCancelStopMarketOrderPending}
              >
                Cancel
              </Button>
            </PopoverContent>
          </Popover>
        </>
      )}
    </div>
  );
};
