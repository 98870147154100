import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip';
import { FC, memo } from 'react';
import { getNetworks } from '../../../../utils/blockchain/token-list';
import { TypographyP, TypographyS } from '../../../ui/typography';

type ChainColumnProps = {
  chainId: number;
  orderId: string;
};

const ChainColumnComp: FC<ChainColumnProps> = ({ chainId, orderId }) => {
  const networks = getNetworks();
  const network = networks.find((n) => n.chainId === chainId);

  return (
    <div className="flex items-center justify-center">
      {network && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="flex flex-col relative items-center gap-1">
              <img src={network.logoUri} alt={`${network.name} logo`} className="w-5 h-5" />
              <TypographyS className="dark:text-white/60 text-black/60 text-[10px] truncate max-w-[100px]">
                Order #{orderId}
              </TypographyS>
            </TooltipTrigger>
            <TooltipContent
              align="center"
              side="right"
              className="bg-white/30 shadow dark:bg-primary-black/30 max-w-[220px] flex items-center flex-col gap-1 backdrop-blur-lg p-2"
            >
              <TypographyP className="dark:text-white text-black">{network.name}</TypographyP>
              <TypographyS className="dark:text-white/60 text-black/60 text-[10px] truncate max-w-[100px]">
                Order #{orderId}
              </TypographyS>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
};

export const ChainColumn = memo(ChainColumnComp);
