import { useEffect, useState } from 'react';

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

const getActiveBreakpoint = (width: number) => {
  if (width >= breakpoints['2xl']) return '2xl';
  if (width >= breakpoints.xl) return 'xl';
  if (width >= breakpoints.lg) return 'lg';
  if (width >= breakpoints.md) return 'md';
  if (width >= breakpoints.sm) return 'sm';
  return 'xs'; // for widths smaller than 640px
};

export const useBreakpoints = () => {
  const [breakpoint, setBreakpoint] = useState(() => getActiveBreakpoint(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      const currentBreakpoint = getActiveBreakpoint(window.innerWidth);
      setBreakpoint(currentBreakpoint);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    breakpoint,
    isMobile: ['sm', 'xs'].includes(breakpoint),
    isXs: breakpoint === 'xs',
  };
};
