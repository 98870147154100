import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useDisconnect } from 'wagmi';
import { APIConfig } from '../../configs';
import { axiosClientWithCredentials } from '../../utils';
import { signOutHandler } from '../../utils/auth/sign-out-handler';
import { useAppDispatch } from '../store';
import { toast } from '../ui/use-toast';

const signOutUser = async () => {
  return await axiosClientWithCredentials.post(`${APIConfig.baseURL}/u/sign-out`, undefined);
};

export const useSignOut = () => {
  const dispatch = useAppDispatch();
  const { disconnect } = useDisconnect();

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: signOutUser,
    onSuccess: async () => {
      signOutHandler(dispatch, disconnect);
    },
    onError(err) {
      console.error(err);
      let message = `Unexpected error occurred: ${err.message}`;

      if (isAxiosError(err)) {
        message = err.response?.data?.error || message;
      }

      toast({
        title: 'Sign out error',
        description: message,
      });
    },
  });

  return {
    signOutError: error,
    isSignOutPending: isPending,
    signOut: mutateAsync,
  };
};
