import { HelpCircleIcon, MoveDown, MoveUp } from 'lucide-react';
import { FC } from 'react';
import { PriceConditionInfo, PriceConditionTypeEnum, StopMarketOrderInfo, Token } from '../../../../interfaces';
import { formatWeiToReadablePriceConditionValue } from '../../../../utils';
import { getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../ui/tooltip';
import { TypographyS } from '../../../ui/typography';
import { OrderPriceLabel } from './components/order-price-label';

type TargetPriceColumnProps = {
  order: StopMarketOrderInfo;
};

const renderPriceLabel = (
  priceCondition: PriceConditionInfo,
  tokenIn: Token,
  tokenOut: Token,
  isCloseOrder: boolean,
  showInitial?: boolean,
  hideTokenSymbol?: boolean,
) => {
  const isTokenInTokenOutCondition = priceCondition.conditionType === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT;

  const amount = parseFloat(
    formatWeiToReadablePriceConditionValue(
      showInitial ? priceCondition.initialValue : priceCondition.value,
      priceCondition.conditionType,
      tokenIn,
      tokenOut,
    )!,
  );
  const token = isTokenInTokenOutCondition ? tokenOut : tokenIn;

  return (
    <OrderPriceLabel amount={amount} token={token} isCloseOrder={isCloseOrder} hideTokenSymbol={hideTokenSymbol} />
  );
};

export const TargetPriceColumn: FC<TargetPriceColumnProps> = ({ order }) => {
  const openCondition = order.openPriceCondition;
  const closeCondition = order.closePriceCondition;

  const openOrderTokenIn = getTokenByTokenId(order.openOrder.tokenInId);
  const openOrderTokenOut = getTokenByTokenId(order.openOrder.tokenOutId);
  const closeOrderTokenIn = getTokenByTokenId(order.openOrder.tokenOutId);
  const closeOrderTokenOut = order.closeOrder && getTokenByTokenId(order.closeOrder.tokenOutId);

  return (
    <div className="flex space-y-1.5 flex-col min-w-max">
      <div className="flex gap-1 w-full justify-center">
        <OrderTargetLabel
          isClose={false}
          condition={openCondition}
          orderTokenIn={openOrderTokenIn}
          orderTokenOut={openOrderTokenOut}
        />
      </div>
      {closeCondition && closeOrderTokenIn && closeOrderTokenOut && (
        <div className="flex gap-1 w-full justify-center">
          <OrderTargetLabel
            condition={closeCondition}
            orderTokenIn={closeOrderTokenIn}
            orderTokenOut={closeOrderTokenOut}
            isClose={true}
          />
        </div>
      )}
    </div>
  );
};

type OrderTargetProps = {
  condition: PriceConditionInfo;
  orderTokenIn: Token | undefined;
  orderTokenOut: Token | undefined;
  isClose: boolean;
  showIcon?: boolean;
};

export const OrderTargetLabel: FC<OrderTargetProps> = ({ condition, showIcon, orderTokenIn, orderTokenOut }) => {
  if (!condition || !orderTokenIn || !orderTokenOut) return null;

  const isTokenInTokenOutCondition = condition.conditionType === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT;
  const isClose = false;
  const hideTokenLabel = true;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex cursor-pointer gap-0.5 items-center  text-[11px]">
            {isTokenInTokenOutCondition ? (
              <MoveUp className="w-2.5 h-2.5 text-green-500 " />
            ) : (
              <MoveDown className="w-2.5 h-2.5 text-red-500 " />
            )}
            {renderPriceLabel(condition, orderTokenIn, orderTokenOut, isClose)}
            {showIcon && <HelpCircleIcon className="w-3 h-3 dark:text-white/60 text-black/60" />}
          </div>
        </TooltipTrigger>
        <TooltipContent
          align="center"
          side="right"
          className="bg-white/30 flex flex-col gap-1 shadow z-10 dark:bg-primary-black/30 max-w-[280px] backdrop-blur-lg p-2"
        >
          <div className="flex items-center gap-1 dark:text-white/80 text-black/80 text-[10px]">
            <TypographyS className="text-[10px] dark:text-white">Initial</TypographyS>
            {renderPriceLabel(condition, orderTokenIn, orderTokenOut, isClose, true, hideTokenLabel)}
          </div>
          <div className="flex items-center gap-1 dark:text-white/80 text-black/80 text-[10px]">
            <TypographyS className="text-[10px] dark:text-white">Target</TypographyS>
            {renderPriceLabel(condition, orderTokenIn, orderTokenOut, isClose, false, hideTokenLabel)}
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const OrderTargetLabelMobile: FC<OrderTargetProps> = ({
  condition,
  showIcon,
  orderTokenIn,
  orderTokenOut,
  isClose,
}) => {
  if (!condition || !orderTokenIn || !orderTokenOut) return null;

  const hideTokenSymbol = true;

  const isTokenInTokenOutCondition = condition.conditionType === PriceConditionTypeEnum.TOKEN_IN_TOKEN_OUT;
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex cursor-pointer gap-0.5 items-center  text-[11px]">
          {isTokenInTokenOutCondition ? (
            <MoveUp className="w-2.5 h-2.5 text-green-500 " />
          ) : (
            <MoveDown className="w-2.5 h-2.5 text-red-500 " />
          )}
          {renderPriceLabel(condition, orderTokenIn, orderTokenOut, isClose, false, hideTokenSymbol)}

          {showIcon && <HelpCircleIcon className="w-3 h-3 dark:text-white/60 text-black/60" />}
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="center"
        side="right"
        className="bg-white/30 z-50 flex flex-col gap-1 shadow dark:bg-primary-black/30 max-w-[280px] w-fit  backdrop-blur-lg p-2"
      >
        <div className="flex items-center gap-1 dark:text-white/80 text-[10px]">
          <TypographyS className="text-[10px] dark:text-white">Initial</TypographyS>
          {renderPriceLabel(condition, orderTokenIn, orderTokenOut, isClose, true, hideTokenSymbol)}
        </div>
        <div className="flex items-center gap-1 dark:text-white/80 text-[10px]">
          <TypographyS className="text-[10px] dark:text-white">Target</TypographyS>
          {renderPriceLabel(condition, orderTokenIn, orderTokenOut, isClose, false, hideTokenSymbol)}
        </div>
      </PopoverContent>
    </Popover>
  );
};
