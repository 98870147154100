import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip';
import { formatDate, formatDistanceToNow } from 'date-fns';
import { FC } from 'react';
import { TypographyP } from '../../../ui/typography';

type CreatedAtColumnProps = {
  createdAt: string;
};
export const CreatedAtColumn: FC<CreatedAtColumnProps> = ({ createdAt }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className="w-fit mx-auto flex items-center text-[11px] justify-center">
          {formatDate(createdAt, 'MMM d hh:mm ')}
        </TooltipTrigger>
        <TooltipContent
          align="center"
          side="right"
          className="bg-white/30 shadow z-10 dark:bg-primary-black/30 max-w-[220px] backdrop-blur-lg p-2"
        >
          <TypographyP className="dark:text-white text-[11px] text-black first-letter:capitalize">
            {formatDistanceToNow(createdAt, { addSuffix: true })}
          </TypographyP>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
