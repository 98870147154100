import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ModalName, Network, Token, TokenPair } from '../../interfaces';
import { closeModal, setStopMarketOrderPair } from '../../store';
import { getAvailableTokens, getNetworkByChainId, getNetworks } from '../../utils/blockchain/token-list';
import { ChainSelector } from '../network/chain-selector';
import { TokenSelector } from '../order/order/token-selector';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogFooter, DialogOverlay, DialogPortal, DialogTitle } from '../ui/dialog';
import { TypographyH4, TypographyP, TypographyS } from '../ui/typography';

export const MobileTokenPairSelector = () => {
  const activeModal = useAppSelector((state) => state.modal);

  const dispatch = useAppDispatch();
  const modalInfo = activeModal.modalInfo as TokenPair | null;

  const [selectedTokenPair, setSelectedTokenPair] = useState<{
    network: Network;
    tokenIn: Token;
    tokenOut: Token;
  }>({
    network: getNetworkByChainId(modalInfo!.chainId)!,
    tokenIn: modalInfo!.tokenIn!,
    tokenOut: modalInfo!.tokenOut!,
  });

  const networkTokens = useMemo(
    () => getAvailableTokens({ chainId: selectedTokenPair.network.chainId }),
    [selectedTokenPair.network.chainId],
  );

  const tokenList = getNetworks()
    .filter((n) => (selectedTokenPair.network.chainId ? n.chainId === selectedTokenPair.network.chainId : true))
    .flatMap((n) => n.tokens);

  const handleTokenInChange = useCallback(
    (tokenIn: Token) => {
      const isTokenInStablecoin = tokenIn.isStablecoin;
      const isTokenOutStablecoin = selectedTokenPair.tokenOut.isStablecoin;

      let tokenOut = selectedTokenPair.tokenOut;

      if (tokenIn.address === tokenOut.address || isTokenOutStablecoin) {
        const firstAvailableToken = networkTokens.find((t) => {
          const isNotTheSameToken = t.address !== tokenIn.address;
          const isNotStablecoin = !t.isStablecoin;

          if (isTokenInStablecoin) {
            return isNotStablecoin && isNotTheSameToken;
          }

          return isNotTheSameToken;
        });

        if (firstAvailableToken) {
          tokenOut = firstAvailableToken;
        }
      }

      setSelectedTokenPair((prev) => ({
        ...prev,
        tokenIn,
        tokenOut,
      }));
    },
    [selectedTokenPair.tokenOut, networkTokens],
  );

  const handleTokenOutChange = useCallback(
    (tokenOut: Token) => {
      const isTokenOutStablecoin = tokenOut.isStablecoin;
      const isTokenInStablecoin = selectedTokenPair.tokenOut.isStablecoin;

      let tokenIn = selectedTokenPair.tokenOut;

      if (tokenOut.address === tokenIn.address || (isTokenInStablecoin && tokenOut.isStablecoin)) {
        const firstAvailableToken = networkTokens.find((t) => {
          const isNotTheSameToken = t.address !== tokenOut.address;
          const isNotStablecoin = !t.isStablecoin;

          if (isTokenOutStablecoin) {
            return isNotStablecoin && isNotTheSameToken;
          }

          return isNotTheSameToken;
        });

        if (firstAvailableToken) {
          tokenIn = firstAvailableToken;
        }
      }

      setSelectedTokenPair((prev) => ({
        ...prev,
        tokenIn,
        tokenOut,
      }));
    },
    [selectedTokenPair.tokenOut, networkTokens],
  );

  const handleChainIdChange = (chainId: number) => {
    const network = getNetworkByChainId(chainId)!;

    const tokenIn = network!.tokens.filter((t) => t.isStablecoin)[0];
    const tokenOut = network!.tokens.filter((t) => !t.isStablecoin)[0];

    setSelectedTokenPair({
      network,
      tokenIn,
      tokenOut,
    });
  };

  return (
    <Dialog
      open={activeModal.activeModal === ModalName.STOP_MARKET_ORDER_TOKEN_PAIR_SELECTOR_MOBILE}
      onOpenChange={() => dispatch(closeModal())}
      modal
    >
      <DialogTitle className="hidden">Token pair</DialogTitle>
      <DialogPortal>
        <DialogOverlay>
          <DialogContent className="w-[100vw] h-[100vh] min-w-[100vw] min-h-[100vh] !rounded-none overflow-auto flex flex-col py-5 px-3">
            <TypographyH4>Select Token Pair</TypographyH4>
            <div className="gap-1 flex flex-col mt-4">
              <TypographyP className="text-xs font-semibold">Network</TypographyP>
              <ChainSelector
                className=""
                selectedChainId={selectedTokenPair.network.chainId}
                handleChainIdChange={(chainId) => handleChainIdChange(chainId)}
              />
            </div>

            <div className="gap-1 flex flex-col mt-3 mb-2">
              <TypographyP className="text-xs font-semibold">
                Token pair <TypographyS className="dark:text-white/60 text-black/60 text-[11px]">(in/out)</TypographyS>
              </TypographyP>

              <div className="flex gap-2">
                <TokenSelector
                  key={`${selectedTokenPair.network.chainId}-open-token-in`}
                  className="px-1 mr-0 flex justify-between !w-full"
                  tokenList={tokenList}
                  label="Token In"
                  contentClassName="w-full flex justify-between"
                  selectedToken={selectedTokenPair.tokenIn}
                  handleTokenChange={handleTokenInChange}
                />

                <TokenSelector
                  key={`${selectedTokenPair.network.chainId}-open-token-out`}
                  className="px-1 mr-0 flex justify-between !w-full"
                  tokenList={tokenList}
                  label="Token Out"
                  selectedToken={selectedTokenPair.tokenOut}
                  handleTokenChange={handleTokenOutChange}
                  contentClassName="w-full flex justify-between"
                />
              </div>
            </div>

            <DialogFooter className="flex gap-2 mt-auto flex-row items-center w-full">
              <Button
                onClick={() => {
                  dispatch(closeModal());
                }}
                className="dark:text-white border  px-2 py-1.5 h-fit text-xs w-full"
                variant="ghost"
              >
                Cancel
              </Button>

              <Button
                onClick={() => {
                  dispatch(
                    setStopMarketOrderPair({
                      tokenIn: selectedTokenPair.tokenIn,
                      tokenOut: selectedTokenPair.tokenOut,
                      chainId: selectedTokenPair.network.chainId,
                    }),
                  );

                  dispatch(closeModal());
                }}
                className="dark:bg-primary-light-gray bg-primary-black/10 dark:text-white  w-full px-2 py-1.5 h-fit text-xs"
                variant="ghost"
              >
                Apply
              </Button>
            </DialogFooter>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
