import { StopMarketOrderInfo } from '../order/stop-market-order-interface';
import { TokenPair } from '../token';

export enum ModalName {
  STOP_MARKET_ORDER_TOKEN_PAIR_SELECTOR_MOBILE = 'stopMarketOrderTokenPairSelectorMobile',
  STOP_MARKET_ORDER_INFO = 'stopMarketOrderInfo',
  STOP_MARKET_ORDER_PREVIEW_DESKTOP = 'stopMarketOrderPreviewDesktop',
}

export type ConditionalModalInfoType<T> = T extends ModalName.STOP_MARKET_ORDER_TOKEN_PAIR_SELECTOR_MOBILE
  ? TokenPair | null
  : T extends ModalName.STOP_MARKET_ORDER_INFO
    ? {
        order: StopMarketOrderInfo;
      }
    : never;
