import { useEffect } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import './App.css';
import { BaseLayout, ModalRegistry } from './components';
import { useAppDispatch, useTheme, useVerifyAccountListener } from './hooks';
import { AppRoutes } from './routes/app-routes';
import { setupAxiosClientWithCredentialsInterceptors } from './utils';

export const App = () => {
  useVerifyAccountListener();

  const dispatch = useAppDispatch();

  const { address } = useAccount();

  const { isThemeSet } = useTheme();

  const { disconnect } = useDisconnect();

  useEffect(() => {
    if (address) {
      setupAxiosClientWithCredentialsInterceptors(dispatch, disconnect, address);
    }
  }, [address, dispatch, disconnect]);

  return !isThemeSet ? null : (
    <>
      <BaseLayout>
        <AppRoutes />
        <ModalRegistry />
      </BaseLayout>
    </>
  );
};
