import { FC } from 'react';
import { StopMarketOrderInfo } from '../../../../interfaces';
import { getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { TokenLabel } from '../../../custom/token-label';
import { TypographyP } from '../../../ui/typography';

type OrderColumnProps = {
  order: StopMarketOrderInfo;
};

export const OrderColumn: FC<OrderColumnProps> = ({ order }) => {
  const openOrderTokenOut = getTokenByTokenId(order.openOrder.tokenOutId);

  return (
    <div className="flex space-y-1.5 flex-col min-w-max">
      <div className="flex gap-1 justify-center">
        <TypographyP className="dark:text-amber-400 font-medium  italic text-[11px]">Buy</TypographyP>
        {openOrderTokenOut && (
          <div className="flex gap-1 items-center justify-start">
            <TokenLabel token={openOrderTokenOut} textClassName="text-[10px]" imgClassName="w-3 h-3" />
          </div>
        )}
      </div>
      {openOrderTokenOut && order.closeOrder && (
        <div className="flex gap-1 justify-center">
          <div className="flex items-center relative">
            <TypographyP className="italic dark:text-purple-400 text-[11px]">Sell</TypographyP>
          </div>

          <div className="flex gap-1 items-center justify-start dark:text-white/60">
            <TokenLabel token={openOrderTokenOut} textClassName="text-[10px]" imgClassName="w-3 h-3" />
          </div>
        </div>
      )}
    </div>
  );
};
