import { FC, memo, useCallback, useState } from 'react';
import { useAppDispatch, useCancelStopMarketOrder } from '../../../../hooks';
import { ConditionalModalInfoType, ModalName, OrderStatusEnum, StopMarketOrderInfo } from '../../../../interfaces';
import { setActiveModalInfo } from '../../../../store';
import { cn } from '../../../../utils';
import { getNetworks, getTokenByTokenId } from '../../../../utils/blockchain/token-list';
import { CopyToClipboardButton } from '../../../custom/copy-to-clipboard-button';
import { TokenLabel } from '../../../custom/token-label';
import { Button } from '../../../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/popover';
import { TypographyP, TypographyS } from '../../../ui/typography';
import { InputAmountOrderLabel } from './amount-column';
import { OrderDateLabel } from './components/order-date-label';
import { OrderTargetLabelMobile } from './target-price-column';

type MobileOrderInfoColumnProps = {
  orderInfo: StopMarketOrderInfo;
  activeOrder: boolean;
};

const MobileOrderInfoColumnComp: FC<MobileOrderInfoColumnProps> = ({ orderInfo }) => {
  const { closeOrder } = orderInfo;

  const dispatch = useAppDispatch();

  const [showCancelPopover, setShowCancelPopover] = useState<boolean>(false);

  const { cancelStopMarketOrder, isCancelStopMarketOrderPending, cancelingOrderId } = useCancelStopMarketOrder();

  const onCancelStopMarketOrderClick = useCallback(async (orderId: string) => {
    const res = await cancelStopMarketOrder(orderId);

    if (res.data.success) {
      setShowCancelPopover(false);
    }
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <div
        className={cn('flex gap-2 justify-between', {
          'flex-col sm:flex-row': orderInfo.closeOrder,
        })}
      >
        <OrderInfoComponent orderInfo={orderInfo} isCloseOrder={false} />
        {orderInfo.closeOrder ? <OrderInfoComponent orderInfo={orderInfo} isCloseOrder={true} /> : null}
      </div>

      <div className={cn('flex items-center xs:justify-end w-full space-x-2')}>
        <Button
          variant="outline"
          className="dark:bg-primary-light-gray dark:text-white px-2 py-1.5 h-fit xs:w-fit w-full text-xs"
          onClick={() =>
            dispatch(
              setActiveModalInfo({
                activeModal: ModalName.STOP_MARKET_ORDER_INFO,
                modalInfo: {
                  order: orderInfo,
                } as ConditionalModalInfoType<ModalName.STOP_MARKET_ORDER_INFO>,
              }),
            )
          }
          aria-label="View Order"
        >
          View
        </Button>
        {[OrderStatusEnum.CREATED, OrderStatusEnum.QUEUED].includes(orderInfo.openOrder.status) ||
        [OrderStatusEnum.CREATED, OrderStatusEnum.QUEUED].includes(orderInfo.closeOrder?.status) ? (
          <Popover open={showCancelPopover} onOpenChange={(open) => setShowCancelPopover(open)}>
            <PopoverTrigger asChild>
              <Button
                onClick={() => setShowCancelPopover(true)}
                variant="outline"
                aria-label="Cancel Order"
                className="dark:text-white px-2 xs:w-fit w-full py-1.5 h-fit text-xs"
              >
                Cancel active {closeOrder ? 'orders' : 'order'}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="flex items-center flex-col gap-4">
              <TypographyP className="text-center text-sm">Do you want to cancel the order?</TypographyP>
              <Button
                type="button"
                variant="ghost"
                className="relative bg-red-600 hover:bg-red-500 text-white"
                onClick={() => {
                  onCancelStopMarketOrderClick(orderInfo.openOrder.id);
                }}
                aria-label="Cancel Order"
                loading={cancelingOrderId === orderInfo.openOrder.id && isCancelStopMarketOrderPending}
                disabled={cancelingOrderId === orderInfo.openOrder.id && isCancelStopMarketOrderPending}
              >
                Cancel
              </Button>
            </PopoverContent>
          </Popover>
        ) : null}
      </div>
    </div>
  );
};

type OrderInfoComponentProps = {
  orderInfo: StopMarketOrderInfo;
  isCloseOrder: boolean;
};

const OrderInfoComponent: FC<OrderInfoComponentProps> = ({ orderInfo, isCloseOrder }) => {
  const { openOrder, closeOrder, closePriceCondition, openPriceCondition } = orderInfo;

  const networks = getNetworks();
  const network = networks.find((n) => n.chainId === openOrder.chainId);

  const openOrderTokenIn = getTokenByTokenId(openOrder.tokenInId);
  const openOrderTokenOut = getTokenByTokenId(openOrder.tokenOutId);
  const closeOrderTokenIn = getTokenByTokenId(openOrder.tokenOutId);
  const closeOrderTokenOut = closeOrder && getTokenByTokenId(closeOrder.tokenOutId);

  const title = isCloseOrder ? 'Close order' : 'Open order';

  const orderStatus = !isCloseOrder ? openOrder.status : closeOrder.status;
  const txHash = !isCloseOrder ? openOrder.txHash : closeOrder.txHash;

  return (
    <div className="shadow-md p-1 rounded-md dark:shadow-none">
      <TypographyP
        className={cn('font-semibold dark:text-yellow-400 italic mb-1 ', {
          'dark:text-purple-400': isCloseOrder,
        })}
      >
        {title}:
      </TypographyP>

      <div className="space-y-1">
        <div className="flex items-center gap-2 ">
          <TypographyS className="font-medium">Network</TypographyS>
          <div className="flex items-center justify-center">
            {network && (
              <Popover>
                <PopoverTrigger className="flex flex-col relative">
                  <img src={network.logoUri} alt={`${network.name} logo`} className="w-3.5 h-3.5" />
                </PopoverTrigger>
                <PopoverContent
                  align="center"
                  side="right"
                  className="bg-white/30 w-fit  shadow dark:bg-primary-black/30 max-w-[220px] backdrop-blur-lg p-2"
                >
                  <TypographyP className="dark:text-white text-black text-xs">{network.name}</TypographyP>
                </PopoverContent>
              </Popover>
            )}
          </div>
        </div>

        <div className="flex items-center gap-2 ">
          <TypographyS className="font-medium">Order</TypographyS>
          <div className="flex items-center justify-center">
            <TypographyS className="text-[11px] truncate max-w-[100px]">#{orderInfo.openOrder.id}</TypographyS>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <TypographyS className="font-medium">Order</TypographyS>

          {openOrderTokenIn && openOrderTokenOut && !isCloseOrder && (
            <div className="flex gap-1 justify-center">
              <TypographyP className="dark:text-amber-400 font-medium text-[11px] leading-4">Buy</TypographyP>
              {openOrderTokenOut && (
                <div className="flex gap-1 items-center justify-start">
                  <TokenLabel token={openOrderTokenOut} textClassName="text-[10px]" imgClassName="w-3 h-3" />
                </div>
              )}
            </div>
          )}

          {closeOrderTokenIn && closeOrderTokenOut && isCloseOrder && (
            <div className="flex gap-1 justify-center">
              <div className="flex items-center relative">
                <TypographyP className="italic dark:text-purple-400 text-[11px] leading-4">Sell</TypographyP>
              </div>

              <div className="flex gap-1 items-center justify-start">
                <TokenLabel token={closeOrderTokenIn} textClassName="text-[10px]" imgClassName="w-3 h-3" />
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center gap-2">
          <TypographyS className="font-medium">Target price</TypographyS>

          {openPriceCondition && openOrderTokenIn && openOrderTokenOut && !isCloseOrder && (
            <OrderTargetLabelMobile
              condition={openPriceCondition}
              orderTokenIn={openOrderTokenIn}
              orderTokenOut={openOrderTokenOut}
              isClose={false}
            />
          )}

          {closePriceCondition && closeOrderTokenIn && closeOrderTokenOut && isCloseOrder && (
            <OrderTargetLabelMobile
              condition={closePriceCondition}
              orderTokenIn={closeOrderTokenIn}
              orderTokenOut={closeOrderTokenOut}
              isClose={true}
            />
          )}
        </div>

        <div className="flex items-center gap-2">
          <TypographyS className="font-medium">Input amount</TypographyS>

          {!isCloseOrder ? (
            <InputAmountOrderLabel amount={openOrder.amountIn} tokenIn={openOrderTokenIn!} isClose={false} />
          ) : (
            <InputAmountOrderLabel
              amount={openOrder.amountOut || openOrder.minAmountOut}
              tokenIn={closeOrderTokenIn!}
              isClose={true}
            />
          )}
        </div>

        <div className="flex items-center gap-2">
          <TypographyS className="font-medium">Output amount</TypographyS>

          {!isCloseOrder ? (
            <InputAmountOrderLabel
              amount={openOrder.amountOut || openOrder.minAmountOut}
              tokenIn={openOrderTokenOut!}
              isClose={false}
            />
          ) : (
            <InputAmountOrderLabel
              amount={closeOrder.amountOut || closeOrder.minAmountOut}
              tokenIn={closeOrderTokenOut!}
              isClose={true}
            />
          )}
        </div>

        <div className="flex items-center gap-2">
          <TypographyS className="font-medium">Created</TypographyS>
          {!isCloseOrder ? (
            <OrderDateLabel date={openOrder.createdAt} />
          ) : (
            <OrderDateLabel date={closeOrder.createdAt} />
          )}
        </div>

        <div className="flex items-center gap-2">
          <TypographyS className="font-medium">Expires</TypographyS>
          <OrderDateLabel date={openOrder.expirationTime} />
        </div>

        <div className="flex items-center gap-2">
          <TypographyS className="font-medium">Status</TypographyS>
          <TypographyP
            className={cn(`text-center capitalize text-[11px]`, {
              'text-green-600': orderStatus === OrderStatusEnum.EXECUTED,
              'text-yellow-600': orderStatus === OrderStatusEnum.QUEUED,
              'text-red-600': orderStatus === OrderStatusEnum.CANCELED,
            })}
          >
            {orderStatus}
          </TypographyP>
        </div>

        {txHash ? (
          <div className="flex items-center gap-2">
            <TypographyS className="font-medium">Tx hash</TypographyS>
            <CopyToClipboardButton
              className="text-[11px]"
              label={`${txHash.slice(0, 6)}...${txHash.slice(-6)}`}
              textToCopy={txHash}
              link={openOrder.chainId === 42161 ? `https://arbiscan.io/tx/${txHash}` : ''}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const MobileOrderInfoColumn = memo(MobileOrderInfoColumnComp);
