import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { cn } from '../../../utils';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../ui/accordion';
import { Button } from '../../ui/button';
import { Card, CardContent } from '../../ui/card';
import { OrderList } from './order-list';

export const OrderListMobile = () => {
  const [showStopMarketOrdersHistory, setShowStopMarketOrdersHistory] = useState('');

  return (
    <Accordion
      onValueChange={(e) => setShowStopMarketOrdersHistory(e)}
      type="single"
      value={showStopMarketOrdersHistory}
      collapsible
      className="!w-full"
    >
      <AccordionItem value="item-1" className="!border-b-0 min-w-full w-full ">
        <AccordionTrigger showIcon={false} asChild>
          <Card className="w-full">
            <CardContent
              className={cn('px-2 py-2 relative flex flex-col items-center w-full', {
                'pt-6': showStopMarketOrdersHistory,
              })}
            >
              <Button
                variant="ghost"
                className={cn('flex items-center w-full !py-0  h-8 text-[12px]', {
                  'absolute top-2 text-[11px] right-2 p-0 h-4 w-fit hover:bg-transparent': showStopMarketOrdersHistory,
                })}
                onClick={() => setShowStopMarketOrdersHistory((prev) => (prev ? '' : 'item-1'))}
              >
                {showStopMarketOrdersHistory ? 'Hide' : 'Show'} orders history
                <ChevronDown
                  className={cn('', {
                    'rotate-180': showStopMarketOrdersHistory,
                  })}
                />
              </Button>
              <AccordionContent className="pt-2 pb-2">
                <OrderList />
              </AccordionContent>
            </CardContent>
          </Card>
        </AccordionTrigger>
      </AccordionItem>
    </Accordion>
  );
};
