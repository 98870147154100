import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { metaMaskWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { createConfig, http } from '@wagmi/core';
import { arbitrum, bsc, Chain } from 'wagmi/chains';

export const SUPPORTED_CHAINS = [arbitrum, bsc] as readonly [Chain, ...Chain[]];

const projectId = '3504ee2464ab9bd955f04031793fe77e';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, walletConnectWallet],
    },
  ],
  {
    appName: 'DexStandard',
    projectId,
  },
);

export const wagmiConfig = createConfig({
  chains: SUPPORTED_CHAINS,
  transports: {
    [arbitrum.id]: http(),
    [bsc.id]: http(),
    // [base.id]: http(),
  },
  connectors,
  ssr: false,
});
