import { FC } from 'react';
import { Token } from '../../../../../interfaces';
import { cn, formatAmount } from '../../../../../utils';
import { TokenLabel } from '../../../../custom/token-label';
import { TokenLogo } from '../../../../custom/token-logo';
import { TypographyS } from '../../../../ui/typography';

type OrderPriceLabelProps = {
  token: Token;
  isCloseOrder: boolean;
  amount: string | number;
  hideTokenSymbol?: boolean;
};

export const OrderPriceLabel: FC<OrderPriceLabelProps> = ({ isCloseOrder, amount, token, hideTokenSymbol }) => {
  return (
    <div className="flex items-center gap-1">
      <TypographyS
        className={cn('', {
          'dark:text-amber-400': !isCloseOrder,
          'dark:text-purple-400': isCloseOrder,
        })}
      >
        {formatAmount({
          value: +amount,
          precise: true,
          withoutFormatForSmallValue: true,
        })}
      </TypographyS>
      {hideTokenSymbol ? (
        <TokenLogo imgClassName="w-3 h-3" token={token} />
      ) : (
        <TokenLabel
          wrapperClassName="flex-row-reverse"
          textClassName="text-[11px]"
          imgClassName="w-3 h-3"
          token={token}
        />
      )}
    </div>
  );
};
