import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import { formatDate, formatDistanceToNow } from 'date-fns';
import { FC } from 'react';
import { TypographyP } from '../../../../ui/typography';

type OrderDateLabelProps = {
  date: Date | string;
};

export const OrderDateLabel: FC<OrderDateLabelProps> = ({ date }) => {
  return (
    <Popover>
      <PopoverTrigger className="w-fit flex items-center text-[11px] leading-4">
        {formatDate(date, 'MMM d hh:mm ')}
      </PopoverTrigger>
      <PopoverContent
        align="center"
        side="right"
        className="bg-white/30 shadow z-10 w-fit dark:bg-primary-black/30 max-w-[220px] backdrop-blur-lg p-2"
      >
        <TypographyP className="dark:text-white text-[11px] text-black first-letter:capitalize">
          {formatDistanceToNow(date, { addSuffix: true })}
        </TypographyP>
      </PopoverContent>
    </Popover>
  );
};
