import { ethers } from 'ethers';
import { KLineData } from 'klinecharts';
import { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useGetPairOHLCInfo } from '../../../hooks/ohlc/use-get-pair-ohlc-info';
import { ChartDateIntervalRangeEnum, IndicatorEnum, OhlcPeriod } from '../../../interfaces';
import { getCurrentInfoFromInterval, getCurrentInfoInterval, getPrependInfoFromInterval } from '../../../utils';
import { getNetworkByChainId } from '../../../utils/blockchain/token-list';
import { NetworkLogo } from '../../custom/network-logo';
import { Card, CardContent } from '../../ui/card';
import { Tabs, TabsList, TabsTrigger } from '../../ui/tabs';
import { TypographyH4 } from '../../ui/typography';
import { ChartComponent } from './chart-component';

type ChartLayoutProps = {
  className?: string;
};

const formatOHLCPeriodToKLineData = (d: OhlcPeriod, tokenOutDecimals: number) => ({
  close: parseFloat(d.close),
  open: parseFloat(d.open),
  low: parseFloat(d.low),
  high: parseFloat(d.high),
  timestamp: Date.parse(d.date),
  volume: parseFloat(ethers.formatUnits(d.volume, tokenOutDecimals || 0).toString()),
});

export const ChartLayout: FC<ChartLayoutProps> = () => {
  const limitOrderPair = useAppSelector((state) => state.stopMarketOrder.stopMarketOrderPair);
  const [selectedRange, setSelectedRange] = useState<ChartDateIntervalRangeEnum>(ChartDateIntervalRangeEnum.ONE_DAY);
  const maxTicks = 24; // can be adjusted later in chart configs
  const interval = getCurrentInfoInterval(selectedRange);
  const currentInfoFromInterval = useMemo(() => getCurrentInfoFromInterval(selectedRange), [selectedRange]);
  const prependInfoFromInterval = useMemo(
    () => getPrependInfoFromInterval(currentInfoFromInterval, interval, maxTicks),
    [currentInfoFromInterval, interval, maxTicks],
  );

  const [allOHLCData, setAllOHLCData] = useState<KLineData[]>([]);

  const network = limitOrderPair?.chainId ? getNetworkByChainId(limitOrderPair.chainId) : null;
  const [lastOHLCInfo, setLastOHLCInfo] = useState<OhlcPeriod | null>(null);

  const { isPairOHLCInfoLoading, pairOHLCInfo } = useGetPairOHLCInfo(
    limitOrderPair
      ? {
          chainId: limitOrderPair.chainId,
          tokenInAddress: limitOrderPair.tokenIn!.address,
          tokenOutAddress: limitOrderPair.tokenOut!.address,
          from: prependInfoFromInterval,
          interval,
          enabled: !!limitOrderPair,
          setLastOHLCInfo,
        }
      : null,
  );

  const { pairOHLCInfo: newPairOHLCInfo } = useGetPairOHLCInfo(
    limitOrderPair && pairOHLCInfo
      ? {
          chainId: limitOrderPair.chainId,
          tokenInAddress: limitOrderPair.tokenIn!.address,
          tokenOutAddress: limitOrderPair.tokenOut!.address,
          from: lastOHLCInfo?.date
            ? Date.parse(lastOHLCInfo?.date)
            : Date.parse(pairOHLCInfo.ohlcPeriod[pairOHLCInfo.ohlcPeriod.length - 1].date),
          interval,
          enabled: !!pairOHLCInfo && !isPairOHLCInfoLoading,
          setLastOHLCInfo,
          refetchInterval: 1000 * 60,
        }
      : null,
  );

  const [indicators] = useState({
    [IndicatorEnum.SMA]: false,
    [IndicatorEnum.EMA]: false,
    [IndicatorEnum.RSI]: false,
    [IndicatorEnum.BOLLINGER_BANDS]: false,
  });
  const [chartWidth, setChartWidth] = useState<number>(0);

  const updateChartDimensions = () => {
    const containerWidth = document.querySelector<HTMLElement>('.chart-layout')?.['offsetWidth'] ?? 0;
    setChartWidth(containerWidth - 40);
  };

  useEffect(() => {
    updateChartDimensions();
    window.addEventListener('resize', updateChartDimensions);

    return () => window.removeEventListener('resize', updateChartDimensions);
  }, []);

  const handleRangeChange = (range: ChartDateIntervalRangeEnum) => {
    setSelectedRange(range);
  };

  const currentOHLCData = useMemo(() => {
    const initialData =
      pairOHLCInfo?.ohlcPeriod
        .filter((d) => Date.parse(d.date) >= currentInfoFromInterval)
        .map((d) => formatOHLCPeriodToKLineData(d, limitOrderPair?.tokenOut?.decimals || 0)) ?? [];

    return initialData;
  }, [selectedRange, isPairOHLCInfoLoading, limitOrderPair?.tokenIn?.address, limitOrderPair?.tokenOut?.address]);

  const prependOHLCData = useMemo(() => {
    const initialData =
      pairOHLCInfo?.ohlcPeriod
        .filter((d) => Date.parse(d.date) >= prependInfoFromInterval && Date.parse(d.date) < currentInfoFromInterval)
        .map((d) => ({
          close: parseFloat(d.close),
          open: parseFloat(d.open),
          low: parseFloat(d.low),
          high: parseFloat(d.high),
          volume: parseFloat(ethers.formatUnits(d.volume, limitOrderPair?.tokenOut?.decimals || 0).toString()),

          timestamp: Date.parse(d.date),
        })) ?? [];

    return initialData;
  }, [selectedRange, pairOHLCInfo]);

  const rangeOptions = [
    ChartDateIntervalRangeEnum.ONE_DAY,
    ChartDateIntervalRangeEnum.FIVE_DAYS,
    ChartDateIntervalRangeEnum.ONE_MONTH,
  ];
  const pairLabel = limitOrderPair ? `${limitOrderPair?.tokenIn?.symbol} / ${limitOrderPair?.tokenOut?.symbol}` : '';

  const newOHLCData = useMemo(() => {
    if (newPairOHLCInfo && allOHLCData.length) {
      const filteredData =
        newPairOHLCInfo.ohlcPeriod
          .filter((p) => Date.parse(p.date) > allOHLCData[allOHLCData.length - 1].timestamp)
          .map((d) => formatOHLCPeriodToKLineData(d, limitOrderPair?.tokenOut?.decimals || 0)) ?? [];

      return filteredData;
    }
  }, [newPairOHLCInfo, currentOHLCData, limitOrderPair?.tokenOut?.decimals]);

  useEffect(() => {
    if (!isPairOHLCInfoLoading) {
      setAllOHLCData(
        pairOHLCInfo?.ohlcPeriod
          .filter((d) => Date.parse(d.date) >= currentInfoFromInterval)
          .map((d) => formatOHLCPeriodToKLineData(d, limitOrderPair?.tokenOut?.decimals || 0)) ?? [],
      );
    }
  }, [isPairOHLCInfoLoading]);

  return (
    <Card className="w-full min-w-full h-full chart-layout ">
      <CardContent className="flex flex-col lg:p-6  lg:pt-0 md:p-3 p-1 pt-0 ">
        <div className="flex md:items-center gap-1 justify-between lg:my-4 my-2 md:pl-0 pl-1  lg:mb-6">
          <div className="flex items-center gap-1 ">
            <div className="flex items-center gap-1 shrink-0">
              <TypographyH4 className="lg:text-xl md:text-lg text-base ">{pairLabel}</TypographyH4>
              {network ? <NetworkLogo className="w-4 h-4" network={network} /> : null}
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex space-x-2 text-xs font-medium">
              <Tabs
                onValueChange={(option) => handleRangeChange(option as ChartDateIntervalRangeEnum)}
                defaultValue={selectedRange}
                className="w-fit"
              >
                <TabsList className="w-full flex items-center md:p-1 p-0.5 h-fit md:h-auto">
                  {rangeOptions.map((r) => (
                    <TabsTrigger
                      className="uppercase xl:text-base md:px-3 md:py-1 px-2 py-0.5  text-xs"
                      key={r}
                      value={r}
                    >
                      {r}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
            </div>
          </div>
        </div>

        <ChartComponent
          indicators={indicators}
          chartWidth={chartWidth}
          currentOHLCData={currentOHLCData}
          newOHLCData={newOHLCData}
          prependOHLCData={prependOHLCData}
          isDataLoading={isPairOHLCInfoLoading}
          selectedRange={selectedRange}
          setAllOHLCData={setAllOHLCData}
          allOHLCData={allOHLCData}
        />
      </CardContent>
    </Card>
  );
};
